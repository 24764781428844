<template>
  <BubbleMenu
    ref="tippy"
    :editor="editor"
    :tippy-options="tippyOptions"
    class="tippy-bubble-menu flex bg-white border divide-x rounded shadow-xl w-fit divide-stone-200 border-stone-200"
  >
    <div
      v-if="!isAISelectorOpen"
      class="flex tippy-buttons"
    >
      <button
        class="flex items-center justify-center whitespace-nowrap text-sm font-medium active:bg-stone-200 h-9 px-3 gap-1 text-purple-500 hover:bg-stone-100 active:bg-stone-200"
        @click="onOpenChange(true)"
      >
        <Sparkles class="w-4 h-4" />
        Ask AI
      </button>
      <NodeSelector :editor="editor" />
      <button
        v-for="(item, index) in items"
        :key="index"
        class="p-2 text-stone-600 hover:bg-stone-100 active:bg-stone-200"
        type="button"
        @click="item.command()"
      >
        <component
          :is="item.icon"
          class="w-4 h-4"
          :class="{
            'text-blue-500': item.isActive(),
          }"
        />
      </button>
      <ColorSelector :editor="editor" />
    </div>
    <div v-else>
      <AISelector
        :open="isAISelectorOpen"
        :editor="editor"
        @on-open-change="onOpenChange"
      />
    </div>
  </BubbleMenu>
</template>

<script setup>
import {BubbleMenu} from "@tiptap/vue-3"
import {computed, ref} from "vue"
import {BoldIcon, CodeIcon, ItalicIcon, Sparkles, StrikethroughIcon, UnderlineIcon} from "lucide-vue-next"

import NodeSelector from "./NodeSelector.vue"
import ColorSelector from "./ColorSelector.vue"
import AISelector from "./AISelector.vue"

const props = defineProps({
  editor: {
    type: Object,
    required: true,
  },
})

const emit = defineEmits(['close'])

const isAISelectorOpen = ref(false)
const tippy = ref(null)

const tippyOptions = computed( () =>
  {
    return {
      placement: isAISelectorOpen.value ? 'top' : 'bottom',
      onHidden: () => {
        onOpenChange(false)
        // props.editor.chain().unsetHighlight().run()
      },
    }
  }
)

const items = [
  {
    name: "bold",
    isActive: () => props.editor.isActive("bold"),
    command: () => props.editor.chain().focus().toggleBold().run(),
    icon: BoldIcon,
  },
  {
    name: "italic",
    isActive: () => props.editor.isActive("italic"),
    command: () => props.editor.chain().focus().toggleItalic().run(),
    icon: ItalicIcon,
  },
  {
    name: "underline",
    isActive: () => props.editor.isActive("underline"),
    command: () => props.editor.chain().focus().toggleUnderline().run(),
    icon: UnderlineIcon,
  },
  {
    name: "strike",
    isActive: () => props.editor.isActive("strike"),
    command: () => props.editor.chain().focus().toggleStrike().run(),
    icon: StrikethroughIcon,
  },
  {
    name: "code",
    isActive: () => props.editor.isActive("code"),
    command: () => props.editor.chain().focus().toggleCode().run(),
    icon: CodeIcon,
  },
]

const onOpenChange = (value) => {
  isAISelectorOpen.value = value
}

</script>

<style>
div[data-tippy-root] {
  transition: all 0.2s ease;
  text-align: left;
}
</style>
