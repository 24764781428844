import ApolloClient from '@/apollo'
import {
  GET_DROPBOX_FILES,
  GET_DROPBOX_FILES_CONTINUE,
  GET_GOOGLE_DRIVE_FILES,
  GET_GOOGLE_DRIVES,
  LIST_WORKSPACE_MEDIA
} from '@/graphql/queries'
import {
  CREATE_WORKSPACE_DIRECTORY_MEDIA,
  CREATE_WORKSPACE_UPLOADABLE_FILE_MEDIA,
  CREATE_WORKSPACE_URL_FILE_MEDIA,
  DELETE_WORKSPACE_MEDIA_BY_ID,
  DELETE_WORKSPACE_MEDIA_BY_IDS,
  MOVE_WORKSPACE_MEDIA_BY_IDS,
  UPDATE_WORKSPACE_DIRECTORY_MEDIA_BY_ID,
  UPDATE_WORKSPACE_UPLOADABLE_FILE_MEDIA_BY_ID
} from '@/graphql/mutations'
import axios from 'axios'
import { handleError } from '@/helpers/ErrorHandler'

export default {
  namespaced: true,
  state: {
    mediaList: [],
    mediaListLoading: false,
    mediaListLoaded: false,
    mediaListSubscription: null,
    selectedMedia: [],
    uploadingQueue: {},
    parentDirectoryMediaId: null
  },
  actions: {
    async getMediaList ({ commit, state, rootGetters }) {
      const parentDirectoryMediaId = rootGetters['media/parentDirectoryMediaId']
      state.mediaListLoading = true
      try {
        const { data: { listWorkspaceMedia } } = await ApolloClient.query({ query: LIST_WORKSPACE_MEDIA, variables: { filters: { parentDirectoryMediaId } }, fetchPolicy: 'no-cache' })
        commit('SET_MEDIA_LIST', listWorkspaceMedia)
      } catch (e) {
        state.mediaListLoading = false
        handleError(e)
      }
    },
    async fetchMedia ({ commit, state, rootGetters }, payload) {
      try {
        const { data: { listWorkspaceMedia } } = await ApolloClient.query({ query: LIST_WORKSPACE_MEDIA, variables: { filters: payload }, fetchPolicy: 'no-cache' })
        return listWorkspaceMedia
      } catch (e) {
        state.mediaListLoading = false
        handleError(e)
      }
    },
    async subscribeToMediaList ({ commit, state, rootGetters }) {
      const pollInterval = 5000
      const parentDirectoryMediaId = rootGetters['media/parentDirectoryMediaId']
      state.mediaListSubscription = ApolloClient.watchQuery({ query: LIST_WORKSPACE_MEDIA, variables: { filters: { parentDirectoryMediaId } }, pollInterval, fetchPolicy: 'no-cache' })
        .subscribe(({ data: { listWorkspaceMedia }, loading }) => {
          commit('SET_MEDIA_LIST', listWorkspaceMedia)
        })
    },
    async fetchGoogleDrives ({ commit, state, rootGetters }, id) {
      try {
        const { data: { googleDriveListDrives } } = await ApolloClient.query({ query: GET_GOOGLE_DRIVES, variables: { googleSocialAccountId: id }, fetchPolicy: 'no-cache' })
        return googleDriveListDrives
      } catch (e) {
        state.mediaListLoading = false
        handleError(e)
      }
    },
    async fetchGoogleDriveFiles ({ commit, state, rootGetters }, payload) {
      try {
        const { data: { googleDriveListFiles } } = await ApolloClient.query({ query: GET_GOOGLE_DRIVE_FILES, variables: payload, fetchPolicy: 'no-cache' })
        return googleDriveListFiles
      } catch (e) {
        state.mediaListLoading = false
        handleError(e)
      }
    },
    async fetchDropboxFolder ({ commit, state, rootGetters }, payload) {
      try {
        const { data: { dropboxFilesListFolder } } = await ApolloClient.query({ query: GET_DROPBOX_FILES, variables: payload, fetchPolicy: 'no-cache' })
        return dropboxFilesListFolder
      } catch (e) {
        state.mediaListLoading = false
        handleError(e)
      }
    },
    async continueFetchDropboxFolder ({ commit, state, rootGetters }, payload) {
      try {
        const { data: { dropboxFilesListFolderContinue } } = await ApolloClient.query({ query: GET_DROPBOX_FILES_CONTINUE, variables: payload, fetchPolicy: 'no-cache' })
        return dropboxFilesListFolderContinue
      } catch (e) {
        state.mediaListLoading = false
        handleError(e)
      }
    },
    async createMedia ({ commit, state, getters, rootGetters }, payload) {
      const parentDirectoryMediaId = getters.parentDirectoryMediaId
      const { data: { createWorkspaceUploadableFileMedia } } = await ApolloClient.mutate({ mutation: CREATE_WORKSPACE_UPLOADABLE_FILE_MEDIA, variables: { input: { parentDirectoryMediaId, ...payload } } })
      commit('ADD_MEDIA', createWorkspaceUploadableFileMedia)
      return createWorkspaceUploadableFileMedia
    },
    async createUrlMedia ({ commit, state, getters, rootGetters }, payload) {
      const parentDirectoryMediaId = getters.parentDirectoryMediaId
      try {
        const { data: { createWorkspaceUrlFileMedia } } = await ApolloClient.mutate({ mutation: CREATE_WORKSPACE_URL_FILE_MEDIA, variables: { input: { parentDirectoryMediaId, ...payload } } })
        commit('ADD_MEDIA', createWorkspaceUrlFileMedia)
        return createWorkspaceUrlFileMedia
      } catch (e) {
        handleError(e)
      }
    },
    async createDirectory ({ commit, state, getters, rootGetters }, name) {
      const parentDirectoryMediaId = getters.parentDirectoryMediaId
      try {
        const { data: { createWorkspaceDirectoryMedia } } = await ApolloClient.mutate({ mutation: CREATE_WORKSPACE_DIRECTORY_MEDIA, variables: { input: { parentDirectoryMediaId, name } } })
        commit('ADD_MEDIA', createWorkspaceDirectoryMedia)
        return createWorkspaceDirectoryMedia
      } catch (e) {
        handleError(e)
      }
    },
    async updateMedia ({ commit, state }, payload) {
      try {
        await ApolloClient.mutate({ mutation: UPDATE_WORKSPACE_UPLOADABLE_FILE_MEDIA_BY_ID, variables: payload })
      } catch (e) {
        handleError(e)
      }
    },
    async updateDirectory ({ commit, state }, payload) {
      try {
        await ApolloClient.mutate({ mutation: UPDATE_WORKSPACE_DIRECTORY_MEDIA_BY_ID, variables: payload })
      } catch (e) {
        handleError(e)
      }
    },
    async updateUrlMedia ({ commit, state }, payload) {
      try {
        await ApolloClient.mutate({ mutation: UPDATE_WORKSPACE_UPLOADABLE_FILE_MEDIA_BY_ID, variables: payload })
      } catch (e) {
        handleError(e)
      }
    },
    async deleteMedia ({ commit, state }, payload) {
      const { id } = payload
      await ApolloClient.mutate({ mutation: DELETE_WORKSPACE_MEDIA_BY_ID, variables: payload })
      commit('REMOVE_MEDIA', id)
    },
    async deleteMediaBulk ({ commit, state }, payload) {
      const { ids } = payload
      await ApolloClient.mutate({ mutation: DELETE_WORKSPACE_MEDIA_BY_IDS, variables: payload })
      commit('REMOVE_MEDIA_BULK', ids)
    },
    async moveMediaBulk ({ commit, state }, payload) {
      const { ids } = payload
      await ApolloClient.mutate({ mutation: MOVE_WORKSPACE_MEDIA_BY_IDS, variables: payload })
      commit('REMOVE_MEDIA_BULK', ids)
    },
    async uploadFile ({ commit, state, rootGetters }, { url, file, id }) {
      const queueObj = { name: file.name, progress: 0, id, size: file.size }
      state.uploadingQueue[id] = queueObj
      const options = {
        headers: {
          'Content-Type': file.type,
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept'
        },
        withCredentials: false,
        onUploadProgress: progressEvent => {
          const currentQueue = { ...state.uploadingQueue }
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          queueObj.progress = percentCompleted
          currentQueue[id] = queueObj
          state.uploadingQueue = currentQueue
        }
      }
      return axios.put(url, file, options).then(() => {
        setTimeout(() => {
          delete state.uploadingQueue[id]
          state.uploadingQueue = { ...state.uploadingQueue }
        }, 300)
      })
    }
  },
  getters: {
    uploadingQueue: state => state.uploadingQueue,
    mediaList: state => state.mediaList,
    mediaListLoading: state => state.mediaListLoading,
    mediaListLoaded: state => state.mediaListLoaded,
    subscribed: state => !!state.mediaListSubscription,
    selectedMedia: state => state.selectedMedia,
    selectedMediaIds: state => state.selectedMedia.map(m => m.id),
    parentDirectoryMediaId: state => state.parentDirectoryMediaId
  },
  mutations: {
    SET_MEDIA_LIST (state, listMedia) {
      state.mediaList = listMedia
      state.mediaListLoading = false
      state.mediaListLoaded = true
    },
    ADD_MEDIA (state, media) {
      state.mediaList = [...state.mediaList, media]
    },
    REMOVE_MEDIA (state, mediaId) {
      state.mediaList = [...state.mediaList.filter(m => mediaId !== m.id)]
    },
    REMOVE_MEDIA_BULK (state, mediaIds) {
      state.mediaList = [...state.mediaList.filter(m => !mediaIds.includes(m.id))]
    },
    SET_SELECTED_MEDIA (state, selectedMedia) {
      state.selectedMedia = selectedMedia
    },
    REMOVE_SELECTED_MEDIA_BY_ID (state, selectedMediaId) {
      state.selectedMedia = [...state.selectedMedia.filter(({id})=> id !== selectedMediaId)]
    },
    CLEAR_SELECTED_MEDIA (state) {
      state.selectedMedia = []
    },
    SET_PARENT_DIRECTORY_MEDIA_ID (state, groupId) {
      state.parentDirectoryMediaId = groupId
    },
    UNSUBSCRIBE_FROM_MEDIA (state) {
      state.mediaListSubscription && state.mediaListSubscription.unsubscribe()
      state.mediaListSubscription = null
    },
    CLEAR_PARENT_DIRECTORY_MEDIA_ID (state) {
      state.parentDirectoryMediaId = null
    },
    CLEAR_UPLOADING_QUEUE (state) {
      state.uploadingQueue = {}
    },
    CLEAR_MEDIA_DATA (state) {
      state.mediaList = []
      state.mediaListLoading = false
      state.mediaListSubscription = null
      state.selectedMedia = []
      state.uploadingQueue = {}
      state.parentDirectoryMediaId = null
    }
  }
}
