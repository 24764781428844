<template>
  <DesignElement
    :title="$t('components.smartTemplateConstructor.foregroundPicker.title')"
    no-border
  >
    <div class="foreground-picker">
      <ColorPicker
        v-if="foreground"
        v-model:color="foregroundColor"
      />
    </div>
  </DesignElement>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import DesignElement from '@/components/smartTemplateConstructor/designElements/DesignElement'
import ColorPicker from '@/components/smartTemplateConstructor/designElements/ColorPicker'
import { DEFAULT_TEMPLATE_FOREGROUND_COLOR } from '@/constants'

export default defineComponent({
  name: 'ForegroundPicker',
  components: {
    ColorPicker,
    DesignElement
  },
  props: {
    foreground: String
  },
  emits: ['update-foreground'],
  setup (props, { emit }) {
    const foregroundColor = ref(props.foreground || DEFAULT_TEMPLATE_FOREGROUND_COLOR)

    const setForegroundColor = (color) => {
      emit('update-foreground', color)
    }

    watch(() => foregroundColor.value, (value) => {
      setForegroundColor(value)
    })

    return {
      foregroundColor,
      setForegroundColor,
    }
  }
})

</script>

<style lang="less">
@import "@/styles/variables.less";

.foreground-picker {
}

</style>
