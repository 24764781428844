import { gql } from 'graphql-tag'

export const ERROR = gql`
  fragment error on Error {
    message
  }
`

export const WORKSPACE_SETTINGS = gql`
  fragment settings on WorkspaceSettings {
    hideWatermark
    useCustomWatermark
    emergencyFeedUrl
    emergencyFeedEnabled
    emergencyFeedDeviceMatchingRule
    whitelabelAccentColor
    demoStorageSizeLimit
  }
`

export const WORKSPACE_CUSTOMER = gql`
  fragment customer on StripeCustomerInfo {
    id
    balance
    discount {
      coupon {
        amount_off
        currency
        duration
        duration_in_months
        name
        percent_off
      }
      end
      start
    }
  }
`

export const WORKSPACE_CHANGE_LOGS = gql`
  fragment workspaceChangelogs on ActivityLogModel {
    id
    type
    createdAt
    author {
      id
      ...on UserModel {
        firstName
        lastName
        avatarMedia {
          ...staticMediaBase
        }
      }
    }
    ... on ActivityLogDeviceContentSyncSettingsUpdatedModel {
      data {
        deviceId
        deviceName
        groupId
        groupName
      }
    }
    ... on ActivityLogDeviceLinkedModel {
      data {
        deviceId
        deviceName
        groupId
        groupName
      }
    }
    ... on ActivityLogDeviceOrientationChangedModel {
      data {
        deviceId
        deviceName
        groupId
        groupName
      }
    }
    ... on ActivityLogDeviceRenamedModel {
      data {
        deviceId
        deviceName
        groupId
        groupName
      }
    }
    ... on ActivityLogDeviceUnlinkedModel {
      data {
        deviceId
        deviceName
        groupId
        groupName
      }
    }
    ... on ActivityLogGroupCreatedModel {
      data {
        groupId
        groupName
      }
    }
    ... on ActivityLogGroupDeletedModel {
      data {
        groupId
        groupName
      }
    }
    ... on ActivityLogGroupMovedModel {
      data {
        groupId
        groupName
      }
    }
    ... on ActivityLogGroupRenamedModel {
      data {
        groupId
        groupName
      }
    }
    ... on ActivityLogMediaCreatedModel {
      data {
        mediaId
        mediaName
      }
    }
    ... on ActivityLogMediaDeletedModel {
      data {
        mediaId
        mediaName
      }
    }
    ... on ActivityLogMediaUploadedModel {
      data {
        mediaId
        mediaName
      }
    }
    ... on ActivityLogPlaylistCopiedModel {
      data {
        playlistId
        playlistName
        fromGroupId
        fromGroupName
        toGroupId
        toGroupName
      }
    }
    ... on ActivityLogPlaylistCreatedModel {
      data {
        playlistId
        playlistName
        groupId
        groupName
      }
    }
    ... on ActivityLogPlaylistDeletedModel {
      data {
        playlistId
        playlistName
        groupId
        groupName
      }
    }
    ... on ActivityLogPlaylistMovedModel {
      data {
        playlistId
        playlistName
        fromGroupId
        fromGroupName
        toGroupId
        toGroupName
      }
    }
    ... on ActivityLogPlaylistSlideChangedDurationModel {
      data {
        slideId
        slideName
        widgetType
        playlistId
        playlistName
        groupId
        groupName
      }
    }
    ... on ActivityLogPlaylistSlideChangedOrderModel {
      data {
        slideId
        slideName
        widgetType
        playlistId
        playlistName
        groupId
        groupName
      }
    }
    ... on ActivityLogPlaylistSlideCreatedModel {
      data {
        slideId
        slideName
        widgetType
        playlistId
        playlistName
        groupId
        groupName
      }
    }
    ... on ActivityLogPlaylistSlideDeletedModel {
      data {
        slideId
        slideName
        widgetType
        playlistId
        playlistName
        groupId
        groupName
      }
    }
    ... on ActivityLogPlaylistSlideDuplicatedModel {
      data {
        slideId
        slideName
        widgetType
        playlistId
        playlistName
        groupId
        groupName
      }
    }
    ... on ActivityLogPlaylistSlideImportedModel {
      data {
        slideId
        slideName
        widgetType
        playlistId
        playlistName
        groupId
        groupName
      }
    }
    ... on ActivityLogPlaylistSlideMutedModel {
      data {
        slideId
        slideName
        widgetType
        playlistId
        playlistName
        groupId
        groupName
      }
    }
    ... on ActivityLogPlaylistSlideUnmutedModel {
      data {
        slideId
        slideName
        widgetType
        playlistId
        playlistName
        groupId
        groupName
      }
    }
    ... on ActivityLogPlaylistSlideUpdatedModel {
      data {
        slideId
        slideName
        widgetType
        playlistId
        playlistName
        groupId
        groupName
      }
    }
    ... on ActivityLogPlaylistVersionPublishedModel {
      data {
        playlistId
        playlistName
        groupId
        groupName
      }
    }
    ... on ActivityLogPlaylistZoneSettingsUpdatedModel {
      data {
        playlistId
        playlistName
        groupId
        groupName
      }
    }
    ... on ActivityLogPlaylistTransitionTypeChangedModel {
      data {
        playlistId
        playlistName
        groupId
        groupName
      }
    }
    ... on ActivityLogScheduleAddedModel {
      data {
        playlistId
        playlistName
        scheduleId
        groupId
        groupName
      }
    }
    ... on ActivityLogScheduleRemovedModel {
      data {
        playlistId
        playlistName
        scheduleId
        groupId
        groupName
      }
    }
    ... on ActivityLogSocialAccountLinkedModel {
      data {
        socialAccountId
        linkedSocialAccountName: socialAccountName 
      }
    }
    ... on ActivityLogSocialAccountUnlinkedModel {
      data {
        socialAccountId
        unlinkedSocialAccountName: socialAccountName
      }
    }
    ... on ActivityLogWorkspaceUserRoleChangedModel {
      data {
        userId
        userName
        role
      }
    }
    ... on ActivityLogWorkspaceUserRoleInvitedModel {
      data {
        role
        email
      }
    }
    ... on ActivityLogWorkspaceUserRoleResetInviteModel {
      data {
        role
        email
      }
    }
    ... on ActivityLogWorkspaceUserRoleRestrictionsUpdatedModel {
      data {
        userId
        userName
      }
    }
    ... on ActivityLogWorkspaceUserRoleRevokedAccessModel {
      data {
        userId
        userName
      }
    }
  }
`

export const STANDARD_GROUP_BASE = gql`
  fragment standardGroupBase on StandardGroupModel {
    id
    name
    type
    parentGroupId
    language
    createdAt
  }
`

export const SMART_GROUP_BASE = gql`
  fragment smartGroupBase on SmartGroupModel {
    id
    name
    type
    createdAt
  }
`

export const STANDARD_GROUP_EXTENDED = gql`
  ${STANDARD_GROUP_BASE}
  fragment standardGroupExtended on StandardGroupModel {
    ...standardGroupBase
    timezone
    createdAt
    updatedAt
    workspaceId
    authorIdentityId
  }
`

export const SMART_GROUP_EXTENDED = gql`
  ${SMART_GROUP_BASE}
  fragment smartGroupExtended on SmartGroupModel {
    ...smartGroupBase
    deviceMatchingRule
    createdAt
    updatedAt
    workspaceId
    authorIdentityId
  }
`

export const MEDIA_METADATA_VIDEO = gql`
  fragment mediaMetadataVideo on MediaVideoMetadata {
    duration
  }
`

export const MEDIA_METADATA_DOCUMENT = gql`
  fragment mediaMetadataDocumemt on MediaDocumentMetadata {
    numPages
  }
`

export const MEDIA_METADATA_IMAGE = gql`
  fragment mediaMetadataImage on MediaImageMetadata {
    width
    height
  }
`

export const MEDIA_METADATA = gql`
  ${MEDIA_METADATA_VIDEO}
  ${MEDIA_METADATA_DOCUMENT}
  ${MEDIA_METADATA_IMAGE}
  fragment mediaMetadata on MediaMetadata {
    ...mediaMetadataVideo
    ...mediaMetadataDocumemt
    ...mediaMetadataImage
  }
`

export const BASIC_MEDIA_BASE = gql`
  fragment basicMediaBase on BasicMediaModel {
    id
    processingStatus
    metadata {
      name
    }
    generatedMedia {
      id
      tag
      url
    }
  }
`

export const STATIC_MEDIA_BASE = gql`
  fragment staticMediaBase on StaticMediaModel {
    id
    processingStatus
    url
    path
    metadata {
      name
    }
    generatedMedia {
      id
      tag
      url
    }
  }
`
export const EXTERNAL_URL_MEDIA_BASE = gql`
  fragment externalUrlMediaBase on ExternalUrlFileMediaModel {
    id
    url
    metadata {
      name
    }
  }
`

export const WORKSPACE_MEDIA_BASE = gql`
  fragment workspaceMediaBase on WorkspaceMediaModel {
    id
    createdAt
    updatedAt
    name
    type
    parentDirectoryMediaId
    authorIdentityId
  }
`

export const WORKSPACE_DIRECTORY_MEDIA_BASE = gql`
  fragment workspaceDirectoryMediaBase on WorkspaceDirectoryMediaModel {
    id
    name
  }
`

export const WORKSPACE_DIRECTORY_MEDIA_EXTENDED = gql`
  ${WORKSPACE_DIRECTORY_MEDIA_BASE}
  fragment workspaceDirectoryMediaExtended on WorkspaceDirectoryMediaModel {
    ...workspaceDirectoryMediaBase
    createdAt
    updatedAt
    mimetype
    type
    parentDirectoryMediaId
    childMedia {
      id
      type
    }
  }
`

export const WORKSPACE_UPLOADABLE_FILE_MEDIA_BASE = gql`
  ${MEDIA_METADATA_VIDEO}
  ${MEDIA_METADATA_DOCUMENT}
  fragment workspaceUploadableFileMediaBase on WorkspaceUploadableFileMediaModel {
    id
    name
    processingData {
      profile
      error
    }
    generatedMedia {
      id
      tag
      url
    }
    putObjectSignedUrl
    metadata {
      ...mediaMetadataVideo
      ...mediaMetadataDocumemt  
    }
  }
`

export const WORKSPACE_UPLOADABLE_FILE_MEDIA_EXTENDED = gql`
  ${WORKSPACE_UPLOADABLE_FILE_MEDIA_BASE}
  ${MEDIA_METADATA_VIDEO}
  ${MEDIA_METADATA_DOCUMENT}
  fragment workspaceUploadableFileMediaExtended on WorkspaceUploadableFileMediaModel {
    ...workspaceUploadableFileMediaBase
    createdAt
    updatedAt
    path
    isUploaded
    size
    url
    mimetype
    processingData {
      profile
      error
    }
    metadata {
      ...mediaMetadataVideo
      ...mediaMetadataDocumemt
    }
  }
`

export const WORKSPACE_URL_FILE_MEDIA_BASE = gql`
  fragment workspaceUrlFileMediaBase on WorkspaceUrlFileMediaModel {
    id
    name
    mediaUrl
    thumbnailUrl
  }
`

export const WORKSPACE_URL_FILE_MEDIA_EXTENDED = gql`
  ${WORKSPACE_URL_FILE_MEDIA_BASE}
  fragment workspaceUrlFileMediaExtended on WorkspaceUrlFileMediaModel {
    ...workspaceUrlFileMediaBase
    createdAt
    updatedAt
    type
    parentDirectoryMediaId
    authorIdentityId
  }
`

export const GOOGLE_DRIVE_MEDIA_BASE = gql`
  fragment googleDriveMediaBase on GoogleDriveMediaModel {
    id
    processingStatus
    sourceType
    gDriveAdditionalInfo: additionalInfo {
      name
    }
    processingData {
      profile
      error
    }
    generatedMedia {
      id
      tag
      url
    }
  }
`

export const DROPBOX_MEDIA_BASE = gql`
  fragment dropboxMediaBase on DropboxMediaModel {
    id
    processingStatus
    sourceType
    dropboxAdditionalInfo: additionalInfo {
      name
    }
    processingData {
      profile
      error
    }
    generatedMedia {
      id
      tag
      url
    }
  }
`

export const USER_BASE = gql`
  fragment userBase on UserModel {
    id
    email
    firstName
    lastName
    phone
    is2faSet
    userCreatedBy
    onboardingFinished
    onboardingStep
    features {
      ownedWorkspacesLimit
    }
  }
`

export const WORKSPACE_BASE = gql`
  fragment workspaceBase on WorkspaceModel {
    id
    shortId
    name
    myRole
    createdAt
    updatedAt
    frozen
    frozenReason
  }
`

export const WORKSPACE_FEATURES = gql`
  fragment workspaceFeatures on WorkspaceFeatures {
    externalApiEnabled
    authorRoleEnabled
    whitelabelEnabled
    ssoEnabled
    nestedGroupsEnabled
    playlistVersionsEnabled
    multipleSocialAccountsEnabled
    externalMediaSyncEnabled
    customWatermarkEnabled
    smartGroupsEnabled
    forcedHideDemoCoverEnabled
    slideEditModalEnabled
  }
`

export const WORKSPACE_ROLE_NOTIFICATION_SETTINGS = gql`
  fragment notificationSettings on WorkspaceRoleNotificationSettings {
    deviceStatusChanges {
      enabled
      groupIds
    }
  }
`
export const WORKSPACE_ROLE_RESTRICTIONS = gql`
  fragment restrictions on WorkspaceRoleRestrictions {
    groupIds
    playlistIds
  }
`

export const WORKSPACE_BILLING_INFO_BASE = gql`
  fragment billingInfoBase on WorkspaceBillingInfo {
    enterprize
    status
    paused
    tier
    currentPeriodEnd
    currentPeriodStart
    stripeCustomPriceId
    stripeGrowthDevicesQuantityLimit
    freeDevicesQuantity
    billingDevicesQuantity
    billingInterval
  }
`

export const DEVICE_BASE = gql`
  fragment deviceBase on DeviceModel {
    id
    name
    createdAt
    updatedAt
    groupId
    orientation
    isOnline
    appVersion
    hardwareInfo {
      tvos
    }
    contentGatheringWindow {
      startDailyWindowTimeMs
      endDailyWindowTimeMs
    }
    tags {
      key 
      title
      isSystemGenerated
    }
  }
`

export const SOCIAL_ACCOUNT_BASE = gql`
  fragment socialAccountBase on SocialAccountModel {
    id
    type
    createdAt
    updatedAt
  }
`

export const SOCIAL_ACCOUNT_TWITTER_BASE = gql`
  ${ERROR}
  fragment socialAccountTwitterBase on SocialAccountTwitterModel {
    info {
      ... on SocialAccountTwitterInfo {
        name
        screen_name  
      }
      ...error
    }
  }
`
export const SOCIAL_ACCOUNT_INSTAGRAM_BASE = gql`
  ${ERROR}
  fragment socialAccountInstagramBase on SocialAccountInstagramModel {
    info {
      ... on SocialAccountInstagramInfo {
        name
        username  
      }
      ...error
    }
  }
`
export const SOCIAL_ACCOUNT_GOOGLE_BASE = gql`
  ${ERROR}
  fragment socialAccountGoogleBase on SocialAccountGoogleModel {
    info {
      ... on SocialAccountGoogleInfo {
        name
        given_name  
      }
      ...error
    }
  }
`
export const SOCIAL_ACCOUNT_MICROSOFT_BASE = gql`
  ${ERROR}
  fragment socialAccountMicrosoftBase on SocialAccountMicrosoftModel {
    info {
      ... on SocialAccountMicrosoftInfo {
        name
        username  
      }
      ...error
    }
  }
`

export const SOCIAL_ACCOUNT_DROPBOX_BASE = gql`
  ${ERROR}
  fragment socialAccountDropboxBase on SocialAccountDropboxModel {
    info {
      ... on SocialAccountDropboxInfo {
        given_name
        surname
        familiar_name
        display_name
        abbreviated_name
      }
      ...error
    }
  }
`

export const SOCIAL_ACCOUNTS_ALL_BASE = gql`
  ${SOCIAL_ACCOUNT_TWITTER_BASE}
  ${SOCIAL_ACCOUNT_INSTAGRAM_BASE}
  ${SOCIAL_ACCOUNT_GOOGLE_BASE}
  ${SOCIAL_ACCOUNT_MICROSOFT_BASE}
  ${SOCIAL_ACCOUNT_DROPBOX_BASE}
  fragment socialAccountsAllModelsBase on SocialAccountModel {
    ...socialAccountTwitterBase
    ...socialAccountInstagramBase
    ...socialAccountGoogleBase
    ...socialAccountMicrosoftBase
    ...socialAccountDropboxBase
  }
`

export const GROUP_SCHEDULE = gql`
  fragment groupSchedule on ScheduleModel {
    id
    groupId
    data {
      elements {
        playlistId
        type
        playlist {
          name
          color
          paused
        }
        ... on ScheduleElementOnetimeWindow {
          startDate
          endDate
        },
        ... on ScheduleElementRepeatableWeeklyWindow {
          startWeeklyWindowTimeMs
          endWeeklyWindowTimeMs
        }
      }
      forcedPlaylist {
        id
        name
        groupId
      }
      forcedPlaylistId
    }
    createdAt
    updatedAt
  }
`
export const PAGINATION = gql`
  fragment pagination on OffsetPaginationOutput {
    total
    limit
    offset
  }
`

export const SLIDE_BASE = gql`
  fragment slideBase on PlaylistLayoutZoneSlide {
    id
    duration
    paused
    name
    tags
    widgetId
  }
`

const SMART_TEMPLATE_BACKGROUND = gql`
  fragment smartTemplateBackground on WidgetSmartTemplateBackground {
    ...on WidgetSmartTemplateBackgroundAnimation {
      animation
    }  
    ...on WidgetSmartTemplateBackgroundColor {
      color
    }
    ...on WidgetSmartTemplateBackgroundMediaImage {
      mediaImageReference {
        ...mediaReference
      }
    }  
    ...on WidgetSmartTemplateBackgroundMediaVideo {
      mediaVideoReference {
        ...mediaReference
      }
    }
  }
`

const MEDIA_REFERENCE = gql`
  fragment mediaReference on MediaReference {
    sourceType
    ... on WorkspaceMediaReference {
      workspaceMediaId
    }
    ... on GoogleDriveMediaReference {
      externalMediaId
      socialGoogleAccountId
      googleDriveMediaId
    }
    ... on DropboxMediaReference {
      externalMediaId
      socialDropboxAccountId
      dropboxMediaId
    }
  }
`

export const WIDGET = gql`
  ${ERROR}
  ${MEDIA_REFERENCE}
  ${STATIC_MEDIA_BASE}
  ${WORKSPACE_UPLOADABLE_FILE_MEDIA_BASE}
  ${GOOGLE_DRIVE_MEDIA_BASE}
  ${DROPBOX_MEDIA_BASE}
  ${SMART_TEMPLATE_BACKGROUND}
  fragment widget on WidgetModel {
    id
    type
    ... on WidgetNewsModel {
      data {
        durationPerItem
        topics
      }
    },
    ... on WidgetWorldClockModel {
      data {
        locations {
          address
          timezone
          latitude
          longitude
        }
      }
    },
    ... on WidgetSocialActivityModel {
      socialActivity: data {
        durationPerItem
        socialInstagramAccountIds
        socialTwitterAccountIds
        feedType
        hashtags
        includeOwnActivity,
        muted
      }
    },
    ... on WidgetSocialTwitterModel {
      socialTwitter: data {
        durationPerItem
        socialTwitterAccountIds
        feedType
        hashtags
        includeOwnTweets,
        muted
      }
    },
    ... on WidgetSocialInstagramModel {
      socialInstagram: data {
        durationPerItem
        socialInstagramAccountIds
        feedType
        view
        hashtags
        includeOwnInstagramMedia,
        muted
      }
    },
    ... on WidgetSocialTiktokModel {
      socialTiktok: data {
        hashtags,
        mentions,
        maxTimeMsPerVideo,
        muted
      }
    },
    ... on WidgetFeedModel {
      data {
        url
        durationPerItem
      }
    },
    ... on WidgetWebsiteModel {
      data {
        url
      }
    },
    ... on WidgetStreamModel {
      data {
        url
        duration
        thumbnailUrl
        muted
        ccEnabled
        objectFit
        forcedCaptions {
          start
          duration
          text
        }
      }
    },
    ... on WidgetQuotesModel {
      data {
        categories
        durationPerItem
      }
    },
    ... on WidgetCovidStatsModel {
      data {
        country
      }
    },
    ... on WidgetMediaImagesModel {
      mediaImages {
        ...workspaceUploadableFileMediaBase
        ...googleDriveMediaBase
        ...dropboxMediaBase
      }
      data {
        objectFit
        view
        shuffle
        durationPerItem
        mediaImageReferences {
          ...mediaReference
        }
      }
    },
    ... on WidgetMediaVideoModel {
      data {
        objectFit
        muted
        mediaVideoReference {
          ...mediaReference
        }
      }
      mediaVideo {
        ...workspaceUploadableFileMediaBase
        ...googleDriveMediaBase
        ...dropboxMediaBase

      }
    }
    ... on WidgetMediaDocumentModel {
      data {
        objectFit
        durationPerItem
        mediaDocumentReference {
          ...mediaReference
        }
      }
      mediaDocument {
        ...workspaceUploadableFileMediaBase
        ...googleDriveMediaBase
        ...dropboxMediaBase
      }
    }
    ... on WidgetWeatherModel {
      data {
        metric
        location {
          latitude
          longitude
          address
        }
      }
    }
    ... on WidgetYelpModel {
      data {
        businessIdOrAlias
        durationPerItem
      }
    }
    ... on WidgetTripAdvisorModel {
      data {
        locationId
      }
      locationDetails {
        ...on TripAdvisorLocationDetails {
          address_obj {
            address_string
          }
          location_id
          name
        }
      }
    }
    ... on WidgetGoogleReviewsModel {
      data {
        placeId
      }
      placeDetails {
        ...on GooglePlaceDetails {
          place_id
          name
          business_status
          formatted_address
          url
        }
        ...on Error {
          message
        }
      }
    }
    ... on WidgetAIContentModel {
      data {
        pexelsQuery
        openaiPrompt
        text {
          value
          position {
            vertical
            horizontal
          }
          font {
            face
            size
            color
          }
          background {
            color
            opacity
          }
        }
        pexelsPhotoId
        pexelsVideoId
      }
      pexelsPhoto {
        ...on PexelsPhoto {
          id
          src {
            original
            large2x
            large
            medium
            small
            portrait
            landscape
            tiny
          }
        }
        ...on Error {
          message
        }
      }
      pexelsVideo {
        ...on PexelsVideo {
          id
          video_files {
            id
            quality
            width
            height
            link
            fps
          }
        }
        ...on Error {
          message
        }
      }
    }
    ... on WidgetCalendarGoogleModel {
      googleCalendar: data {
        view
        socialGoogleAccountId
        calendarIds
      }
      googleCalendars: calendars {
        ... on GoogleCalendarsList {
          list {
            description
            summary
            id
          } 
        }
        ...error
      }
    }
    ... on WidgetCalendarMicrosoftModel {
      microsoftCalendar: data {
        view
        socialMicrosoftAccountId
        calendarIds
      }
      microsoftCalendars: calendars {
        ... on MicrosoftCalendarsList {
          list {
            name
            id  
          }
        }
        ...error
      }
    }
    ... on WidgetSmartTemplateTextModel {
      backgroundMedia {
        ...workspaceUploadableFileMediaBase
        ...googleDriveMediaBase
        ...dropboxMediaBase
      }
      smartTemplate: data {
        foreground
        layout
        background {
          ...smartTemplateBackground
        }
        caption
        header
        subHeader
      }
    }
    ... on WidgetSmartTemplateSaleModel {
      backgroundMedia {
        ...workspaceUploadableFileMediaBase
        ...googleDriveMediaBase
        ...dropboxMediaBase
      }
      smartTemplate: data {
        foreground
        layout
        background {
          ...smartTemplateBackground
        }
        caption
        discount
        header
        description
      }
    }
    ... on WidgetSmartTemplateMenuModel {
      backgroundMedia {
        ...workspaceUploadableFileMediaBase
        ...googleDriveMediaBase
        ...dropboxMediaBase
      }
      mediaImage {
        ...workspaceUploadableFileMediaBase
        ...googleDriveMediaBase
        ...dropboxMediaBase
      }
      smartTemplate: data {
        foreground
        background {
          ...smartTemplateBackground
        }
        caption
        header
        subHeader
        price
        comment
        mediaImageReference {
          ...mediaReference
        }
      }
    }
    ... on WidgetSmartTemplateDirectoryModel {
      backgroundMedia {
        ...workspaceUploadableFileMediaBase
        ...googleDriveMediaBase
        ...dropboxMediaBase
      }
      smartTemplate: data {
        foreground
        background {
          ...smartTemplateBackground
        }
        directories {
          number
          title
          description
          direction
        }
      }
    }
    ... on WidgetSmartTemplateFloorMapModel {
      backgroundMedia {
        ...workspaceUploadableFileMediaBase
        ...googleDriveMediaBase
        ...dropboxMediaBase
      }
      smartTemplate: data {
        foreground
        background {
          ...smartTemplateBackground
        }
        floor
        sections
      }
    }
    ... on WidgetSmartTemplateScheduleModel {
      backgroundMedia {
        ...workspaceUploadableFileMediaBase
        ...googleDriveMediaBase
        ...dropboxMediaBase
      }
      smartTemplate: data {
        foreground
        background {
          ...smartTemplateBackground
        }
        header
        workingHours {
          day
          time
        }
      }
    }
    ... on WidgetSmartTemplateFacebookModel {
      logoMediaImage {
        ...workspaceUploadableFileMediaBase
        ...googleDriveMediaBase
        ...dropboxMediaBase
      }
      backgroundMedia {
        ...workspaceUploadableFileMediaBase
        ...googleDriveMediaBase
        ...dropboxMediaBase
      }
      smartTemplate: data {
        foreground
        background {
          ...smartTemplateBackground
        }
        layout
        address
        logoMediaImageReference {
          ...mediaReference
        }
      }
    }
    ... on WidgetTickerModel {
      data {
        tickers {
          name
          symbol
          interval
        }
      }
    }
    ... on WidgetRapidApiHoroscopesModel {
      data {
        signs
        period
        horoscopeType
        durationPerItem
      }
    }
    ... on WidgetRapidApiPublicHolidaysModel {
      data {
        country
      }
    }
    ... on WidgetRapidApiTedTalksModel {
      data {
        continueVideoOnRestarts
        tedTalkId
        muted
        objectFit
      }
      tedTalk {
        ... on RapidApiTedTalkInfo {
          id
          url
          title
          description
          audio_language
          event
          publish_date
          record_date
          duration_in_seconds
          thumbnail_url
          mp4_url
          embed_url
        }
        ... on Error {
          message
        }
      }
    }
    ... on WidgetRapidApiTastyModel {
      data {
        tags
        muted
        objectFit
        continueVideoOnRestarts
      }
      tags {
        display_name
      }
    }
    
    ... on WidgetImageComposerModel {
      data {
        aspectRatio
      }
      imageMedia {
        ...staticMediaBase
      }
    }
  }
`

export const STRIPE_BILLING_INFO = gql`
  fragment stripeBillingInfo on StripeSubscriptionBillingInfo {
    billingDevicesQuantity
    currentPeriodEnd
    currentPeriodStart
    currentPeriodInterval
    billingAmount
    billingInterval
    status
    tier
  }
`

export const SUBSCRIPTION = gql`
  ${STRIPE_BILLING_INFO}
  fragment subscriptionInfo on StripeSubscriptionInfo {
    billing_cycle_anchor
    canceled_at
    created
    current_period_start
    current_period_end
    discount {
      coupon {
        amount_off
        currency
        duration
        duration_in_months
        name
        percent_off
      }
      end
      start
    }
    ended_at
    next_pending_invoice_item_invoice
    start_date
    status
    trial_end
    trial_start
    billingItemsInfo {
      billingDevicesQuantity
    }
    billingInfo {
      ...stripeBillingInfo
    }
    latestInvoice {
      id
      amount_due
      amount_paid
      amount_remaining
      attempt_count
      attempted
      auto_advance
      collection_method
      created
      due_date
      ending_balance
      starting_balance
      hosted_invoice_url
      invoice_pdf
      next_payment_attempt
      paid
      period_end
      period_start
      post_payment_credit_notes_amount
      pre_payment_credit_notes_amount
      receipt_number
      status
      paymentIntent {
        id
        amount
        amount_capturable
        amount_received
        canceled_at
        cancellation_reason
        capture_method
        client_secret
        confirmation_method
        created
        setup_future_usage
        status
      }
    }
  }
`

export const STRIPE_PRICE_INFO = gql`
  fragment priceInfo on StripePriceInfo {
    id
    active
    billing_scheme
    created
    currency
    custom_unit_amount {
      maximum
      minimum
      preset
    }
    lookup_key
    nickname
    product
    recurring {
      interval
      interval_count
      trial_period_days
    }
    tiers {
      flat_amount_decimal
      unit_amount_decimal
      up_to
    }
    tiers_mode
    unit_amount_decimal
  }
`


export const STANDARD_GROUP_PLAYLISTS_BASE = gql`
  fragment standardGroupPlaylistsBase on StandardGroupModel {
    playlists {
      id
      name
      isMainPlaylist
    }
  }
`

export const SMART_GROUP_PLAYLISTS_BASE = gql`
  fragment smartGroupPlaylistsBase on SmartGroupModel {
    playlists {
      id
      name
    }
  }
`

export const PLAYLIST_BASE = gql`
  fragment playlistBase on PlaylistModel {
    id
    isMainPlaylist
    isPublished
    version
    paused
    color
    name
  }
`

export const PLAYLIST_CHANGELOGS = gql`
  ${STATIC_MEDIA_BASE}
  fragment playlistChangelogs on PlaylistChangeLogModel {
    id
    playlistId
    playlistVersion
    authorIdentityId
    author {
      ... on UserModel {
        id
        firstName
        lastName
        avatarMedia {
          ...staticMediaBase
        }
      }
      
    }
    type
    createdAt
    ...on PlaylistChangeLogSlideChangedOrderModel {
      data {
        slideId
        slideName
      }
    }
    ...on PlaylistChangeLogSlideChangedDurationModel {
      data {
        slideId
        slideName
      }
    }
    ...on PlaylistChangeLogSlideCreatedModel {
      data {
        slideId
        slideName
      }
    }
    ...on PlaylistChangeLogSlideDeletedModel {
      data {
        slideId
        slideName
      }
    }
    ...on PlaylistChangeLogSlideDuplicatedModel {
      data {
        slideId
        slideName
      }
    }
    ...on PlaylistChangeLogSlideImportedModel {
      data {
        slideId
        slideName
      }
    }
    ...on PlaylistChangeLogSlideMutedModel {
      data {
        slideId
        slideName
      }
    }
    ...on PlaylistChangeLogSlideUnmutedModel {
      data {
        slideId
        slideName
      }
    }
    ...on PlaylistChangeLogSlideUpdatedModel {
      data {
        slideId
        slideName
      }
    }
  }
`

export const PLAYLIST_EXTENDED = gql`
  ${PLAYLIST_BASE}
  fragment playlistExtended on PlaylistModel {
    ...playlistBase
    createdAt
    updatedAt
    groupId
    authorIdentityId
  }
`

export const UPDATED_PLAYLIST = gql`
  ${SLIDE_BASE}
  ${WIDGET}
  ${PLAYLIST_BASE}
  ${EXTERNAL_URL_MEDIA_BASE}
  ${STATIC_MEDIA_BASE}
  fragment updatedPlaylist on PlaylistModel {
    ...playlistBase
    mediaAudio {
      ...staticMediaBase
      ...externalUrlMediaBase
    }
    layout {
      transitionType
      type
      shuffle
      musicType
      zones {
        id
        slides {
          ...slideBase
          widget {
            ...widget
          }
        }
      }
    }
  }
`

// WEBHOOKS

export const WEBHOOK_BASE = gql`
  fragment webhookBase on WebhookModel {
    id
    name
    url
    enabled
    createdAt
    updatedAt
  }
`

//API KEYS

export const API_KEYS_BASE = gql`
  fragment apiKeyBase on ExternalApiKeyModel {
    createdAt
    deletedAt
    id
    lastActivityAt
    name
    updatedAt
    workspaceId
  }
`
