<template>
  <div style="height: 100%">
    <a-layout class="full-height groups-view">
      <router-view name="InnerSider" />
      <a-layout-content>
        <router-view
          v-if="groupId"
          @delete-playlist="onDeletePlaylist"
        />
      </a-layout-content>
    </a-layout>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { error, success } from '@/utils'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'GroupsView',
  props: {
    groupId: String,
    playlistId: String
  },
  setup (props) {
    const store = useStore()
    const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()
    const playlists = computed(() => store.getters['groups/currentGroupPlaylists'])
    const availableGroupsLoaded = computed(() => store.getters['groups/availableGroupsLoaded'])
    const currentGroupPlaylists = computed(() => store.getters['groups/currentGroupPlaylists'])
    const currentGroupDefaultPlaylistId = computed(() => store.getters['groups/currentGroupDefaultPlaylistId'])
    const isBaseRoute = computed(() => route.name === 'Groups')

    onMounted(() => {
      availableGroupsLoaded.value && handleGroupChange()
    })

    const handleGroupChange = () => {
      store.dispatch('groups/setCurrentGroup', { groupId: props.groupId }).then(() => {
        setRoute()
      })
    }

    const setRoute = async () => {
      if (isBaseRoute.value) {
        await goToDefaultPlaylist()
      } else if (props.playlistId) {
        await goToPlaylist()
      }
    }

    const goToDefaultPlaylist = () => {
      return router.replace({ name: 'PlaylistPage', params: { playlistId: currentGroupDefaultPlaylistId.value } })
    }

    const goToPlaylist = () => {
      const playlistExists = currentGroupPlaylists.value?.some((pl) => pl.id === props.playlistId)
      const playlistId = playlistExists ? props.playlistId : currentGroupDefaultPlaylistId.value
      return router.replace({ name: 'PlaylistPage', params: { playlistId } })
    }

    const onDeletePlaylist = (id) => {
      if (playlists.value.length === 1) return error(t('components.groupsView.cantDeleteLast'))

      const currentIndex = playlists.value.findIndex(p => p.id === id)
      const nextIndex = currentIndex === 0 ? 0 : currentIndex - 1

      store.dispatch('groups/deleteCurrentGroupPlaylist', { id, force: true }).then(() => {
        success()
        router.push({ name: 'PlaylistPage', params: { playlistId: playlists.value[nextIndex]?.id } })
      }).catch((e) => {
        error(e.message || 'Error')
      })
    }

    watch(() => props.groupId, () => {
      handleGroupChange()
    })

    watch(() => route.name, () => {
      if (isBaseRoute.value) {
        goToDefaultPlaylist()
      }
    })

    watch(() => availableGroupsLoaded.value, (newValue, oldValue) => {
      if (!oldValue) {
        handleGroupChange()
      }
    })

    return {
      onDeletePlaylist
    }
  }
})
</script>

<style lang="less">
.groups-view {
  .ant-layout-content {
    .ant-spin-nested-loading {
      overflow: hidden;
      flex: 1;
      .ant-spin-container {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>
