<template>
  <a-collapse
    :bordered="false"
    class="working-hours-element"
    ghost
  >
    <a-collapse-panel
      key="panel"
      :header="$t('components.smartTemplateConstructor.workingHoursElement.title')"
    >
      <template
        v-if="deletable"
        #extra
      >
        <a-tooltip
          :title="$t('components.smartTemplateConstructor.workingHoursElement.deleteTooltipTitle')"
          placement="left"
        >
          <a-button
            danger
            size="small"
            type="text"
            @click.stop="onDeleteWorkingHours"
          >
            <DeleteOutlined />
          </a-button>
        </a-tooltip>
      </template>
      <TextElement
        v-model:value-model="fieldsState.day"
        :max-length="10"
        :title="$t('components.smartTemplateConstructor.workingHoursElement.dateInputTitle')"
      />
      <TextElement
        v-model:value-model="fieldsState.time"
        :max-length="15"
        :title="$t('components.smartTemplateConstructor.workingHoursElement.timeInputTitle')"
      />
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import { DeleteOutlined } from '@ant-design/icons-vue'
import TextElement from '@/components/smartTemplateConstructor/segmentElements/TextElement'

export default defineComponent({
  name: 'WorkingHoursElement',
  components: {
    TextElement,
    DeleteOutlined
  },
  props: {
    deletable: Boolean,
    schedule: Object
  },
  emits: ['delete'],
  setup (props, { emit }) {
    const fieldsState = reactive(props.schedule)

    const onDeleteWorkingHours = () => {
      emit('delete')
    }
    return {
      fieldsState,
      onDeleteWorkingHours
    }
  }
})
</script>

<style lang="less" scoped>
.working-hours-element {
  :deep(  > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box) {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  :deep( > .ant-collapse-item > .ant-collapse-header) {
    padding-left: 0;
    padding-right: 0;
  }

  :deep(.ant-form-item) {
    display: block;
    margin-bottom: 4px;
  }
}
</style>
