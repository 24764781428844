<template>
  <a-modal
    :open="showMedia"
    width="100%"
    :footer="null"
    wrap-class-name="full-modal media-modal"
    @ok="closeModal"
    @cancel="closeModal"
  >
    <template #title>
      <ModalHeading
        :title="$t('components.mediaModal.title')"
        :subtitle="$t('components.mediaModal.subtitle')"
      />
    </template>
    <MediaView
      selection="multiple"
      external-selection="none"
    />
  </a-modal>
</template>

<script>

import { computed, defineComponent } from 'vue'
import MediaView from '@/views/MediaView'
import { useStore } from 'vuex'
import ModalHeading from '@/components/ModalHeading.vue'

export default defineComponent({
  name: 'MediaModal',
  components: { ModalHeading, MediaView },
  setup () {
    const store = useStore()
    const showMedia = computed(() => store.getters.showMedia)

    const closeModal = () => {
      store.commit('media/CLEAR_PARENT_DIRECTORY_MEDIA_ID')
      store.commit('media/CLEAR_SELECTED_MEDIA')
      store.dispatch('closeMedia')
    }

    return {
      showMedia,
      closeModal
    }
  }
})
</script>

<style lang="less">

</style>
