<template>
  <div class="working-hours-editor">
    <TextElement
      v-model:value-model="fieldsState.header"
      :max-length="25"
      :title="$t('components.smartTemplateConstructor.workingHoursEditor.headerInputTitle')"
    />
  </div>
  <WorkingHoursElement
    v-for="(scheduleItem, $index) in fieldsState.workingHours"
    :key="'schedule-item'+$index"
    :deletable="fieldsState.workingHours?.length > 1"
    :schedule="scheduleItem"
    @delete="deleteRow($index)"
  />
  <a-button
    :disabled="!canAddLocation"
    block
    type="primary"
    @click="addRow"
  >
    {{ $t('components.smartTemplateConstructor.workingHoursEditor.addDirectoryButtonText') }}
  </a-button>
</template>

<script>
import { computed, defineComponent, reactive, watchEffect } from 'vue'
import WorkingHoursElement from '@/components/smartTemplateConstructor/segmentElements/WorkingHoursElement'
import TextElement from '@/components/smartTemplateConstructor/segmentElements/TextElement'
import { cloneDeep } from '@apollo/client/utilities'

export default defineComponent({
  name: 'WorkingHoursEditor',
  components: {
    TextElement,
    WorkingHoursElement
  },
  props: {
    data: Object,
    defaultTemplateData: Object
  },
  emits: ['validChange'],
  setup (props, { emit }) {
    const fieldsState = reactive(props.data)
    const isValid = computed(() => !!(fieldsState.header
        && fieldsState.workingHours && fieldsState.workingHours?.every((wh) => !!(wh.day && wh.time))
    ))

    const canAddLocation = computed(() => fieldsState.workingHours?.length < 7)

    const deleteRow = (index) => {
      fieldsState.workingHours = [...fieldsState.workingHours.slice(0, index), ...fieldsState.workingHours.slice(index + 1)]
    }

    const addRow = () => {
      const row = cloneDeep(props.defaultTemplateData?.workingHours?.[0])
      fieldsState.workingHours = [...fieldsState.workingHours, row]
    }

    watchEffect(() => {
      emit('validChange', isValid.value)
    })

    return {
      fieldsState,
      canAddLocation,
      addRow,
      deleteRow
    }
  }
})
</script>

<style lang="less" scoped>
.working-hours-editor {

}
</style>
