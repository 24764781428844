<template>
  <div>
    <a-modal
      :open="showUpdateSubscription"
      :title="!paymentSuccess && $t('components.updateSubscriptionModal.title')"
      width="1200px"
      :closable="closable"
      :mask-closable="closable"
      :footer="null"
      wrap-class-name="full-modal subscription-modal"
      @ok="goToSubscription"
      @cancel="goToSubscription"
    >
      <template v-if="!paymentSuccess">
        <template v-if="!isPaid">
          <div style="width: 400px;">
            <a-steps
              :current="currentStep"
            >
              <a-step
                :title="$t('components.updateSubscriptionModal.subscriptionPlanTitle')"
                @click="goToStepOne"
              />
              <a-step :title="$t('components.updateSubscriptionModal.paymentMethodTitle')" />
            </a-steps>
          </div>

          <a-divider />
        </template>
        <a-row
          :gutter="[24, 24]"
        >
          <a-col :span="14">
            <template v-if="currentStep===0">
              <SubscriptionPlan
                :disable-fields="disableSubscriptionFields"
                :is-paid="isPaid"
                :devices="devices"
                :all-devices="allDevicesNumber"
                :current-devices="billingDevicesQuantity"
                :show-devices-number-more-than-max-error="showDevicesNumberMoreThanMaxError"
                :is-monthly="isMonthly"
                :is-yearly="isYearly"
                :prices-info="pricesInfo"
                :enterprise-request-form-submitted="enterpriseRequestFormSubmitted"
                @change-billing-interval="changeBillingInterval"
                @on-devices-number-change="handleDevicesNumberChange"
              />
            </template>
            <template v-else>
              <SubscriptionPaymentMethod
                :disable-fields="paymentInProcess"
                :elements-options="elementsOptions"
                :stripe-loaded="stripeLoaded"
                :stripe-key="stripeKey"
                @on-elements-set="e => elms = e"
              />
            </template>
          </a-col>
          <a-col
            :span="10"
            :class="{'disabled': disablePayment}"
          >
            <SubscriptionOrderSummary
              v-model:promo-code="promoCode"
              :billing-interval="billingInterval"
              :prices-info="pricesInfo"
              :devices="devices"
              :disable-payment="disablePayment"
              :amount-due="amountDue"
              :payment-in-process="paymentInProcess"
              :discount-is-active="discountIsActive"
              :amount-discount="amountDiscount"
              :percent-discount="percentDiscount"
              :subscription-update-info-loading="subscriptionUpdateInfoLoading"
              :next-payment-anchor="nextPaymentAnchor"
              :handle-submit="handleSubmit"
              :apply-promo-code="applyPromoCode"
              :is-yearly="isYearly"
              :is-monthly="isMonthly"
              :current-step="currentStep"
              @apply-promo-code="applyPromoCode"
              @handle-submit="handleSubmit"
            />
          </a-col>
        </a-row>
      </template>
      <template v-else>
        <div style="display: flex; align-items: center; justify-content: center">
          <a-result
            status="success"
            :title="$t('components.updateSubscriptionModal.successTitle')"
            :sub-title="$t('components.updateSubscriptionModal.successSubtitle')"
            style="margin-top: 32px; max-width: 560px;"
          >
            <template #icon>
              <CheckCircleOutlined />
            </template>
            <template #extra>
              <a-button
                type="primary"
                @click="goToDashboard"
              >
                {{ $t('components.updateSubscriptionModal.goToDashboardButtonText') }}
              </a-button>
            </template>
          </a-result>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>

import { computed, defineComponent, nextTick, onBeforeMount, onMounted, ref, watch, inject, watchEffect } from 'vue'
import { loadStripe } from '@stripe/stripe-js'
import { debounce } from 'lodash-es'
import { CheckCircleOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import moment from 'moment'
import { error, success } from '@/utils'
import SubscriptionPlan from '@/components/SubscriptionPlan.vue'
import SubscriptionPaymentMethod from '@/components/SubscriptionPaymentMethod.vue'
import SubscriptionOrderSummary from '@/components/SubscriptionOrderSummary.vue'

const PLAN_NAME = 'Kitcast Growth'
const MAX_DEVICES_FOR_GROWTH_PLAN = 10
const MIN_DEVICES_FOR_GROWTH_PLAN = 1
const YEARLY = 'year'
const MONTHLY = 'month'
const PRICE_INTERVAL_MAP = {
  year: 'yearly',
  month: 'monthly'
}
const PER_INTERVAL_MAP = {
  year: 'Yearly',
  month: 'Monthly'
}

const PER_INTERVAL_PRICE_INFO_MAP = {
  year: 'perYear',
  month: 'perMonth'
}

const appearance = {
  theme: 'stripe',
}
let stripe

export default defineComponent({
  name: 'UpdateSubscriptionModal',
  components: {
    SubscriptionOrderSummary,
    SubscriptionPaymentMethod,
    SubscriptionPlan,
    CheckCircleOutlined
  },
  props: {
    success: {
      type: Object,
      default: null
    }
  },
  setup (props) {
    const store = useStore()
    const card = ref()
    const elms = ref()
    const gtm = inject('gtm')
    const successInfo = {...{
        devicesBought: 0,
        couponName: null,
        amount: 0,
        invoiceId: null,
        billingInterval: YEARLY,
        price: 0
      }, ...props.success}
    const stripeKey = process.env.VUE_APP_STRIPE_KEY
    const paymentSuccess = ref(false)
    const billingInterval = ref(YEARLY)
    const currentStep = ref(0)
    const promoCode = ref('')
    const stripeLoaded = ref(false)
    const paymentInProcess = ref(false)
    const subscriptionUpdateInfo = ref(null)
    const disableSubscriptionFields = ref(false)
    const subscriptionUpdateInfoLoading = ref(false)
    const showDevicesNumberMoreThanMaxError = ref(false)
    const isYearly = computed(()=>billingInterval.value === YEARLY)
    const isMonthly = computed(()=>billingInterval.value === MONTHLY)
    const disablePayment = computed(()=> showDevicesNumberMoreThanMaxError.value || noChanges.value)
    const prices = computed(()=> store.getters['subscription/stripeGrowthPrices'])
    const hasCustomPrice = computed(() => store.getters['workspace/hasCustomPrice'])
    const stripeCustomPrice = computed(() => store.getters['subscription/stripeCustomPrice'])
    const isPaid = computed(() => store.getters['workspace/isPaid'])
    const updateIntentClientSecret = computed(() => store.getters['subscription/stripeUpdateIntentClientSecret'])
    const billingDevicesQuantity = computed(() => store.getters['workspace/billingDevicesQuantity'])
    const currentBillingInterval = computed(() => isPaid.value ? store.getters['workspace/billingInterval'] : YEARLY)
    const workspaceCustomer = computed(() => store.getters['subscription/workspaceCustomer'])
    const stripeGrowthDevicesQuantityLimit = computed(() => store.getters['workspace/stripeGrowthDevicesQuantityLimit'])
    const enterpriseRequestFormSubmittedAt = computed(() => store.getters['workspace/enterpriseRequestFormSubmittedAt'])
    const allDevicesNumber = computed(()=>store.getters['devices/allDevicesNumber'])
    const discount = computed(()=>workspaceCustomer.value?.discount)
    const workspaceSubscription = computed(()=>store.getters['subscription/workspaceSubscription'])
    const latestInvoice = computed(()=>workspaceSubscription.value?.latestInvoice)
    const coupon = computed(()=>discount.value?.coupon)
    const discountIsActive = computed(()=>{
      const now = moment()
      const start = discount.value?.start ? moment(discount.value?.start * 1000) : null
      const end = discount.value?.end ? moment(discount.value?.end * 1000) : null
      return (start && now.diff(start) > 0) && (!end || now.diff(end) < 0)
    })
    const amountDiscount = computed(()=>coupon.value?.amount_off)
    const percentDiscount = computed(()=>coupon.value?.percent_off)
    const devices = ref(billingDevicesQuantity.value || allDevicesNumber.value || MIN_DEVICES_FOR_GROWTH_PLAN)
    const elementsOptions = ref({
      clientSecret: updateIntentClientSecret.value ||  null,
      appearance
    })

    const customPriceInterval = computed(()=>{
      if (!stripeCustomPrice.value?.recurring?.interval) return null
      return {
        inner: PRICE_INTERVAL_MAP[stripeCustomPrice.value.recurring.interval],
        value: stripeCustomPrice.value.recurring.interval
      }
    })

    const pricePerItemPerInterval = computed(()=>{
      const interval = PRICE_INTERVAL_MAP[billingInterval.value]
      const intervalString = PER_INTERVAL_PRICE_INFO_MAP[billingInterval.value]
      return pricesInfo.value?.[interval]?.[intervalString] || 0
    })

    const maxDevicesForGrowthPlan = computed(() => {
      return stripeGrowthDevicesQuantityLimit.value || MAX_DEVICES_FOR_GROWTH_PLAN
    })

    const enterpriseRequestFormSubmitted = computed(() => !!enterpriseRequestFormSubmittedAt.value)

    const amountDue = computed(() => subscriptionUpdateInfo.value?.amount_due || 0)

    const nextPaymentAnchor = computed(()=>{
      if (subscriptionUpdateInfo.value?.billing_cycle_anchor) {
        return moment(subscriptionUpdateInfo.value?.billing_cycle_anchor * 1000).format('MMM Do')
      }
      return null
    })

    const isDowngrade = computed(()=>{
      return billingDevicesQuantity.value > devices.value
    })

    const noChanges = computed(()=> isPaid.value && billingDevicesQuantity.value === devices.value && currentBillingInterval.value === billingInterval.value)

    const closable = computed(()=>{
      return !paymentInProcess.value && !paymentInProcess.value && !paymentSuccess.value
    })

    const pricesInfo = computed(()=>{
      if (!prices.value && !stripeCustomPrice.value?.recurring?.interval) return null
      if (prices.value) {
        const monthlyPerMonth = prices.value?.monthly?.unit_amount_decimal
        const monthlyPerYear = monthlyPerMonth * 12
        const yearlyPerYear = prices.value?.yearly?.unit_amount_decimal
        const yearlyPerMonth = yearlyPerYear / 12
        const yearlyDiscount = monthlyPerYear - yearlyPerYear
        const yearlyDiscountPercent = (yearlyDiscount / monthlyPerYear * 100).toFixed(2)
        return {
          monthly: {
            perMonth: monthlyPerMonth,
            perYear: monthlyPerYear
          },
          yearly: {
            perMonth: yearlyPerMonth,
            perYear: yearlyPerYear
          },
          yearlyDiscount,
          yearlyDiscountPercent
        }
      }
      else {
        const price = stripeCustomPrice.value.unit_amount_decimal
        const perYear = customPriceInterval.value.value === YEARLY ? price : price * 12
        const perMonth = customPriceInterval.value.value === YEARLY ? price / 12 : price
        return {
          [customPriceInterval.value.inner]: {
            perYear,
            perMonth
          }
        }
      }
    })


    const showUpdateSubscription = computed(() => store.getters.showUpdateSubscription)
    const currentIntervalString = computed(()=> PER_INTERVAL_MAP?.[currentBillingInterval.value])
    const minDevicesLimit = computed(()=> isPaid.value ? billingDevicesQuantity.value : MIN_DEVICES_FOR_GROWTH_PLAN)

    onBeforeMount(() => {
      store.dispatch('subscription/setWorkspaceCustomer')
      const stripePromise = loadStripe(stripeKey)
      stripePromise.then((Stripe) => {
        stripe = Stripe
        stripeLoaded.value = true
      })
    })

    onMounted(() => {
      if (props.success) {
        paymentSuccess.value = true
      }
    })

    const changeBillingInterval = (period) => {
      billingInterval.value = period
      previewWorkspaceSubscriptionUpdate()
    }


    const closeModal = () => {
      store.dispatch('closeUpdateSubscription')
    }

    const goToSubscription = () => {
      store.dispatch('closeUpdateSubscription')
      store.dispatch('openGlobalSettingsSubscription')
    }

    const goToDashboard = () => {
      closeModal()
      setTimeout(()=>{
        store.dispatch('workspace/updateBillingInfo')
      }, 1000)
    }

    const handleDevicesNumberChange = (value = 0) => {
      showDevicesNumberMoreThanMaxError.value = false
      if (value < minDevicesLimit.value) {
        devices.value = minDevicesLimit.value
      }
      else if (value < allDevicesNumber.value) {
        devices.value = allDevicesNumber.value
      }
      else {
        devices.value = value
        if (value > maxDevicesForGrowthPlan.value) {
          showDevicesNumberMoreThanMaxError.value = true
        }
      }
    }

    const previewWorkspaceSubscriptionUpdate = async () => {
      if (devices.value > maxDevicesForGrowthPlan.value) return
      subscriptionUpdateInfoLoading.value = true
      const input = {
        billingItemsInfo: {
          billingInterval: billingInterval.value,
          billingDevicesQuantity: devices.value
        }
      }
      store.dispatch('subscription/previewWorkspaceSubscriptionUpdate', { input }).then(({ data: {previewWorkspaceSubscriptionUpdate} })=>{
        subscriptionUpdateInfo.value = previewWorkspaceSubscriptionUpdate
      }).catch((e)=>{
        console.log(e.message)
      }).then(()=>{
        subscriptionUpdateInfoLoading.value = false
      })
    }

    const debounceGetUpdateInfo = debounce(previewWorkspaceSubscriptionUpdate, 300)

    const confirmPayment = () => {
      paymentInProcess.value = true
      const devicesBought = devices.value - (isPaid.value ? billingDevicesQuantity.value : 0)
      const couponName = coupon.value?.name
      const invoiceId = latestInvoice.value?.id
      const interval = PRICE_INTERVAL_MAP[billingInterval.value]
      const intervalString = PER_INTERVAL_PRICE_INFO_MAP[billingInterval.value]
      const price = pricesInfo.value?.[interval]?.[intervalString] || 0
      const elements = elms.value
      elements.instance.confirmPayment({
        elements: elms.value.elements,
        confirmParams: {
          return_url: window.location.origin + `/?payment=true&devices-bought=${devicesBought}&amount=${amountDue.value}&coupon=${couponName}&invoice-id=${invoiceId}&price=${price}`,
        },
      }).then(()=>{
        paymentInProcess.value = false
      })
    }
    // returnUrl example
    // http://localhost:3000/?payment=true&devices-bought=3&amount=100&invoice-id=12312312&coupon=coupon&payment_intent=pi_3PP01MKO1KeC1qMz1xvNiObS&payment_intent_client_secret=pi_3PP01MKO1KeC1qMz1xvNiObS_secret_XRffw15DxNVHPKiVeAvbI65KE&redirect_status=succeeded

    const updateSubscription = () => {
      return store.dispatch('subscription/updateWorkspaceSubscription', {
        input: {
          billingItemsInfo: {
            billingInterval: billingInterval.value,
            billingDevicesQuantity: devices.value
          }
        }
      })
    }

    const applyPromoCode = () => {
      if (!promoCode.value) return
      paymentInProcess.value = true
      store.dispatch('subscription/updateWorkspaceCustomer', { input: { promotionCode: promoCode.value}}).then(()=>{
        previewWorkspaceSubscriptionUpdate()
        success()
        promoCode.value = '';
      }).catch(e=>{
        error(e.message)
      }).then(()=>{
        paymentInProcess.value = false
      })
    }

    const handleSuccess = () => {
      paymentSuccess.value = true
      paymentInProcess.value = false
      disableSubscriptionFields.value = false
      sendSuccessEvent(successInfo)
    }

    const getPaymentIntent = () => {
      paymentInProcess.value = true
      disableSubscriptionFields.value = true
      successInfo.devicesBought = devices.value - (isPaid.value ? billingDevicesQuantity.value : 0)
      successInfo.couponName = coupon.value?.name
      successInfo.amount = amountDue.value
      successInfo.invoiceId = latestInvoice.value?.id
      successInfo.billingInterval = billingInterval.value
      successInfo.price = pricePerItemPerInterval.value
      updateSubscription().then(data => {
        const hasIntent = data?.latestInvoice?.paymentIntent
        const succeeded = data?.latestInvoice?.paymentIntent?.status === 'succeeded'
        const requiresAction = data?.latestInvoice?.paymentIntent?.status === 'requires_action'
        const clientSecret = data?.latestInvoice?.paymentIntent?.client_secret
        if (hasIntent && !succeeded) {
          if (requiresAction) {
            stripe.confirmCardPayment(clientSecret).then(()=>{
              handleSuccess()
            })
            return
          }
          elementsOptions.value.clientSecret = clientSecret
          nextTick(() => {
            // step change
            gtm.push({
              event: "add_payment_info",
              ecommerce: {
                currency: "USD",
                value: + (amountDue.value || 0),
                payment_type: 'payment_type',
                items: [
                  {
                    item_name: "product_type", // this should be user understandable name of plan
                    item_brand: billingInterval.value,
                    price: +pricePerItemPerInterval.value,
                    quantity: +devices.value
                  }
                ]
              }
            })
            currentStep.value = 1
            paymentInProcess.value = false
            disableSubscriptionFields.value = false
          })
          return
        }
        handleSuccess()
      }).catch(e=> {
        paymentInProcess.value = false
        disableSubscriptionFields.value = false
        error(e.message)
        // error
      }).then(() => {

      })
    }

    const handleSubmit = () => {
      if (currentStep.value === 0) {
        getPaymentIntent()
      }
      else {
        confirmPayment()
      }
    }

    const goToStepOne = () => {
      if (!paymentInProcess.value && !disableSubscriptionFields.value) {
        currentStep.value = 0
      }
    }

    const sendSuccessEvent = ({devicesBought = 0, couponName, amount = 0, invoiceId, billingInterval, price = 0}) => {
      gtm.push({ ecommerce: null })
      gtm.push({
        event: "purchase",
        ecommerce: {
          transaction_id: invoiceId,
          value: +amount,
          currency: "USD",
          coupon: couponName || null,
          items: [{
            item_name: PLAN_NAME,
            item_brand: billingInterval,
            quantity: +devicesBought,
            price: +price
          }]
        }
      })
    }

    watch(() => allDevicesNumber.value, (value) => {
      if (!isPaid.value) {
        devices.value = value || MIN_DEVICES_FOR_GROWTH_PLAN
        previewWorkspaceSubscriptionUpdate()
      }
    })

    watch(() => devices.value, (value)=>{
      value && debounceGetUpdateInfo()
    })

    watch(() => showUpdateSubscription.value, async (show)=>{
      if (show) {
        devices.value = isPaid.value ? billingDevicesQuantity.value : allDevicesNumber.value || MIN_DEVICES_FOR_GROWTH_PLAN
        if (hasCustomPrice.value) {
          await store.dispatch('subscription/getStripeCustomPrice')
        }
        else {
          await store.dispatch('subscription/getStripeGrowthPrices')
        }
        if (customPriceInterval.value) {
          changeBillingInterval(customPriceInterval.value.value)
        }
        else {
          changeBillingInterval(currentBillingInterval.value)
        }
      }
      else {
        setTimeout(()=>{
          currentStep.value = 0
          paymentSuccess.value = false
        }, 400)
      }
      if (!paymentSuccess.value) {
        gtm.push({
          event: "begin_checkout",
          ecommerce: {
            currency: "USD",
            value: +pricePerItemPerInterval.value,
            items: [
              {
                item_name: PLAN_NAME,
                item_brand: billingInterval.value,
                price: +pricePerItemPerInterval.value,
                quantity: +devices.value
              }
            ]
          }
        })
      }
    })

    watch(()=> updateIntentClientSecret.value, ()=>{
      elementsOptions.value.clientSecret = updateIntentClientSecret.value
    })

    watch(()=> billingDevicesQuantity.value, (value)=>{
      devices.value = value
    })

    watch(()=> billingDevicesQuantity.value, (value)=>{
      devices.value = value
    })

    watchEffect(() => {
      if (paymentSuccess.value && workspaceSubscription.value && props.success) {
        sendSuccessEvent(successInfo)
      }
    })

    return {
      card,
      elms,
      isPaid,
      isDowngrade,
      noChanges,
      currentStep,
      stripeKey,
      closable,
      stripeLoaded,
      promoCode,
      elementsOptions,
      isYearly,
      isMonthly,
      devices,
      pricesInfo,
      paymentSuccess,
      amountDue,
      allDevicesNumber,
      billingInterval,
      currentBillingInterval,
      disablePayment,
      paymentInProcess,
      showUpdateSubscription,
      disableSubscriptionFields,
      billingDevicesQuantity,
      currentIntervalString,
      nextPaymentAnchor,
      showDevicesNumberMoreThanMaxError,
      subscriptionUpdateInfoLoading,
      subscriptionUpdateInfo,
      enterpriseRequestFormSubmittedAt,
      enterpriseRequestFormSubmitted,
      workspaceCustomer,
      discountIsActive,
      percentDiscount,
      amountDiscount,
      applyPromoCode,
      goToStepOne,
      handleSubmit,
      handleDevicesNumberChange,
      goToSubscription,
      closeModal,
      goToDashboard,
      changeBillingInterval
    }
  }
})
</script>

<style lang="less">
@import "../styles/variables.less";

 .subscription-modal {
   .ant-modal-body {
     overflow: hidden !important;
   }
   .subscription-element {
     &.disabled {
       opacity: .6;
       pointer-events: none;
       user-select: none;
     }
     .small-x-borders {
       .ant-card-body {
         padding-top: 8px;
         padding-bottom: 8px;
         .ant-list-item {
           padding-left: 0;
           padding-right: 0;
         }
       }
     }
   }
   .promo {
     margin-bottom: 24px;
     margin-top: 12px;
     height: 150px;
   }
 }
</style>
