<template>
  <div>
    <a-modal
      :open="showUpdatePaymentMethod"
      :title="$t('components.updatePaymentMethodModal.title')"
      width="768px"
      :closable="closable"
      :mask-closable="closable"
      :footer="null"
      wrap-class-name="full-modal subscription-modal upgrade-plan-modal"
      @ok="closeModal"
      @cancel="closeModal"
    >
      <a-space
        v-if="!updateSuccess"
        direction="vertical"
        style="width: 100%"
        size="large"
      >
        <div :class="{loaded: stripeLoaded && elementsOptions.clientSecret && clientSecretLoaded}">
          <StripeElements
            v-if="stripeLoaded && elementsOptions.clientSecret && clientSecretLoaded"
            ref="elms"
            v-slot="{ elements }"
            :stripe-key="stripeKey"
            :elements-options="elementsOptions"
          >
            <StripeElement
              type="payment"
              :elements="elements"
              :options="{}"
              @change="handleElementChange"
            />
          </StripeElements>
        </div>
        <div style="display: flex; justify-content: flex-end">
          <a-button
            type="primary"
            :disabled="!stripeLoaded || !clientSecretLoaded || !isFormValid"
            :loading="updateInProcess"
            @click="updatePaymentMethod"
          >
            {{ $t('components.updatePaymentMethodModal.updateButtonText') }}
          </a-button>
        </div>
      </a-space>
      <template v-else>
        <a-result
          :title="$t('components.updatePaymentMethodModal.successTitle')"
          style="margin-top: 40px;"
        >
          <template #icon>
            <RocketOutlined />
          </template>
          <template #extra>
            <a-button
              type="primary"
              @click="goToDashboard"
            >
              {{ $t('components.updatePaymentMethodModal.goToDashboardButtonText') }}
            </a-button>
          </template>
        </a-result>
      </template>
    </a-modal>
  </div>
</template>

<script>

import { computed, defineComponent, onBeforeMount, ref, watch } from 'vue'
import { StripeElement, StripeElements } from 'vue-stripe-js'
import { RocketOutlined } from '@ant-design/icons-vue'
import { loadStripe } from '@stripe/stripe-js'
import { useStore } from 'vuex'
import { error } from '@/utils'

let stripe

const appearance = {
  theme: 'stripe',
};

export default defineComponent({
  name: 'UpdatePaymentMethodModal',
  components: {
    StripeElements,
    StripeElement,
    RocketOutlined,
  },
  setup () {
    const store = useStore()
    const stripeKey = process.env.VUE_APP_STRIPE_KEY
    const updateInProcess = ref(false)
    const updateSuccess = ref(false)
    const clientSecretLoaded = ref(false)
    const elms = ref()
    const isFormValid = ref(false)

    const handleElementChange = (event) => {
      isFormValid.value = event.complete;
    }

    const elementsOptions = ref({
      clientSecret: null,
      appearance
    })

    const closable = computed(()=>{
      return !updateInProcess.value
    })

    const stripeLoaded = ref(false)
    const showUpdatePaymentMethod = computed(() => store.getters.showUpdatePaymentMethod)

    const closeModal = () => {
      store.dispatch('closeUpdatePaymentMethod')
    }

    const goToDashboard = () => {
      closeModal()
    }

    onBeforeMount(() => {
      const stripePromise = loadStripe(stripeKey)
      stripePromise.then((Stripe) => {
        stripe = Stripe
        stripeLoaded.value = true
      })
    })

    const updatePaymentMethod = () => {
      updateInProcess.value = true
      const elements = elms.value
      elements.instance.confirmSetup({
        elements: elms.value.elements,
        redirect: 'if_required',
        confirmParams: {
          return_url: window.location.origin + '/?payment-method-update-success=true',
        },
      }).then((result)=>{
        if (!result.error) {
          store.dispatch('subscription/getPaymentMethod')
          updateSuccess.value = true
        }
      }).then(()=>{
        updateInProcess.value = false
      })
    }

    const getSetupIntent = () => {
      store.dispatch('subscription/getStripeSetupIntentClientSecret').then(client_secret => {
        elementsOptions.value.clientSecret = client_secret
        clientSecretLoaded.value = true
      }).catch( e => {
        error(e.message)
      })
    }

    const resetModal = () => {
      clientSecretLoaded.value = false
      updateSuccess.value = false
    }

    watch(() => showUpdatePaymentMethod.value, (show)=>{
      show && getSetupIntent()
      if (!show) {
        setTimeout(()=>{
          resetModal()
        })
      }
    })


    return {
      elms,
      isFormValid,
      stripeKey,
      closable,
      stripeLoaded,
      elementsOptions,
      updateInProcess,
      showUpdatePaymentMethod,
      clientSecretLoaded,
      updateSuccess,
      closeModal,
      goToDashboard,
      updatePaymentMethod,
      handleElementChange
    }
  }
})
</script>

<style lang="less">
@import "../styles/variables.less";
.upgrade-plan-modal {
  .ant-modal-body {
   overflow-x: auto;
   min-height: 200px;
    transition: all .3s ease;
    > .ant-space {
      .ant-space-item:first-child {
        > div {
          transition: all .05s ease;
          min-height: 200px;
          max-height: 200px;
          &.loaded {
            max-height: 500px;
          }
        }
      }
    }
  }
}
</style>
