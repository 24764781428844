<script setup>
import InfoHeader from '@/InfoHeader.vue'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
const store = useStore()
const daysToCardExpiration = computed(() => store.getters['subscription/daysToCardExpiration'])

const openUpdatePaymentMethod= () => {
  store.dispatch('openUpdatePaymentMethod')
}
const expired = computed(() => daysToCardExpiration.value <= 0)
</script>

<template>
  <InfoHeader :type="expired ? 'danger' : 'warning'">
    <template v-if="expired">
      <i18n-t
        keypath="components.cardExpirationHeader.cardExpiredText"
        tag="label"
        for="components.cardExpirationHeader.cardExpiredLink"
      >
        <a
          class="upgrade-link"
          @click="openUpdatePaymentMethod"
        >{{ $t('components.cardExpirationHeader.cardExpiredLink') }}</a>
      </i18n-t>
    </template>
    <template v-else>
      <a-space>
        <span>{{ $t('components.cardExpirationHeader.cardExpiresIn', {days: daysToCardExpiration}, daysToCardExpiration ) }}</span>
        <i18n-t
          keypath="components.cardExpirationHeader.cardExpiresInUpgradeText"
          tag="label"
          for="components.cardExpirationHeader.cardExpiresInUpgradeLink"
        >
          <a
            class="upgrade-link"
            @click="openUpdatePaymentMethod"
          >{{ $t('components.cardExpirationHeader.cardExpiresInUpgradeLink') }}</a>
        </i18n-t>
      </a-space>
    </template>
  </InfoHeader>
</template>

<style scoped lang="less">
.upgrade-link {
  color: white;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
</style>
