<script setup>
import CommandList from "./CommandList.vue"
import {Check, TextQuote, TrashIcon} from "lucide-vue-next"
import {computed} from "vue"

const emit = defineEmits(['on-discard'])

const props = defineProps({
  editor: {
    type: Object,
    required: true,
  },
  completion: {
    type: String,
    required: true
  },
  filter: {
    type: String,
    default: ''
  }
})

const items = [
  {
    name: 'replace',
    label: 'Replace selection',
    icon: Check,
    onSelect() {
      const selection = props.editor.view.state.selection
      props.editor
        .chain()
        .focus()
        .insertContentAt(
            {
              from: selection.from,
              to: selection.to,
            },
            props.completion,
        )
        .run()
    }
  },
  {
    name: 'insert',
    label: 'Insert below',
    icon: TextQuote,
    onSelect() {
      const selection = props.editor.view.state.selection
      props.editor
          .chain()
          .focus()
          .insertContentAt(selection.to + 1, props.completion)
          .run()
    }
  },
  {
    name: 'discard',
    label: 'Discard',
    icon: TrashIcon,
    onSelect() {
      emit('on-discard')
    }
  }
]

const handleItemSelect = (item) => {
  item.onSelect()
}

const filteredItems = computed(()=>{
  return items.filter(item => item.label.toLowerCase().includes(props.filter.toLowerCase()))
})


</script>

<template>
  <CommandList
    v-if="filteredItems.length > 0"
    :items="filteredItems"
    @select="handleItemSelect"
  >
    <template #item="{item, index, isActive}">
      <button
        :key="index"
        class="flex items-center w-full px-2 py-1 space-x-2 text-sm text-left rounded-md text-stone-900 hover:bg-stone-100"
        :class="isActive ? 'bg-stone-100 text-stone-900' : ''"
        @click="item.onSelect()"
      >
        <div class="flex items-center justify-center w-10 h-10 bg-white border rounded-md border-stone-200">
          <component
            :is="item.icon"
            size="18"
          />
        </div>
        <div>
          <p class="font-medium">
            {{ item.label }}
          </p>
        </div>
      </button>
    </template>
  </CommandList>
</template>

<style scoped>

</style>
