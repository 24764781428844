<template>
  <AttachDeviceModal
    v-model:visible="showAttachDeviceModal"
  />
  <a-layout-content style="background: #fff; height: 100%; overflow-x: auto;">
    <a-page-header :title="$t('components.standardGroupDevicesPage.title')">
      <template #extra>
        <div style="display: flex; gap: 8px;">
          <a-input-search
            v-model:value="searchTerm"
            :placeholder="$t('components.devicesTable.filterPlaceholder')"
            style="width: 200px"
          />
          <a-button
            ref="addDeviceButtonRef"
            type="primary"
            @click="handleAddDevice"
          >
            <template #icon>
              <PlusCircleOutlined />
            </template>
            {{ $t('components.standardGroupDevicesSider.addScreen') }}
          </a-button>
        </div>
      </template>
    </a-page-header>
    <DevicesTable
      v-model:searchTerm="searchTerm"
      hide-search
      :devices="devicesList"
      :padded="true"
    />
  </a-layout-content>
</template>

<script>
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import DevicesTable from '@/components/DevicesTable'
import AttachDeviceModal from '@/components/AttachDeviceModal.vue'
import { PlusCircleOutlined } from '@ant-design/icons-vue'
import { useI18n } from 'vue-i18n'
import { error } from '@/utils'

export default defineComponent({
  name: 'StandardGroupDevicesPage',
  components: {
    PlusCircleOutlined,
    AttachDeviceModal,
    DevicesTable,
  },
  setup () {
    const store = useStore()
    const devicesList = computed(() => store.getters['devices/currentGroupDevices'])
    const currentGroupId = computed(() => store.getters['groups/currentGroupId'])
    const searchTerm = ref('')
    const addDeviceButtonRef = ref(null)
    const { t } = useI18n()
    const currentDevicesNumber = computed(() => store.getters['devices/allDevicesNumber'])
    const totalDevices = computed(() => store.getters['workspace/billingDevicesQuantity'] + store.getters['workspace/freeDevicesQuantity'])
    const emailVerified = computed(() => store.getters['auth/emailVerified'])
    const isEnterprize = computed(() => store.getters['workspace/isEnterprize'])
    const onboardingService = inject('onboardingService')
    const isPaused = computed(() => store.getters['workspace/isPaused'])
    const isManuallyFrozen = computed(() => store.getters['workspace/isManuallyFrozen'])

    const showAttachDeviceModal = ref(false)
    onMounted(()=>{
      store.dispatch('devices/getCurrentGroupDevices')
      store.dispatch('devices/subscribeToDeviceUpdate')
    })

    const startDeviceAttach = () => {
      if (currentDevicesNumber.value >= totalDevices.value) {
        if (!emailVerified.value) {
          return error(t('components.standardGroupDevicesSider.verifyEmail'))
        }
        else if (isPaused.value || isManuallyFrozen.value) {
          return error(t('components.standardGroupDevicesSider.accountFrozen'))
        }
        else if (!isEnterprize.value) {
          return store.dispatch('handleUpdateSubscription')
        }
        else {
          return error(t('components.standardGroupDevicesSider.contactSales'))
        }
      }
      showAttachDeviceModal.value = true
    }

    const handleAddDevice = () => {
      if (onboardingService.isCurrent(1)) {
        onboardingService.next()
      }
      startDeviceAttach()
    }

    watch(()=>currentGroupId.value , (newValue)=>{
      newValue && store.dispatch('devices/getCurrentGroupDevices')
    })

    watch(() => addDeviceButtonRef.value, (value) => {
      value && onboardingService.setRef(value?.$el, 'addDevice', ()=> {
        startDeviceAttach()
      })
    })


    return {
      devicesList,
      searchTerm,
      showAttachDeviceModal,
      addDeviceButtonRef,
      handleAddDevice,
      startDeviceAttach
    }
  }

})
</script>
<style lang="less">
.editable-cell {
  display: flex;
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-overflow {
    overflow: hidden;
    display: inherit;
    text-overflow: ellipsis;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 6px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  .editable-cell-icon {
    justify-content: center;
    align-items: center;
    margin: 0;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: flex;
}
</style>
