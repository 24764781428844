<template>
  <LoadingView
    v-if="loading"
    :show="!!loading"
  />
  <PixieComponent
    ref="pixieRef"
    @close="handleClose"
    @update="handleUpdate"
  />
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from 'vue'
import { CustomSlide } from '@/helpers/Slides'
import PixieComponent from '@/components/PixieComponent.vue'
import { useStore } from 'vuex'
import LoadingView from '@/components/LoadingView.vue'
import { error, getComposerAssets, loadJson } from '@/utils'
import { ASPECT_RATIO_MAP } from '@/constants'
const getAssets = getComposerAssets()

class ImageForPixie {
  id
  preview
  source
  constructor (media) {
    this.id = media.id
    this.preview = media.generatedMedia?.find(m => m.tag === 'preview')?.url
    this.source = media.url
  }

}

export default defineComponent({
  name: 'CustomTemplateModal',
  components: {
    LoadingView,
    PixieComponent
  },
  props: {
    visible: Boolean,
    slideObj: CustomSlide,
    zoningLayoutType: String,
    zoneKey: String
  },
  emits: ['update', 'update:visible', 'close'],
  setup (props, { emit }) {
    const pixieRef = ref()
    const store = useStore()
    const slide = ref(props.slideObj || new CustomSlide())
    const widgetJson = ref(slide.value?.slideData?.json)
    const aspectRatio = ref(slide.value?.slideData?.aspectRatio)
    const widgetJsonLoaded = ref(!slide.value?.widgetId)
    const mediaList = computed(()=> store.getters['media/mediaList'] || [])
    const images = computed(() => mediaList.value?.filter(m=>m.mimetype?.startsWith('IMAGE'))?.map(m => new ImageForPixie(m)) || [])
    const mediaListLoaded = computed(()=> store.getters['media/mediaListLoaded'])
    const loading = computed(()=> props.visible && (!mediaListLoaded.value || !widgetJsonLoaded.value))
    const templatesRaw = computed(()=> store.getters['slides/defaultCustomTemplates'])
    const templatesLoaded = computed(()=> store.getters['slides/defaultCustomTemplatesLoaded'])
    const templates = computed(() => templatesRaw.value.map(t => {
      return {
        name: t.name,
        tags: t.tags?.map(t => t.toLowerCase()),
        url: t?.widget?.jsonMedia?.url,
        id: t.widgetId,
        preview: t?.widget?.imageMedia?.generatedMedia?.find(({ tag }) => tag === 'preview')?.url
      }
    }))

    watch([() => props.visible, () => mediaListLoaded.value, () => widgetJsonLoaded.value, () => slide.value, () => templatesLoaded.value], async ([modalVisible, mediaLoaded, jsonLoaded, slideExists, templatesLoaded]) => {
      if (modalVisible && mediaLoaded && jsonLoaded && slideExists && templatesLoaded) {
        const ar = aspectRatio.value || ASPECT_RATIO_MAP[props.zoningLayoutType]?.[props.zoneKey]
        if (!aspectRatio.value) {
          slide.value?.updateAspectRatio(ar)
          aspectRatio.value = slide.value?.slideData?.aspectRatio
        }
        pixieRef.value.openModal({assets: getAssets(), images: images.value, json: widgetJson.value, aspectRatio: ar, templates: [...templates.value], getTemplateJson: getTemplateJsonFromUrl})
      }
    })

    watch(() => props.visible, (visible) => {
      if (visible && !mediaListLoaded.value) {
        store.dispatch('media/getMediaList')
      }
      if (!visible) {
        slide.value = null
        aspectRatio.value = null
      }
      else if (visible) {
        slide.value = props.slideObj || new CustomSlide()
      }
    })

    const handleUpdate = ({ img, json }) => {
      updateSlide({ img, json })
      pixieRef.value.closeModal()
      emit('update:visible', false)
      emit('close')
    }

    const handleClose = () => {
      emit('update:visible', false)
      emit('close')
    }

    const getTemplateJson = async (widgetId) => {
      return await store.dispatch('slides/fetchComposerJson', widgetId)
    }

    const getTemplateJsonFromUrl = async (url) => {
      return await loadJson(url)
    }

    const updateSlide = ({img, json}) => {
      slide.value.setSlideData({
        imageBase64: img,
        json,
        aspectRatio: aspectRatio.value
      })
      emit('update', slide.value?.getDto())
    }

    const loadWidgetJson = async () => {
      const widgetId = slide.value?.widgetId
      if (!widgetId) {
        widgetJsonLoaded.value = true
        widgetJson.value = {}
        return
      }
      widgetJsonLoaded.value = false
      widgetJson.value = await getTemplateJson(widgetId)
      widgetJsonLoaded.value = true
    }

    watch(() => props.slideObj, async (value) => {
      if (value && value.widgetType !== 'IMAGE_COMPOSER') return
      slide.value = value ? props.slideObj : new CustomSlide()
      aspectRatio.value = slide.value?.slideData?.aspectRatio
      try {
        await loadWidgetJson()
      }
      catch (e) {
        error(e.message)
        emit('update:visible', false)
      }
    })

    return {
      pixieRef,
      loading,
      handleUpdate,
      handleClose
    }
  }
})
</script>

<style lang="less">

</style>
