<script setup>

import {onMounted, onUnmounted, ref, watch} from "vue"

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  className: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['select'])

const commandListContainer = ref()
const selectedIndex = ref(0)
const navigationKeys = ["ArrowUp", "ArrowDown", "Enter"]
function onKeyDown(e) {
  if (navigationKeys.includes(e.key)) {
    e.preventDefault()
    e.stopPropagation()
    if (e.key === "ArrowUp") {
      selectedIndex.value =
          (selectedIndex.value + props.items.length - 1) % props.items.length
      scrollToSelected()
      return true
    }
    if (e.key === "ArrowDown") {
      selectedIndex.value = (selectedIndex.value + 1) % props.items.length

      scrollToSelected()
      return true
    }
    if (e.key === "Enter") {
      selectItem(selectedIndex.value)
      return true
    }
    return false
  }
}


function selectItem(index) {
  const item = props.items[index]

  if (item) {
    emit('select', item)
  }
}

watch(()=> props.items?.length, () => {
  selectedIndex.value = 0
})

function updateScrollView(container, item) {
  const containerHeight = container.offsetHeight
  const itemHeight = item ? item.offsetHeight : 0

  const top = item.offsetTop
  const bottom = top + itemHeight

  if (top < container.scrollTop) {
    container.scrollTop -= container.scrollTop - top + 5
  } else if (bottom > containerHeight + container.scrollTop) {
    container.scrollTop += bottom - containerHeight - container.scrollTop + 5
  }
}

function scrollToSelected() {
  const container = commandListContainer.value
  const item = container?.children[selectedIndex.value]

  if (container && item) {
    updateScrollView(container, item)
  }
}

onMounted(()=>{
  document.addEventListener("keydown", onKeyDown)
})

onUnmounted(()=>{
  document.removeEventListener("keydown", onKeyDown)
})


</script>

<template>
  <div
    ref="commandListContainer"
    :class="className || 'z-50 h-auto overflow-y-auto px-1 py-2 transition-all'"
  >
    <template v-for="(item, index) in items">
      <slot
        name="item"
        v-bind="{item, index, isActive: selectedIndex === index}"
      />
    </template>
  </div>
</template>

<style scoped>

</style>
