<template>
  <a-modal
    :closable="false"
    :open="visible"
    width="100%"
    wrap-class-name="template-constructor-modal"
    @cancel="onCancel"
    @ok="onSave"
  >
    <a-layout v-if="visible">
      <a-layout-content>
        <div
          :style="backgroundStyle"
          class="template-preview"
        >
          <video
            v-if="backgroundVideo"
            autoplay
            class="video-layer"
            controls="false"
            loop
            muted
          >
            <source
              :src="backgroundVideo.source"
              :type="backgroundVideo.mimeType"
            >
            Your browser does not support the video tag.
          </video>
          <AnimationPreview
            v-if="data?.background?.animation"
            :animation="ANIMATIONS[data.background?.animation]"
          />
          <component
            :is="template"
            :assets="assets"
            :data="data"
            style="position: relative; height: 100%;"
          />
        </div>
      </a-layout-content>
      <a-layout-sider
        theme="light"
        width="300"
      >
        <TemplatePicker
          v-if="!templateSelected"
          @change="onTemplateChange"
        />
        <TemplateEditor
          v-else
          v-model:data="data"
          :assets="assets"
          :default-template-data="defaultTemplateData"
          :animations="animations"
          :editor="editor"
          @back="handleBack"
          @valid-change="onValidChange"
        />
      </a-layout-sider>
    </a-layout>
    <template #footer>
      <a-space>
        <a-button
          @click="onCancel"
        >
          {{ $t('components.smartTemplateConstructor.templateConstructorModal.cancelText') }}
        </a-button>
        <a-button
          type="primary"
          :disabled="!isValid"
          @click="onSave"
        >
          {{ $t('components.smartTemplateConstructor.templateConstructorModal.okText') }}
        </a-button>
      </a-space>
    </template>
  </a-modal>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'

import AnimationPreview from '@/components/smartTemplateConstructor/animations/AnimationPreview'
import TemplatePicker from '@/components/smartTemplateConstructor/TemplatePicker'
import TemplateEditor from '@/components/smartTemplateConstructor/TemplateEditor'
import { ANIMATIONS } from '@/components/smartTemplateConstructor/animations/animations'
import templates from '@/components/smartTemplateConstructor/templates'
import { cloneDeep } from '@apollo/client/utilities'
import { createSlideByType, Slide, SmartTemplateTextSlide } from '@/helpers/Slides'

const DEFAULT_SLIDE = SmartTemplateTextSlide

export default defineComponent({
  name: 'TemplateConstructorModal',
  components: {
    TemplateEditor,
    TemplatePicker,
    AnimationPreview,
    ...templates
  },
  props: {
    visible: Boolean,
    slideObj: Slide
  },
  emits: ['update', 'update:visible', 'close'],
  setup (props, { emit }) {
    const slide = ref(props.slideObj || new DEFAULT_SLIDE())
    const data = ref(cloneDeep(slide.value?.slideData))
    const assets = ref(slide.value.getAssets && slide.value.getAssets())
    const defaultTemplateData = computed(() => slide.value?.settings?.defaultSlideData)
    const template = computed(() => slide.value?.constructor?.template)
    const animations = computed(() => slide.value?.constructor?.animations)
    const editor = computed(() => slide.value?.constructor?.editor)
    const templateSelected = ref(false)
    const isValid = ref(!!props.slideObj)

    const backgroundStyle = computed(() => {
      const background = data.value?.background
      const backgroundType = background ? Object.keys(background)[0] : ''
      switch (backgroundType) {
        case 'color':
          return {
            background: data.value.background.color
          }
        case 'mediaImageReference':
          return {
            background: `url(${assets.value?.background?.source}) no-repeat center center`,
            backgroundSize: 'cover'
          }
      }

      return ''
    })

    const backgroundVideo = computed(() => {
      const background = data.value?.background
      const backgroundType = background ? Object.keys(background)[0] : ''
      const isVideo = backgroundType === 'mediaVideoReference'
      return isVideo ? {
        source: assets.value?.background?.source,
        mimeType: assets.value?.background?.mimeType
      } : null
    })

    const onTemplateChange = (widgetType) => {
      if (slide.value?.widgetType !== widgetType) {
        slide.value = createSlideByType(widgetType)
        data.value = cloneDeep(slide.value?.slideData)
        assets.value = {}
      }
      isValid.value = false
      templateSelected.value = true
    }

    const handleBack = () => {
      templateSelected.value = false
    }

    const onSave = () => {
      slide.value.setWidgetId(props.slideObj?.widgetId)
      slide.value.setSlideData(data.value)
      slide.value.setWaitingForUpdate(true)
      emit('update', slide.value.getDto())
      reset()
    }

    const onCancel = () => {
      reset()
      emit('close')
    }

    const reset = () => {
      emit('update:visible', false)
      templateSelected.value = false
      slide.value = new DEFAULT_SLIDE()
      assets.value = slide.value.getAssets()
      isValid.value = false
      data.value = slide.value?.slideData
    }

    const onValidChange = (valid) => {
      isValid.value = valid
    }

    watch(() => props.visible, (value) => {
      if (value) {
        if (!slide.value) {
          slide.value = new DEFAULT_SLIDE()
          data.value = cloneDeep(slide.value?.slideData)
          assets.value = slide.value.getAssets()
        }
      }
    })

    watch(() => props.slideObj, (value) => {
      slide.value = value ? props.slideObj : new DEFAULT_SLIDE()
      isValid.value = !!value
      templateSelected.value = !!value
      data.value = cloneDeep(slide.value?.slideData)
      assets.value = slide.value.getAssets()
    })

    return {
      slide,
      data,
      assets,
      isValid,
      onValidChange,
      template,
      animations,
      editor,
      defaultTemplateData,
      ANIMATIONS,
      backgroundStyle,
      templateSelected,
      backgroundVideo,
      onTemplateChange,
      onSave,
      onCancel,
      handleBack
    }
  }
})
</script>

<style lang="less">
@import '@/styles/variables.less';

.template-constructor-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  .ant-modal-content {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }
  .ant-modal-footer {
    padding: 16px;
    margin-top: 0;
  }

  .ant-modal-body {
    flex: 1;
    padding: 0;
    display: flex;
    overflow: hidden;

    > .ant-layout {
      flex: 1;

      > .ant-layout-content {
        background-color: @bg-light-grey;
        display: flex;
        align-items: center;
        justify-content: center;

        .template-preview {
          background: black;
          width: 660px;
          aspect-ratio: 16/9;
          position: relative;
          margin: 40px 0;

          .video-layer {
            width: 100%;
            height: 100%;
            position: absolute;
          }
        }
      }

      > .ant-layout-sider {
        padding: 0 16px 16px 16px;

        > .ant-layout-sider-children {
          display: flex;
          flex-direction: column;

          > header {
            line-height: 56px;
          }

          > section {
            flex: 1;
          }

          > footer {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

</style>
