import ClockSlide from './Clock'
import FeedSlide from './Feed'
import ImageSlide from './Image'
import VideoSlide from './Video'
import DocumentSlide from './Document'
import NewsSlide from './News'
import QuotesSlide from './Quotes'
import SocialActivitySlide from './SocialActivity'
import SocialTiktokSlide from './SocialTiktok'
import SocialTwitterSlide from './SocialTwitter'
import SocialInstagramSlide from './SocialInstagram'
import CalendarGoogleSlide from './CalendarGoogle'
import CalendarMicrosoftSlide from './CalendarMicrosoft'
import WeatherSlide from './Weather'
import CovidSlide from './Covid'
import CovidAwarenessSlide from './CovidAwareness'
import StreamSlide from './Stream'
import WebsiteSlide from './Web'
import YelpSlide from './Yelp'
import CanvaSlide from './Canva'
import TickerSlide from './Ticker'
import FunFactsSlide from './FunFacts.vue'
import HoroscopesSlide from './Horoscopes.vue'
import LiveScoreSlide from './LiveScore.vue'
import PublicHolidaysSlide from './PublicHolidays.vue'
import TEDTalksSlide from './TEDTalks.vue'
import TastySlide from './Tasty.vue'
import GoogleReviews from '@/components/slides/GoogleReviews.vue'
import TripAdvisorSlide from '@/components/slides/TripAdvisor.vue'

export default {
  ClockSlide,
  FeedSlide,
  ImageSlide,
  VideoSlide,
  DocumentSlide,
  NewsSlide,
  QuotesSlide,
  SocialActivitySlide,
  SocialTwitterSlide,
  SocialInstagramSlide,
  SocialTiktokSlide,
  CalendarGoogleSlide,
  CalendarMicrosoftSlide,
  WeatherSlide,
  CovidSlide,
  CovidAwarenessSlide,
  StreamSlide,
  WebsiteSlide,
  YelpSlide,
  GoogleReviews,
  CanvaSlide,
  TickerSlide,
  FunFactsSlide,
  HoroscopesSlide,
  LiveScoreSlide,
  PublicHolidaysSlide,
  TEDTalksSlide,
  TastySlide,
  TripAdvisorSlide
}
