<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()
const isTrialTier = computed(() => store.getters['workspace/isTrialTier'])
const isEnterprize = computed(() => store.getters['workspace/isEnterprize'])
const isInactive = computed(() => store.getters['workspace/isInactive'])
const isPastDue = computed(() => store.getters['workspace/isPastDue'])
const isPaused = computed(() => store.getters['workspace/isPaused'])
const trialIsOver = computed(() => store.getters['workspace/trialIsOver'])
defineProps({
  allocatedDevices: Number,
  billingDevicesQuantity: Number,
  currentPeriodEndDate: String,
  currentBillingAmount: Number,
  currentBillingInterval: String
})

</script>

<template>
  <a-card v-if="isTrialTier">
    <a-row>
      <a-col
        :span="12"
        class="current-plan-row"
      >
        <a-typography-text>{{ $t(`components.subscriptionSettings.${!trialIsOver ? 'trialPlanName' : 'growthPlanName'}`) }}</a-typography-text>
        <a-typography-text type="secondary">
          {{ $t('components.subscriptionSettings.screensConnected', {count: allocatedDevices}) }}
        </a-typography-text>
      </a-col>
    </a-row>
  </a-card>
  <a-card v-else-if="isEnterprize">
    <a-row>
      <a-col
        :span="12"
        class="current-plan-row"
      >
        <a-typography-text>{{ $t('components.subscriptionSettings.enterprisePlanName') }}</a-typography-text>
        <a-typography-text type="secondary">
          {{ $t('components.subscriptionSettings.includesNumberOfLicenses', {count: billingDevicesQuantity}) }}
        </a-typography-text>
      </a-col>
      <a-col
        :span="12"
        class="current-plan-row"
      >
        <a-typography-text
          v-if="!isInactive"
          type="secondary"
        >
          {{ $t('components.subscriptionSettings.renewalDate') }} <span v-text="currentPeriodEndDate" />
        </a-typography-text>
        <a-typography-text
          v-if="isPastDue"
          type="danger"
        >
          {{ $t('components.subscriptionSettings.failed') }}
        </a-typography-text>
      </a-col>
    </a-row>
  </a-card>
  <a-card v-else>
    <a-row>
      <a-col
        :span="12"
        class="current-plan-row"
      >
        <a-typography-text>{{ $t('components.subscriptionSettings.growthPlanName') }}</a-typography-text>
        <a-typography-text type="secondary">
          {{ $t('components.subscriptionSettings.includesNumberOfLicenses', {count: billingDevicesQuantity}) }}
        </a-typography-text>
      </a-col>
      <a-col
        v-if="!isInactive && !isPaused"
        :span="12"
        class="current-plan-row"
      >
        <a-typography-text v-if="currentBillingInterval">
          <span v-format-cents="currentBillingAmount" />/{{ $t(`components.subscriptionSettings.${currentBillingInterval}`) }}
        </a-typography-text>
        <a-typography-text type="secondary">
          {{ $t('components.subscriptionSettings.renewalDate') }} <span v-text="currentPeriodEndDate" />
        </a-typography-text>
        <a-typography-text
          v-if="isPastDue"
          type="danger"
        >
          {{ $t('components.subscriptionSettings.failed') }}
        </a-typography-text>
      </a-col>
    </a-row>
  </a-card>
</template>

<style scoped lang="less">
.current-plan-row {
  display: flex;
  flex-direction: column;
}
</style>
