<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.covid.slideStep1.subTitle')"
    :title="$t('components.slides.covid.slideStep1.title')"
    no-border
  >
    <GeoSuggest
      ref="geoSuggest"
      :debounce="fn => debounce(fn, 500)"
      :search="searchInput"
      :suggestion="selectedSuggestion"
      @geocoded="onDecode"
    />
    <a-select
      ref="geoInput"
      v-model:value="selectedPlaceLabel"
      :disabled="inputDisabled"
      :filter-option="false"
      :loading="inputDisabled"
      :max-tag-text-length="10"
      :not-found-content="loading ? undefined : null"
      :options="suggestionOptions"
      :placeholder="$t('components.slides.covid.slideStep1.placeSelectPlaceholder')"
      :show-search="true"
      style="width: 100%; margin-bottom: 16px"
      @search="onSearch"
      @select="onSelect"
    >
      <template
        v-if="loading"
        #notFoundContent
      >
        <a-spin size="small" />
      </template>
    </a-select>
  </SlideStep>
</template>

<script>
import { computed, defineComponent, nextTick, onMounted, ref } from 'vue'
import { debounce } from 'lodash-es'
import SlideStep from '@/components/SlideStep'
import {  countriesCodesToNamesMap } from '@/helpers/Countries'
import GeoSuggest from '@/components/GeoSuggest'
import { error } from '@/utils'
import { useI18n } from 'vue-i18n'
import { PublicHolidaysSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/DurationSlideStep.vue'

export default defineComponent({
  name: 'PublicHolidaysSlide',
  components: {
    DurationSlideStep,
    GeoSuggest,
    SlideStep
  },
  props: {
    title: String,
    slideObj: PublicHolidaysSlide,
    groupId: String,
    disabled: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const slide = props.slideObj || new PublicHolidaysSlide()
    const { t } = useI18n()
    const { country } = slide.slideData
    const geoSuggest = ref(null)
    const geoInput = ref(null)
    const inputDisabled = ref(false)
    const selectedPlaceLabel = ref(countriesCodesToNamesMap[country] || country)
    const selectedPlace = ref(country)

    toggleDisabled(!selectedPlace.value)
    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })

    const searchInput = ref(country || '')
    const selectedSuggestion = ref(null)

    const suggestionOptions = computed(() => {
      const suggestions = geoSuggest?.value?.suggestions ?? []
      return suggestions.map(({
        description,
        placeId
      }) => ({
        label: description,
        value: placeId
      }))
    })

    onMounted(() => {
      geoInput.value.focus()
    })

    const updateSlide = () => {
      emit('update-slide', slide)
    }

    const toggleDisabled = (status) => {
      emit('update:disabled', status)
    }

    const updateSlideData = () => {
      slide.updateSlideData({
        country: selectedPlace.value
      })
    }

    const onDecode = async (data) => {
      const code = data?.addressComponentMap?.country?.shortName
      const value = countriesCodesToNamesMap[code] || code
      inputDisabled.value = false
      selectedSuggestion.value = null
      if (!value) {
        error(t('components.slides.covid.countryNotSupportedError'))
        nextTick(() => {
          toggleDisabled(false)
          geoInput.value.focus()
        })
        selectedPlace.value = null
        return
      }
      selectedPlace.value = code
      selectedPlaceLabel.value = value
      updateSlideData()
      updateSlide()
      toggleDisabled(!selectedPlace.value)
    }

    const onSelect = (v) => {
      const suggestion = geoSuggest?.value?.suggestions.find(s => s.placeId === v)
      inputDisabled.value = true
      selectedSuggestion.value = suggestion
    }

    const onSearch = (value) => {
      searchInput.value = value
    }

    return {
      slide,
      searchInput,
      geoInput,
      geoSuggest,
      inputDisabled,
      suggestionOptions,
      selectedSuggestion,
      selectedPlace,
      selectedPlaceLabel,
      debounce,
      onSearch,
      onSelect,
      onDecode,
      updateSlide
    }
  }
})
</script>

<style lang="less">

</style>
