<template>
  <a-modal
    :open="visible"
    width="672px"
    destroy-on-close
    style="top: 80px"
    wrap-class-name="full-height-modal slide-modal"
    @cancel="cancel"
  >
    <template #title>
      <a-typography-paragraph
        v-model:content="slideName"
        style="margin-bottom: 0;"
        :editable="{
          maxlength: 50,
          triggerType: ['icon', 'text'] ,
          onEnd: handleNameChangeEnd,
          onCancel: handleNameChangeEnd
        }"
      />
    </template>
    <component
      :is="slideType"
      v-if="visible"
      v-model:title="title"
      v-model:disabled="disabled"
      :zoning="!isMainZone"
      :slide-obj="slideObject"
      @save="handleUpdate"
      @close="cancel"
      @update-slide="onSlideUpdate"
      @init-name="onNameInit"
    />
    <template #footer>
      <a-button
        key="submit"
        type="primary"
        :disabled="disabled"
        @click="handleUpdate"
      >
        Done
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { computed, defineComponent, nextTick, ref } from 'vue'
import slides from '@/components/slides'

export default defineComponent({
  name: 'SlideModal',
  components: {
    ...slides
  },
  props: {
    visible: Boolean,
    slideType: String,
    slide: Object,
    isMainZone: Boolean
  },
  emits: ['update', 'update:visible', 'close'],
  setup (props, { emit }) {
    const disabled = ref(false)
    const slideName = ref(null)
    const defaultSlideName = ref(null)
    const currentSlideName = ref(null)
    let slide

    const handleUpdate = () => {
      const slideToUpdate = slide || slideObject.value
      if (slideNameChanged.value || !slideToUpdate.name) {
        slideToUpdate.setName(slideName.value)
      }
      emit('update', slideToUpdate.getDto())
      emit('update:visible', false)
      slide = null
      slideName.value = null
      defaultSlideName.value = null
      currentSlideName.value = null
    }

    const slideNameChanged = computed(() => {
      return (currentSlideName.value && slideName.value !== currentSlideName.value) || (!currentSlideName.value && slideName.value !== defaultSlideName.value)
    })

    const slideObject = computed(() => {
      return props.slide
    })

    const onSlideUpdate = (updatedSlide) => {
      slide = updatedSlide
    }

    const handleNameChangeEnd = () => {
      if (!slideName.value) {
        nextTick(()=>{
          slideName.value = defaultSlideName.value
        })
      }
    }

    const cancel = () => {
      emit('update:visible', false)
      emit('close')
      slide = null
    }

    const onNameInit = ({ name, defaultName }) => {
      currentSlideName.value = name
      slideName.value = name || defaultName
      defaultSlideName.value = defaultName
    }

    return {
      title: ref(''),
      slideObject,
      slideName,
      disabled,
      slideNameChanged,
      cancel,
      onNameInit,
      handleUpdate,
      handleNameChangeEnd,
      onSlideUpdate
    }
  }
})
</script>

<style lang="less">
  .slide-modal {
    .ant-modal-title {
      padding-right: 20px;
    }
  }
</style>
