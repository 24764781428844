<script setup>
import { useStore } from 'vuex'
const store = useStore()
defineProps({
  enterpriseRequestFormSubmitted: {
    type: Boolean,
    default: false
  }
})

const openContactUs = () => {
  store.dispatch('openContactUs')
}

</script>

<template>
  <a-row align="middle">
    <a-col style="flex: 1">
      <template v-if="!enterpriseRequestFormSubmitted">
        <div>{{ $t('components.updateSubscriptionModal.overLimitScreensTitle') }}</div>
        <div>{{ $t('components.updateSubscriptionModal.overLimitScreensDescription') }}</div>
      </template>
      <template v-else>
        <div>{{ $t('components.updateSubscriptionModal.alreadyContactedTitle') }}</div>
        <div>{{ $t('components.updateSubscriptionModal.alreadyContactedDescription') }}</div>
      </template>
    </a-col>
    <a-col>
      <a-button
        type="primary"
        :disabled="enterpriseRequestFormSubmitted"
        style="margin-left: 16px;"
        @click="openContactUs"
      >
        {{ $t('components.updateSubscriptionModal.contactUsButtonText') }}
      </a-button>
    </a-col>
  </a-row>
</template>

<style scoped lang="less">

</style>
