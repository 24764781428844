<template>
  <a-modal
    :open="visible"
    :closable="false"
    :mask-closable="!loading"
    :title="$t('components.attachDeviceModal.title')"
    width="540px"
    class="device-attach-modal"
    destroy-on-close
    :ok-text="$t('components.attachDeviceModal.okText')"
    :cancel-text="$t('components.attachDeviceModal.cancelText')"
    :confirm-loading="loading || saving"
    :ok-button-props="{ disabled: !isReadyToSave }"
    @ok="onOk"
    @close="onClose"
    @cancel="onCancel"
  >
    <div
      ref="attachDeviceModalRef"
      class="device-attach-modal-wrapper"
    >
      <transition
        name="step"
        :duration="400"
      >
        <div
          v-if="!firstStepComplete"
          style="width: 100%; position: absolute; top:0;"
        >
          <a-row :gutter="16">
            <a-col
              :span="22"
              style="margin-top: 8px;"
            >
              <a-form
                ref="codeForm"
                class="device-code-form"
              >
                <a-form-item :label="$t('components.attachDeviceModal.screenCodeLabel')">
                  <a-input
                    v-for="(char, index) in codeSize"
                    :ref="refs[index]"
                    :key="char"
                    v-model:value="code[char]"
                    :data-index="index"
                    :disabled="loading"
                    size="large"
                    :maxlength="1"
                    class="device-code-input"
                    @input="handleCodeChange"
                    @keyup.delete="handleCodeDelete"
                    @keydown="handleKeydown"
                  />
                </a-form-item>
              </a-form>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="20">
              <a-typography-paragraph>
                {{ $t('components.attachDeviceModal.howToFindCode') }}
              </a-typography-paragraph>
              <a-typography-link
                href="https://intercom.help/kitcast/en/articles/9373587-how-to-install-kitcast-app-on-apple-tv"
                target="_blank"
              >
                {{ $t('components.attachDeviceModal.howToInstallDisplay') }}
              </a-typography-link>
            </a-col>
          </a-row>
        </div>
        <div
          v-else
          style="width: 100%; position: absolute; top:0;"
        >
          <a-row :gutter="16">
            <a-col
              :span="16"
              style="margin-top: 8px;"
            >
              <a-form
                class="device-info-form"
                layout="vertical"
                @submit.prevent="onOk"
              >
                <a-form-item :label="$t('components.attachDeviceModal.deviceNameLabel')">
                  <a-input
                    ref="nameInput"
                    v-model:value="deviceName"
                    :placeholder="$t('components.attachDeviceModal.deviceNamePlaceholder')"
                    size="large"
                  />
                </a-form-item>
              </a-form>
            </a-col>
            <a-col
              :span="8"
              style="margin-top: 8px;"
            >
              <a-form
                class="device-info-form"
                layout="vertical"
                @submit.prevent="onOk"
              >
                <a-form-item :label="$t('components.attachDeviceModal.orientationLabel')">
                  <a-select
                    ref="select"
                    v-model:value="orientation"
                    size="large"
                  >
                    <a-select-option value="0">
                      {{ $t('components.attachDeviceModal.horizontal') }}
                    </a-select-option>
                    <a-select-option value="90">
                      {{ $t('components.attachDeviceModal.vertical') }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-form>
            </a-col>
          </a-row>
        </div>
      </transition>
    </div>
  </a-modal>
</template>

<script>
import { computed, defineComponent, inject, nextTick, onMounted, onUnmounted, reactive, ref, toRef, watch } from 'vue'
import { error, success } from '@/utils'

import { useStore } from 'vuex'

const CODE_SIZE = 6
const codeObj = {}
for (let i = 1; i < CODE_SIZE + 1; i++) {
  codeObj[i] = ''
}
export default defineComponent({
  name: 'AttachDeviceModal',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:visible'],
  setup (props, { emit }) {
    const visible = toRef(props, 'visible')
    const store = useStore()
    const code = reactive(codeObj)
    const loading = ref(false)
    const saving = ref(false)
    const created = ref(false)
    const deviceName = ref('')
    const orientation = ref('0')
    const deviceId = ref(null)
    const codeForm = ref()
    const gtm = inject('gtm')
    const attachDeviceModalRef = ref(null)
    const nameInput = ref()
    const onboardingService = inject('onboardingService')
    const currentDevicesNumber = computed(() => store.getters['devices/allDevicesNumber'])
    const refs = Array.from(Array(CODE_SIZE)).map(() => ref())

    onMounted(() => {
      window.addEventListener('paste', handleCodePaste)
    })

    onUnmounted(() => {
      window.removeEventListener('paste', handleCodePaste)
    })

    const handleCodeChange = (e) => {
      e.preventDefault()
      const input = e.target
      const index = [...input.parentElement.childNodes].indexOf(input) - 1
      const nextInput = refs[index]?.value?.[0]
      if (e.target.value === ' ') {
        nextTick(() => { code[index] = '' })
      } else if (nextInput && e.target.value.trim()) {
        nextTick(() => { nextInput.focus() })
      }
    }

    const codeString = computed(() => {
      return Object.values(code).filter(Boolean).join('')
    })

    const isCodeFilled = computed(() => {
      return codeString?.value?.length === CODE_SIZE
    })

    const firstStepComplete = computed(() => {
      return !loading.value && created.value && isCodeFilled.value
    })

    const isReadyToSave = computed(() => {
      return deviceName.value && deviceName.value.length > 2
    })

    const handleCodeDelete = (e) => {
      const input = e.target
      const prevInput = input.previousElementSibling
      if (prevInput && prevInput.tagName && prevInput.tagName.toLowerCase() === 'input') {
        prevInput.focus()
      }
    }

    const handleKeydown = (e) => {
      const { key, target, ctrlKey, metaKey } = e
      const isChar = (/^.$/u.test(key))
      if ( metaKey || ctrlKey ) {
        return
      }
      if (target.value.trim() && isChar) {
        const index = e.target.getAttribute('data-index')
        const nextInput = target.nextElementSibling
        if (index + 1 in code) {
          code[index + 1] = key
          nextInput && nextInput.focus()
          e.preventDefault()
        }
      } else if (!key.trim() && isChar) {
        e.preventDefault()
      }
    }

    const handleCodePaste = (e) => {
      const isPasted = e.target.classList.contains('device-code-input')
      if (!isPasted) return
      const text = e.clipboardData.getData('Text')
      if (!text.trim() || !text[0].trim()) {
        e.preventDefault()
        return
      }
      if (text.length === CODE_SIZE) {
        nextTick(() => {
          for (let i = 1; i <= CODE_SIZE; i++) {
            code[i] = text[i - 1]

          }
          refs?.[CODE_SIZE]?.focus()
        })
      }
    }

    const resetModal = () => {
      handleClose()
      loading.value = false
      created.value = false
      saving.value = false
      deviceName.value = ''
      for (const key in code) {
        code[key] = ''
      }
    }

    const onOk = () => {
      saving.value = true
      store.dispatch('devices/updateDevice', {
        id: deviceId.value,
        input: {
          name: deviceName.value,
          orientation: parseInt(orientation.value || 0)
        },
        isCurrent: true
      }).then(() => {
        success()
        resetModal()
        emit('update:visible', false)
      }).catch((e) => {
        saving.value = false
        error(e.message ?? 'Error')
      })
    }

    const onClose = () => {
      resetModal()
      emit('update:visible', false)
    }

    const onCancel = () => {
      resetModal()
      emit('update:visible', false)
    }

    const attachDevice = () => {
      const pairingCode = codeString.value?.toUpperCase()
      const payload = {
        pairingCode
      }
      loading.value = true
      store.dispatch('devices/attachDeviceToCurrentGroup', payload).then((device) => {
        const { id, name } = device
        deviceId.value = id
        deviceName.value = name
        created.value = true
        onboardingService.goTo('changeScreenName')
        if (currentDevicesNumber.value === 0) {
          gtm.push({ event: 'First Device Attached', action: 'success', label: 'success'})
        }
      }).catch(e => {
        error(e.message ?? 'error')
      }).then(() => {
        loading.value = false
      })
    }

    const handleClose = () => {
      onboardingService.goTo('groupsSidebar')
    }

    watch(isCodeFilled, value => {
      if (value) {
        attachDevice()
      } else {
        created.value = false
      }
    })
    watch(()=>code[CODE_SIZE], (value) => {
      if (value && isCodeFilled.value && !loading.value) {
        attachDevice()
      }
    })

    watch(firstStepComplete, value => {
      setTimeout(() => {
        nameInput.value && nameInput.value.focus()
      }, 450)
    })

    watch(visible, value => {
      if (value) {
        nextTick(() => {
          refs[0].value && refs[0].value[0].focus()
          if (attachDeviceModalRef.value) {
            onboardingService.clearRef('attachDevice')
            onboardingService.clearRef('changeScreenName')
            setTimeout(() => {
              onboardingService.setRef(attachDeviceModalRef.value?.parentNode?.parentNode, 'attachDevice', () => {
                emit('update:visible', false)
              }, () => {
                emit('update:visible', false)
              })
              onboardingService.setRef(attachDeviceModalRef.value?.parentNode?.parentNode, 'changeScreenName', () => {
                emit('update:visible', false)
              }, () => {
                emit('update:visible', false)
              })
            }, 280)
          }
        })
      }
    })

    return {
      codeSize: CODE_SIZE,
      orientation,
      isCodeFilled,
      refs,
      code,
      codeString,
      loading,
      saving,
      created,
      codeForm,
      firstStepComplete,
      nameInput,
      deviceName,
      isReadyToSave,
      attachDeviceModalRef,
      onOk,
      onClose,
      onCancel,
      handleCodeChange,
      handleCodePaste,
      handleCodeDelete,
      handleKeydown
    }
  }
})
</script>

<style lang="less">
.device-attach-modal {
  overflow: hidden;
  .ant-modal-body {
    min-height: 200px;
    .device-attach-modal-wrapper {
      position: relative;
    }
  }
  .device-code-form {
    .ant-form-item-label {
      align-self: center;
    }
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: space-between;
      gap: 16px;
      input {
        text-align: center;
      }
    }
    .device-code-input {
      text-transform: uppercase;
    }
  }
  .step-enter-active,
  .step-leave-active {
    transition: right .4s ease-out,
                left .4s ease-out;
  }

  .step-enter-to {
    position: absolute;
    right: 0;
  }

  .step-enter-from {
    position: absolute;
    right: -100%;
  }

  .step-leave-to {
    position: absolute;
    left: -100%;
  }

  .step-leave-from {
    position: absolute;
    left: 0;
  }
}

</style>
