<script setup>

import InfoHeader from '@/InfoHeader.vue'
import { useStore } from 'vuex'
const store = useStore()

const openUpdatePaymentMethod= () => {
  store.dispatch('openUpdatePaymentMethod')
}
</script>

<template>
  <InfoHeader type="danger">
    <a-space>
      <span>{{ $t('components.upgradePaymentMethodHeader.lastPaymentFailed') }}</span>
      <a-button
        type="primary"
        size="small"
        @click="openUpdatePaymentMethod"
      >
        {{ $t('components.upgradePaymentMethodHeader.updatePaymentDetailsButtonText') }}
      </a-button>
    </a-space>
  </InfoHeader>
</template>

<style scoped lang="less">

</style>
