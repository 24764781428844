<template>
  <div class="current-plan-tab">
    <a-typography-title
      :level="5"
      style="display: contents"
    >
      {{ $t('components.subscriptionSettings.title') }}
    </a-typography-title>
    <a-divider />
    <SubscriptionSettingsInfoCard
      :allocated-devices="allDevicesNumber"
      :billing-devices-quantity="billingDevicesQuantity"
      :current-period-end-date="currentPeriodEndsFormatted"
      :current-billing-amount="currentBillingAmount"
      :current-billing-interval="subscription?.billingInfo?.billingInterval"
    />
    <a-divider />
    <SubscriptionSettingsManageBlock
      :current-period-end-date="currentPeriodEndsFormatted"
    />
  </div>
</template>

<script>
import { computed, createVNode, defineComponent, onMounted, ref, watch, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { useI18n } from 'vue-i18n'
import moment from 'moment'
import SubscriptionSettingsInfoCard from '@/components/SubscriptionSettingsInfoCard.vue'
import SubscriptionSettingsManageBlock from '@/components/SubscriptionSettingsManageBlock.vue'

export default defineComponent({
  name: 'SubscriptionSettings',
  components: { SubscriptionSettingsManageBlock, SubscriptionSettingsInfoCard },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const isPaid = computed(() => store.getters['workspace/isPaid'])
    const freeDevicesQuantity = computed(() => store.getters['workspace/freeDevicesQuantity'])
    const billingDevicesQuantity = computed(() => store.getters['workspace/billingDevicesQuantity'])
    const showGlobalSettings = computed(() => store.getters['showGlobalSettings'])
    const subscription = computed(() => store.getters['subscription/workspaceSubscription'])
    const currentPeriodEnds = computed(() => store.getters['subscription/currentPeriodEnds'])
    const currentPeriodEndsFormatted = computed(() => moment(currentPeriodEnds.value || null).format('MMMM D'))
    const workspaceCustomer = computed(() => store.getters['subscription/workspaceCustomer'])
    const discount = computed(()=>workspaceCustomer.value?.discount)
    const coupon = computed(()=>discount.value?.coupon)
    const billingAmount = computed(()=>subscription.value?.billingInfo?.billingAmount)
    const amountDiscount = computed(()=>coupon.value?.amount_off)
    const percentDiscount = computed(()=>coupon.value?.percent_off)
    const hasDiscount = computed(()=> !!amountDiscount.value || !!percentDiscount.value)

    const discountIsActive = computed(()=>{
      const now = moment()
      const start = discount.value?.start ? moment(discount.value?.start * 1000) : null
      const end = discount.value?.end ? moment(discount.value?.end * 1000) : null

      return (start && now.diff(start) > 0) && (!end || now.diff(end) < 0)
    })

    const currentBillingAmount = computed(()=>{
      if (!billingAmount.value) return null
      if (!hasDiscount.value || !discountIsActive.value) {
        return billingAmount.value
      }
      if (percentDiscount.value ) {
        return (billingAmount.value * (1 - percentDiscount.value/100))
      }
      else {
        return (billingAmount.value - amountDiscount.value)
      }
    })

    const lastWorkspaceInvoiceUrl = computed(() => store.getters['subscription/lastWorkspaceInvoiceUrl'])
    const globalSettingsActiveTab = computed(() => store.getters['globalSettingsActiveTab'])
    const isSubscription = computed(()=>globalSettingsActiveTab.value === 'subscription')
    const allDevicesNumber = computed(()=>store.getters['devices/allDevicesNumber'])
    const migratedModalShown = ref(!!localStorage.getItem('migrated-modal-shown') || false)
    const pendingStripeSubscriptionUpdate = computed(() => store.getters['workspace/pendingStripeSubscriptionUpdate'])
    const user = computed(() => store.getters['auth/user'])
    const isMigratedUser = computed(()=> user.value?.userCreatedBy === 'V1_MIGRATION')

    onMounted(()=>{
      store.dispatch('workspace/updateBillingInfo')
      store.dispatch('subscription/setWorkspaceCustomer')
      isPaid.value && store.dispatch('subscription/getPaymentMethod')
      isPaid.value && store.dispatch('subscription/geWorkspaceInvoices')
    })

    const showMigratedModal = () => {
      localStorage.setItem('migrated-modal-shown', 'true')
      return Modal.confirm({
        title: t('components.subscriptionSettings.migratedModalTitle'),
        icon: createVNode(ExclamationCircleOutlined),
        content: t('components.subscriptionSettings.migratedModalContent'),
        okText: t('components.subscriptionSettings.migratedModalOkButtonText'),
        cancelText: t('components.subscriptionSettings.migratedModalCancelButtonText'),
        width: '768px',
        onOk () {
          store.dispatch('openUpdateSubscription')
        }
      })
    }

    watch(showGlobalSettings, (value)=>{
      if (value && isSubscription.value && isPaid.value) {
        store.dispatch('workspace/updateBillingInfo')
        store.dispatch('subscription/getPaymentMethod')
        store.dispatch('subscription/geWorkspaceInvoices')
      }
    })

    watchEffect(()=>{
      if (!migratedModalShown.value && isMigratedUser.value && pendingStripeSubscriptionUpdate.value) {
        showMigratedModal()
      }
    })

    return {
      subscription,
      allDevicesNumber,
      lastWorkspaceInvoiceUrl,
      freeDevicesQuantity,
      billingDevicesQuantity,
      currentBillingAmount,
      currentPeriodEndsFormatted
    }
  }
})
</script>

<style lang="less" scoped>
.current-plan-tab {
  height: 100%;
  display: flex;
  flex-direction: column;
}


</style>
