<template>
  <div class="menu-editor">
    <TextElement
      v-model:value-model="fieldsState.caption"
      :max-length="25"
      :title="$t('components.smartTemplateConstructor.menuEditor.captionInputTitle')"
    />
    <TextElement
      v-model:value-model="fieldsState.header"
      :lines="3"
      :title="$t('components.smartTemplateConstructor.menuEditor.headerInputTitle')"
    />
    <TextElement
      v-model:value-model="fieldsState.subHeader"
      :max-length="25"
      :title="$t('components.smartTemplateConstructor.menuEditor.subHeaderInputTitle')"
    />
    <TextElement
      v-model:value-model="fieldsState.price"
      :max-length="6"
      :title="$t('components.smartTemplateConstructor.menuEditor.priceInputTitle')"
    />
    <TextElement
      v-model:value-model="fieldsState.comment"
      :lines="2"
      :max-length="50"
      :title="$t('components.smartTemplateConstructor.menuEditor.descriptionInputTitle')"
    />
    <MediaPicker
      :selected="assets?.image"
      @update="onMediaUpdate"
    />
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref, watchEffect } from 'vue'
import TextElement from '@/components/smartTemplateConstructor/segmentElements/TextElement'
import MediaPicker from '@/components/smartTemplateConstructor/generalElements/MediaPicker'

export default defineComponent({
  name: 'MenuEditor',
  components: {
    MediaPicker,
    TextElement
  },
  props: {
    data: Object,
    assets: Object
  },
  emits: ['validChange'],
  setup (props, { emit }) {
    const fieldsState = reactive(props.data)

    const isValid = computed(() => !!(fieldsState.caption
        && fieldsState.header
        && fieldsState.subHeader
        && fieldsState.price
        && fieldsState.comment
        && fieldsState.mediaImageReference))

    const assets = ref(props.assets)

    const onMediaUpdate = (media) => {
      fieldsState.mediaImageReference = media.mediaReference
      if (assets.value) {
        assets.value.image = media
      }
    }

    watchEffect(() => {
      emit('validChange', isValid.value)
    })

    return {
      fieldsState,
      onMediaUpdate
    }
  }
})
</script>

<style lang="less" scoped>
.menu-editor {
}

</style>
