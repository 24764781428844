<script setup>

import { useStore } from 'vuex'
const store = useStore()

defineProps({
  isPaid: {
    type: Boolean,
    default: false
  },
  allDevicesNumber: {
    type: Number,
    default: 0
  },
  billingDevicesQuantity: {
    type: Number,
    default: 0
  }
})

const openDevicesModal = () => {
  store.dispatch('openDevices')
}

</script>

<template>
  <p v-if="!isPaid">
    {{ $t('components.updateSubscriptionModal.kitcastGrowth') }}
    <br>
    <span style="color: var(--ant-primary-color); white-space: nowrap">{{ $t('components.updateSubscriptionModal.screensNumber', {count: allDevicesNumber}) }}</span> {{ $t('components.updateSubscriptionModal.inDemoMode') }}
  </p>
  <p v-else>
    {{ $t('components.updateSubscriptionModal.kitcastGrowth') }}
    <br>
    <span style="color: var(--ant-primary-color); white-space: nowrap"> {{ $t('components.updateSubscriptionModal.screensNumber', {count: billingDevicesQuantity}) }} </span>
  </p>
  <a-typography-link
    v-if="!isPaid"
    type="secondary"
    underline
    @click="openDevicesModal"
  >
    {{ $t('components.updateSubscriptionModal.manageScreensLink') }}
  </a-typography-link>
</template>

<style scoped lang="less">

</style>
