<template>
  <a-spin :spinning="loading">
    <div style="height: 100vh" />
  </a-spin>
  <AuthModal
    v-if="!loading"
    :is-login="isLogin"
    :is-signup="isSignup"
    :is-simple-signup="isSimpleSignup"
    :is-reset="isReset"
    :is-s-s-o-login="isSSOLogin"
    :is-email-confirmed="isEmailConfirmed"
    :token="token"
  />
</template>

<script>
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import AuthModal from '@/components/AuthModal'
import { error } from '@/utils'
import { openLoginWithGoogleWindow } from '@/helpers/Google'
import { openLoginWithMicrosoftWindow } from '@/helpers/Microsoft'

export default defineComponent({
  name: 'AuthPage',
  components: { AuthModal },
  props: {
    token: String
  },
  setup (props) {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const authStatus = computed(() => store.getters['auth/authStatus'])
    const isAuthenticated = computed(() => store.getters['auth/isAuthenticated'])
    const isLogin = computed(() => route.name === 'Login')
    const isSignup = computed(() => route.name === 'Signup')
    const isSSOLogin = computed(() => route.name === 'SSOLogin')
    const isSimpleSignup = computed(() => route.name === 'SimpleSignup')
    const isReset = computed(() => route.name === 'ForgotPassword')
    const isEmailConfirmed = computed(() => route.name === 'EmailConfirmed')
    const loading = ref(true)

    onMounted(async () => {
      if (isAuthenticated.value && !authStatus.value) {
        await store.dispatch('auth/initUser').then(() => {
          return router.push({ name: 'Home', query: { inviteToken: props.token} })
        })
      } else if (authStatus.value) {
        return router.push({ name: 'Home', query: { inviteToken: props.token} })
      } else {
        loading.value = false
      }
      window.addEventListener('message', receiveMessage, false)
    })

    onUnmounted(() => {
      window.removeEventListener('message', receiveMessage)
    })

    const receiveMessage = ({ data }) => {
      try {
        data = JSON.parse(data)
      } catch (e) {
        return
      }

      let {
        action,
        platform,
        state,
        code,
        error: errorMessage
      } = data

      if (errorMessage) {
        return error(errorMessage)
      }

      if (state && code) {
        platform = 'oidc'
        action = 'login'
      }

      if (!action || !platform) return

      let input, dispatchActionName
      switch (action) {
        case 'login':
          switch (platform) {
            case 'google':
              input = { code }
              dispatchActionName = 'auth/loginWithGoogle'
              break
            case 'microsoft':
              input = { code }
              dispatchActionName = 'auth/loginWithMicrosoft'
              break
            case 'oidc':
              input = { code, state }
              dispatchActionName = 'auth/loginWithOIDC'
              break
          }
      }
      if (!input || !dispatchActionName) return
      store.dispatch(dispatchActionName, input).then(() => {
        router.push({ name: 'Home' })
      }).catch(e => {
        error(e.message)
      })
    }

    return {
      loading,
      isAuthenticated,
      authStatus,
      isLogin,
      isSignup,
      isSimpleSignup,
      isReset,
      isSSOLogin,
      isEmailConfirmed,
      openLoginWithGoogleWindow,
      openLoginWithMicrosoftWindow,
    }
  }
})

</script>

<style scoped>

</style>
