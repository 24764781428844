import DirectoryTemplate from '@/components/smartTemplateConstructor/templates/DirectoryTemplate'
import SaleTemplate from '@/components/smartTemplateConstructor/templates/SaleTemplate'
import MenuTemplate from '@/components/smartTemplateConstructor/templates/MenuTemplate'
import SimpleSignTemplate from '@/components/smartTemplateConstructor/templates/SimpleSignTemplate'
import FacebookGroupTemplate from '@/components/smartTemplateConstructor/templates/FacebookGroupTemplate'
import WorkingHoursTemplate from '@/components/smartTemplateConstructor/templates/WorkingHoursTemplate'
import FloorMapTemplate from '@/components/smartTemplateConstructor/templates/FloorMapTemplate'

export default {
  DirectoryTemplate,
  MenuTemplate,
  SaleTemplate,
  SimpleSignTemplate,
  FacebookGroupTemplate,
  FloorMapTemplate,
  WorkingHoursTemplate
}
