<template>
  <a-layout
    class="full-height"
    style="overflow: hidden"
  >
    <GeneralSider>
      <a-spin
        :spinning="socialAccountsLoading"
        style="min-height: 80px;"
      >
        <a-menu
          v-model:selectedKeys="selectedTab"
          theme="light"
          mode="inline"
          class="sider-menu no-overflow"
        >
          <a-menu-item
            key="kitcastStorage"
            @click="selectTab('kitcastStorage')"
          >
            <template #icon>
              <HddOutlined />
            </template>
            {{ $t('components.mediaView.storageTitle') }}
          </a-menu-item>
          <a-menu-item
            v-for="account in googleDriveAccounts"
            :key="account.id"
            @click="selectTab('googleDrive', account.id)"
          >
            <template #icon>
              <GoogleOutlined />
            </template>
            {{ account.name }}
          </a-menu-item>
          <a-menu-item
            v-for="account in dropboxAccounts"
            :key="account.id"
            @click="selectTab('dropbox', account.id)"
          >
            <template #icon>
              <DropboxOutlined />
            </template>
            {{ account.name }}
          </a-menu-item>
          <a-menu-item
            v-if="!socialAccountsLoading && !hasConnectedGDrives"
            :key="'connectGDrive'"
            @click="selectTab('connectGDrive')"
          >
            <template #icon>
              <GoogleOutlined />
            </template>
            {{ GOOGLE_DRIVE_TITLE }}
          </a-menu-item>
          <a-menu-item
            v-if="!socialAccountsLoading && !hasConnectedDropboxes"
            :key="'connectDropbox'"
            @click="selectTab('connectDropbox')"
          >
            <template #icon>
              <DropboxOutlined />
            </template>
            {{ DROPBOX_TITLE }}
          </a-menu-item>
        </a-menu>
      </a-spin>
      <template #footer>
        <a-menu
          theme="light"
          :selectable="false"
        >
          <a-dropdown placement="topLeft">
            <a-menu-item>
              <template #icon>
                <PlusCircleOutlined />
              </template>
              {{ $t('components.mediaView.addStorageButtonText') }}
            </a-menu-item>
            <template #overlay>
              <div>
                <a-menu :selected-keys="[]">
                  <a-menu-item @click="addSocialAccount('google')">
                    <template #icon>
                      <GoogleOutlined />
                    </template>
                    {{ GOOGLE_DRIVE_TITLE }}
                  </a-menu-item>
                  <a-menu-item @click="addSocialAccount('dropbox')">
                    <template #icon>
                      <DropboxOutlined />
                    </template>
                    {{ DROPBOX_TITLE }}
                  </a-menu-item>
                </a-menu>
              </div>
            </template>
          </a-dropdown>
        </a-menu>
      </template>
    </GeneralSider>
    <a-layout-content>
      <a-layout-content style="background: #fff; height: 100%; overflow-x: auto; position: relative;display: flex;flex-direction: column;">
        <a-page-header style="position: sticky; z-index: 3; top: 0;">
          <template #title>
            {{ tabTitle }}
          </template>
        </a-page-header>
        <component
          :is="currentTabComponent"
          v-bind="currentTabParams"
        />
      </a-layout-content>
    </a-layout-content>
  </a-layout>
</template>

<script>
import { computed, defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { DownOutlined, DropboxOutlined, GoogleOutlined, HddOutlined, PlusCircleOutlined } from '@ant-design/icons-vue'
import { addAccount, toStandard } from '@/helpers/SocialAccount'
import StorageView from '@/components/media/StorageView'
import DropboxView from '@/components/media/DropboxView'
import GoogleDriveView from '@/components/media/GoogleDriveView'
import GeneralSider from '@/components/GeneralSider'
import ConnectNowView from '@/components/media/ConnectNowView'
import { useI18n } from 'vue-i18n'

const DROPBOX_TITLE = 'Dropbox'
const GOOGLE_DRIVE_TITLE = 'Google Drive'

export default defineComponent({
  name: 'MediaView',
  components: {
    ConnectNowView,
    GeneralSider,
    GoogleDriveView,
    DropboxView,
    StorageView,
    DownOutlined,
    GoogleOutlined,
    DropboxOutlined,
    HddOutlined,
    PlusCircleOutlined
  },
  props: {
    mediaTypes: {
      type: Array
    },
    selection: {
      type: String,
      validator (value) {
        return ['single', 'multiple', 'none'].indexOf(value) !== -1
      },
      default: 'single'
    },
    externalSelection: {
      type: String,
      validator (value) {
        return ['single', 'multiple', 'none'].indexOf(value) !== -1
      },
      default: 'single'
    },
    excludeHeadings: {
      type: Array,
      default: () => []
    },
    folderSelectDisabled: Boolean,
    disableActions: Boolean
  },
  setup (props) {
    const store = useStore()
    const { t } = useI18n()
    const currentTabComponent = ref('StorageView')
    const selectedTab = ref(['kitcastStorage'])
    const currentTabTitle = ref(t('components.mediaView.storageTitle'))
    const isGroupStorage = computed(() => currentTabComponent.value === 'StorageView')
    const defaultStorageParams = {
      mediaTypes: props.mediaTypes,
      selection: props.selection,
      excludeHeadings: props.excludeHeadings,
      disableActions: props.disableActions,
      folderSelectDisabled: props.folderSelectDisabled
    }

    const defaultExternalStorageParams = {
      mediaTypes: props.mediaTypes,
      selection: props.externalSelection,
      excludeHeadings: props.excludeHeadings
    }

    const currentTabParams = ref(defaultStorageParams)
    const socialAccountsLoading = computed(() => store.getters['social/socialAccountsLoading'])
    const googleDriveAccounts = computed(() => store.getters['social/socialGoogleAccounts'].map(toStandard))
    const dropboxAccounts = computed(() => store.getters['social/socialDropboxAccounts'].map(toStandard))

    const tabTitle = computed(()=>{
      return currentTabComponent.value === 'StorageView' ? t('components.mediaView.storageTitle') : currentTabTitle.value
    })

    const hasConnectedGDrives = computed(() => {
      return googleDriveAccounts.value?.length
    })
    const hasConnectedDropboxes = computed(() => {
      return dropboxAccounts.value?.length
    })

    const selectTab = (tab, accountId) => {
      store.commit('media/CLEAR_SELECTED_MEDIA')
      selectedTab.value = [accountId || tab]
      switch (tab) {
        case 'googleDrive':
          currentTabComponent.value = 'GoogleDriveView'
          currentTabTitle.value = googleDriveAccounts.value.find(({ id }) => id === accountId)?.name + ' gDrive'
          currentTabParams.value = {
            ...defaultExternalStorageParams,
            googleAccountId: accountId
          }
          break
        case 'dropbox':
          currentTabComponent.value = 'DropboxView'
          currentTabTitle.value = dropboxAccounts.value.find(({ id }) => id === accountId)?.name + ' dropbox'
          currentTabParams.value = {
            ...defaultExternalStorageParams,
            dropboxAccountId: accountId
          }
          break
        case 'connectDropbox':
          currentTabComponent.value = 'ConnectNowView'
          currentTabTitle.value = DROPBOX_TITLE
          currentTabParams.value = {
            title: DROPBOX_TITLE,
            platform: 'dropbox'
          }
          break
        case 'connectGDrive':
          currentTabComponent.value = 'ConnectNowView'
          currentTabTitle.value = GOOGLE_DRIVE_TITLE
          currentTabParams.value = {
            title: GOOGLE_DRIVE_TITLE,
            platform: 'google'
          }
          break
        case 'kitcastStorage':
          currentTabComponent.value = 'StorageView'
          currentTabTitle.value = t('components.mediaView.storageTitle')
          currentTabParams.value = defaultStorageParams
          break
      }
    }

    watch(() => hasConnectedGDrives.value, (current) => {
      if (current) {
        const lastItem = googleDriveAccounts.value[googleDriveAccounts.value.length - 1]
        selectTab('googleDrive', lastItem.id)
      }
    })

    watch(() => hasConnectedDropboxes.value, (current) => {
      if (current) {
        const lastItem = dropboxAccounts.value[dropboxAccounts.value.length - 1]
        selectTab('dropbox', lastItem.id)
      }
    })

    return {
      currentTabParams,
      currentTabComponent,
      googleDriveAccounts,
      dropboxAccounts,
      hasConnectedGDrives,
      hasConnectedDropboxes,
      socialAccountsLoading,
      tabTitle,
      isGroupStorage,
      selectedTab,
      DROPBOX_TITLE,
      GOOGLE_DRIVE_TITLE,
      selectTab,
      addSocialAccount: addAccount
    }
  }

})
</script>

<style lang="less">

  .actions {
    font-size: 18px;
    > span {
      cursor: pointer;
    }
  }
  .storage-table {
    .ant-image {
      width: 100%;
      cursor: pointer;
      .ant-image-img {
        height: inherit;
        object-fit: cover;
      }
    }
  }
</style>
