import {
  PREPARE_SETUP_INTENT,
  PREVIEW_WORKSPACE_SUBSCRIPTION_UPDATE,
  UPDATE_WORKSPACE_CUSTOMER,
  UPDATE_WORKSPACE_SUBSCRIPTION
} from '@/graphql/mutations'
import {
  GET_PAYMENT_METHOD,
  GET_STRIPE_GROWTH_PRICES,
  GET_WORKSPACE_CUSTOM_PRICE,
  GET_WORKSPACE_CUSTOMER,
  GET_WORKSPACE_SUBSCRIPTION,
  LIST_WORKSPACE_INVOICES
} from '@/graphql/queries'
import ApolloClient from '@/apollo'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    paymentMethod: null,
    workspaceInvoices: null,
    workspaceCustomer: null,
    workspaceSubscription: null,
    stripeGrowthPrices: null,
    stripeCustomPrice: null,
    stripeIntent: null
  },
  actions: {
    async getPaymentMethod ({commit}) {
      const { data: { getPaymentMethod } } = await ApolloClient.query({
        query: GET_PAYMENT_METHOD,
        fetchPolicy: 'no-cache'
      })
      return commit('SET_PAYMENT_METHOD', getPaymentMethod)
    },
    async geWorkspaceInvoices ({commit}) {
      const { data: { listWorkspaceInvoices: { data: invoices} } } = await ApolloClient.query({
        query: LIST_WORKSPACE_INVOICES,
        fetchPolicy: 'no-cache'
      })
      return commit('SET_WORKSPACE_INVOICES', invoices)
    },
    async getWorkspaceSubscription ({ commit }) {
      try {
        const { data: { getWorkspaceSubscription } } = await ApolloClient.query({ query: GET_WORKSPACE_SUBSCRIPTION })
        commit('SET_WORKSPACE_SUBSCRIPTION', getWorkspaceSubscription)
      } catch (e) {
        console.log(e)
      }
    },
    async getStripeGrowthPrices ({ commit }) {
      try {
        const { data: { getStripeGrowthPrices } } = await ApolloClient.query({ query: GET_STRIPE_GROWTH_PRICES })
        commit('SET_STRIPE_GROWTH_PRICES', getStripeGrowthPrices)
      } catch (e) {
        console.log(e)
      }
    },
    async getStripeCustomPrice ({ commit }) {
      try {
        const { data: { getWorkspaceStripeCustomPrice } } = await ApolloClient.query({ query: GET_WORKSPACE_CUSTOM_PRICE })
        commit('SET_STRIPE_CUSTOM_PRICE', getWorkspaceStripeCustomPrice)
      } catch (e) {
        console.log(e)
      }
    },
    async setWorkspaceCustomer ({ commit }) {
      try {
        const { data: { getWorkspaceCustomer } } = await ApolloClient.query({ query: GET_WORKSPACE_CUSTOMER })
        commit('SET_WORKSPACE_CUSTOMER', getWorkspaceCustomer)
      } catch (e) {
        console.log(e)
      }
    },
    async getStripeSetupIntentClientSecret ({ commit }) {
      try {
        const { data: { prepareSetupIntent: {client_secret} } } = await ApolloClient.mutate({ mutation: PREPARE_SETUP_INTENT })
        return client_secret
      } catch (e) {
        console.log(e)
      }
    },
    async previewWorkspaceSubscriptionUpdate ({ commit }, { input }) {
      return ApolloClient.mutate({ mutation: PREVIEW_WORKSPACE_SUBSCRIPTION_UPDATE, variables: { input } })
    },
    async updateWorkspaceSubscription ({commit, dispatch}, { input }) {
      const { data: { updateWorkspaceSubscription } } = await ApolloClient.mutate({
        mutation: UPDATE_WORKSPACE_SUBSCRIPTION,
        variables: { input }
      })
      dispatch('workspace/updateFrozenStatusesAndBillingInfo', null, { root: true })
      commit('UPDATE_WORKSPACE_SUBSCRIPTION', updateWorkspaceSubscription)
      return updateWorkspaceSubscription
    },
    async updateWorkspaceCustomer ({commit}, { input }) {
      const { data: { updateWorkspaceCustomer } } = await ApolloClient.mutate({
        mutation: UPDATE_WORKSPACE_CUSTOMER,
        variables: { input }
      })
      commit('SET_WORKSPACE_CUSTOMER', updateWorkspaceCustomer)
    },
  },
  getters: {
    paymentMethod: state => state.paymentMethod,
    daysToCardExpiration: state => {
      const card = state.paymentMethod?.card
      if (!card) return null
      const expYear = card.exp_year
      const expMonth = card.exp_month
      if (!expYear || !expMonth) return null
      const expirationDate = moment(`${expYear}-${expMonth}`, "YYYY-MM").endOf('month')
      const currentDate = moment()
      return expirationDate.diff(currentDate, 'days')
    },
    workspaceSubscription: state => state.workspaceSubscription,
    workspaceInvoices: state => state.workspaceInvoices,
    workspaceCustomer: state => state.workspaceCustomer,
    lastWorkspaceInvoiceUrl: state => state.workspaceInvoices?.[0]?.invoice_pdf,
    currentPeriodEnds: state => state.workspaceSubscription?.billingInfo?.currentPeriodEnd,
    stripeGrowthPrices: state => state.stripeGrowthPrices,
    stripeCustomPrice: state => state.stripeCustomPrice,
    stripeUpdateIntentClientSecret: state => state.intent?.client_secret
  },
  mutations: {
    SET_PAYMENT_METHOD (state, paymentMethod) {
      state.paymentMethod = paymentMethod
    },
    SET_WORKSPACE_INVOICES(state, invoices) {
      state.workspaceInvoices = invoices
    },
    SET_WORKSPACE_SUBSCRIPTION (state, subscription) {
      state.workspaceSubscription = subscription
    },
    UPDATE_WORKSPACE_SUBSCRIPTION (state, subscription) {
      state.workspaceSubscription = {...state.workspaceSubscription, ...subscription}
    },
    SET_STRIPE_CUSTOM_PRICE (state, price) {
      state.stripeCustomPrice = price
    },
    SET_STRIPE_GROWTH_PRICES (state, prices) {
      state.stripeGrowthPrices = prices
    },
    SET_WORKSPACE_CUSTOMER (state, customer) {
      state.workspaceCustomer = customer
    },
    CLEAR_SUBSCRIPTION_DATA (state) {
      state.paymentMethod = null
      state.workspaceSubscription = null
      state.stripeGrowthPrices = null
      state.stripeCustomPrice = null
      state.stripeIntent = null
    }
  }
}
