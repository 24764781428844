<template>
  <a-modal
    :title="title"
    :open="visible"
    style="top: 80px"
    wrap-class-name="full-height-modal"
    destroy-on-close
    :ok-text="$t('components.playlistModal.okText')"
    :cancel-text="$t('components.playlistModal.cancelText')"
    :ok-button-props="{ disabled: playlistObj.name?.trim()?.length === 0}"
    @ok="handleEditPlaylist"
    @cancel="resetPlaylistForm"
  >
    <a-form
      layout="vertical"
      @submit.prevent="handleEditPlaylist"
    >
      <a-form-item
        :label="$t('components.playlistModal.playlistNameLabel')"
        name="name"
      >
        <a-input
          ref="nameInput"
          v-model:value="playlistObj.name"
          maxlength="255"
          :placeholder="$t('components.playlistModal.playlistNamePlaceholder')"
        />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { computed, defineComponent, nextTick, reactive, ref, toRefs, watch } from 'vue'
import { randomPaletteColor } from '@/utils'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  name: 'PlaylistModal',
  props: {
    visible: {
      type: Boolean,
      default: true
    },
    playlist: Object
  },
  emits: ['update:visible', 'update'],
  setup (props, { emit }) {
    const { visible } = toRefs(props)
    const { t } = useI18n()
    const nameInput = ref(null)
    const { playlist } = toRefs(props)
    const playlistObj = reactive({
      name: playlist.value?.name || '',
      color: randomPaletteColor()
    })

    const title = computed(() => {
      return playlist?.value ? playlist.value.name : t('components.playlistModal.newPlaylist')
    })

    const handleEditPlaylist = () => {
      emit('update', playlistObj)
      resetPlaylistForm()
    }
    const resetPlaylistForm = () => {
      playlistObj.name = ''
      playlistObj.color = randomPaletteColor()
      emit('update:visible', false)
    }

    watch(visible, (newValue) => {
      if (playlist.value) {
        playlistObj.name = playlist.value?.name
        playlistObj.color = playlist.value?.color
      }
      nextTick(() => {
        newValue && nameInput.value && nameInput.value.focus()
      })
    })
    watch(() => playlist.value, (newValue) => {
      playlistObj.name = newValue.name
      playlistObj.color = newValue.color
    })
    return {
      title,
      nameInput,
      playlistObj,
      handleEditPlaylist,
      resetPlaylistForm
    }
  }
})
</script>

<style scoped>

</style>
