<script setup>
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import moment from 'moment'

defineProps({
  currentPeriodEndDate: String
})

const PAST_DUE_DAYS_LIMIT = 13

const store = useStore()
const isTrialTier = computed(() => store.getters['workspace/isTrialTier'])
const isPastDue = computed(() => store.getters['workspace/isPastDue'])
const isPaused = computed(() => store.getters['workspace/isPaused'])
const isUnpaid = computed(() => store.getters['workspace/isUnpaid'])
const isInactive = computed(() => store.getters['workspace/isInactive'])
const isFrozen = computed(() => store.getters['workspace/isFrozen'])
const isEnterprize = computed(() => store.getters['workspace/isEnterprize'])
const currentPeriodStarts = computed(() => store.getters['workspace/currentPeriodStarts'])
const trialIsOver = computed(() => store.getters['workspace/trialIsOver'])
const emailVerified = computed(() => store.getters['auth/emailVerified'])
const lastWorkspaceInvoiceUrl = computed(() => store.getters['subscription/lastWorkspaceInvoiceUrl'])
const paymentMethod = computed(() => store.getters['subscription/paymentMethod'])
const pastDueDays = computed(()=>{
  if (!currentPeriodStarts.value) return null
  const currentPeriodStartsDate = moment(currentPeriodStarts.value).add(PAST_DUE_DAYS_LIMIT, 'day')
  const currentDate = moment()
  return currentPeriodStartsDate.diff(currentDate, 'days')
})

const updateSubscription = () => {
  store.dispatch('closeGlobalSettings')
  store.dispatch('handleUpdateSubscription')
}

const updatePaymentMethod = () => {
  store.dispatch('closeGlobalSettings')
  store.dispatch('openUpdatePaymentMethod')
}

</script>

<template>
  <div
    style="display: flex; flex-direction: column; flex: 1;"
  >
    <template v-if="!emailVerified">
      <a-alert
        show-icon
        type="warning"
      >
        <template #message>
          <span v-html="$t('components.subscriptionSettings.verifyEmail')" />
        </template>
      </a-alert>
    </template>
    <template v-else-if="!isEnterprize && isTrialTier">
      <a-alert
        type="warning"
        show-icon
      >
        <template #message>
          <template v-if="!trialIsOver">
            {{ $t('components.subscriptionSettings.upgradeYourPlan', {date: currentPeriodEndDate}) }}
            <br>
            <a-typography-link
              class="underline-link"
              @click="updateSubscription"
            >
              {{ $t('components.subscriptionSettings.upgradeLink') }}
            </a-typography-link>
          </template>
          <template v-else>
            <i18n-t
              keypath="components.subscriptionSettings.trialIsOverText"
              tag="label"
              for="components.subscriptionSettings.trialIsOverLink"
            >
              <a-typography-link
                class="underline-link"
                @click="updateSubscription"
              >
                {{ $t('components.subscriptionSettings.trialIsOverLink') }}
              </a-typography-link>
            </i18n-t>
          </template>
        </template>
      </a-alert>
    </template>
    <template v-else-if="isEnterprize">
      <a-typography-title
        :level="5"
        style="margin-bottom: 20px;"
      >
        {{ $t('components.subscriptionSettings.manageSubscription') }}
      </a-typography-title>
      <a-alert
        v-if="isPastDue"
        show-icon
        type="warning"
        style="margin-bottom: 16px;"
      >
        <template #message>
          {{ $t('components.subscriptionSettings.lastPaymentFailEnterprize', {days: pastDueDays}, pastDueDays) }}
          <br>
          <a-typography-link
            class="underline-link"
            href="mailto:support@kitcast.tv"
          >
            {{ $t('components.subscriptionSettings.contactSupportLink') }}
          </a-typography-link>
        </template>
      </a-alert>
      <a-alert
        v-else-if="isUnpaid"
        show-icon
        type="warning"
        style="margin-bottom: 16px;"
      >
        <template #message>
          {{ $t('components.subscriptionSettings.subscriptionSuspendedEnterprize') }}
          <br>
          <a-typography-link
            class="underline-link"
            href="mailto:support@kitcast.tv"
          >
            {{ $t('components.subscriptionSettings.contactSupportLink') }}
          </a-typography-link>
        </template>
      </a-alert>
      <a-alert
        v-else-if="isPaused"
        show-icon
        type="warning"
        style="margin-bottom: 16px;"
      >
        <template #message>
          {{ $t('components.subscriptionSettings.subscriptionPaused') }}
          <br>
          <a-typography-link
            class="underline-link"
            href="mailto:support@kitcast.tv"
          >
            {{ $t('components.subscriptionSettings.contactSupportLink') }}
          </a-typography-link>
        </template>
      </a-alert>
      <a-alert
        v-else-if="isInactive"
        show-icon
        type="warning"
        style="margin-bottom: 16px;"
      >
        <template #message>
          {{ $t('components.subscriptionSettings.subscriptionDoesntExistEnterprize') }}
          <br>
          <a-typography-link
            class="underline-link"
            href="mailto:support@kitcast.tv"
          >
            {{ $t('components.subscriptionSettings.contactSupportLink') }}
          </a-typography-link>
        </template>
      </a-alert>
      <a-alert
        v-else-if="isFrozen"
        show-icon
        type="warning"
        style="margin-bottom: 16px;"
      >
        <template #message>
          {{ $t('components.subscriptionSettings.subscriptionFrozen') }}
          <br>
          <a-typography-link
            class="underline-link"
            href="mailto:support@kitcast.tv"
          >
            {{ $t('components.subscriptionSettings.contactSupportLink') }}
          </a-typography-link>
        </template>
      </a-alert>
      <a-space
        v-if="!isUnpaid && !isInactive && !isPaused"
        direction="vertical"
      >
        <a-typography-link
          :disabled="!lastWorkspaceInvoiceUrl"
          download
          :href="lastWorkspaceInvoiceUrl"
        >
          {{ $t('components.subscriptionSettings.downloadLatestInvoiceLink') }}
        </a-typography-link>
      </a-space>
      <div style="margin-top: 16px;">
        {{ $t('components.subscriptionSettings.contactUsToChangeSubscription') }} <a-typography-link href="mailto:support@kitcast.tv">
          support@kitcast.tv
        </a-typography-link>
      </div>
    </template>
    <template v-else>
      <a-typography-title
        :level="5"
        style="margin-bottom: 20px;"
      >
        {{ $t('components.subscriptionSettings.manageSubscriptionLink') }}
      </a-typography-title>
      <a-alert
        v-if="isPastDue"
        show-icon
        type="warning"
        style="margin-bottom: 16px;"
      >
        <template #message>
          {{ $t('components.subscriptionSettings.lastPaymentFail', {days: pastDueDays}, pastDueDays) }}
          <br>
          <a-typography-text
            class="underline-link"
            @click="updatePaymentMethod"
          >
            {{ $t('components.subscriptionSettings.updatePaymentMethodLink') }}
          </a-typography-text>
        </template>
      </a-alert>
      <a-alert
        v-else-if="isUnpaid"
        show-icon
        type="warning"
        style="margin-bottom: 16px;"
      >
        <template #message>
          {{ $t('components.subscriptionSettings.subscriptionSuspended') }}
          <br>
          <a-typography-link
            class="underline-link"
            @click="updateSubscription"
          >
            {{ $t('components.subscriptionSettings.upgradeLink') }}
          </a-typography-link>
        </template>
      </a-alert>
      <a-alert
        v-else-if="isPaused"
        show-icon
        type="warning"
        style="margin-bottom: 16px;"
      >
        <template #message>
          {{ $t('components.subscriptionSettings.subscriptionPaused') }}
          <br>
          <a-typography-link
            class="underline-link"
            href="mailto:support@kitcast.tv"
          >
            {{ $t('components.subscriptionSettings.contactSupportLink') }}
          </a-typography-link>
        </template>
      </a-alert>
      <a-alert
        v-else-if="isInactive"
        show-icon
        type="warning"
        style="margin-bottom: 16px;"
      >
        <template #message>
          {{ $t('components.subscriptionSettings.subscriptionDoesntExist') }}
          <br>
          <a-typography-link
            class="underline-link"
            @click="updateSubscription"
          >
            {{ $t('components.subscriptionSettings.upgradeLink') }}
          </a-typography-link>
        </template>
      </a-alert>
      <a-alert
        v-else-if="isFrozen"
        show-icon
        type="warning"
        style="margin-bottom: 16px;"
      >
        <template #message>
          {{ $t('components.subscriptionSettings.subscriptionFrozen') }}
          <br>
          <a-typography-link
            class="underline-link"
            href="mailto:support@kitcast.tv"
          >
            {{ $t('components.subscriptionSettings.contactSupportLink') }}
          </a-typography-link>
        </template>
      </a-alert>
      <a-space
        v-if="!isUnpaid && !isInactive && !isPaused && !isFrozen"
        direction="vertical"
        style="flex: 1;"
      >
        <a-typography-link
          @click="updateSubscription"
        >
          {{ $t('components.subscriptionSettings.addMoreLicensesLink') }}
        </a-typography-link>
        <div>
          <a-typography-link
            @click="updatePaymentMethod"
          >
            {{ $t('components.subscriptionSettings.changePaymentMethodLink') }}
          </a-typography-link>&nbsp;
          <a-typography-text
            v-if="paymentMethod"
            type="secondary"
          >
            {{ paymentMethod?.card?.brand }} ••••{{ paymentMethod?.card?.last4 }}
          </a-typography-text>
        </div>
        <a-typography-link
          :disabled="!lastWorkspaceInvoiceUrl"
          download
          :href="lastWorkspaceInvoiceUrl"
        >
          {{ $t('components.subscriptionSettings.downloadLatestInvoiceLink') }}
        </a-typography-link>
      </a-space>
      <div style="margin-top: 16px;">
        {{ $t('components.subscriptionSettings.contactUsToDowngradeSubscription') }} <a-typography-link href="mailto:support@kitcast.tv">
          support@kitcast.tv
        </a-typography-link>
      </div>
    </template>
  </div>
</template>

<style scoped lang="less">
.underline-link {
  color: #000
}

</style>
