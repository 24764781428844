<script setup>
import { computed, h, nextTick, provide, reactive, ref, watchEffect, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { Typography } from 'ant-design-vue';
import OnboardingVideo from '@/components/OnboardingVideo.vue'
const store = useStore()
const { t } = useI18n()
const { Text } = Typography;
// const onboardingStep = computed(() => 8)
const onboardingStep = computed(() => store.getters['auth/onboardingStep'])
const onboardingFinished = computed(() => store.getters['auth/onboardingFinished'])
// const onboardingFinished = computed(() => false)
const currentGroupDefaultPlaylistId = computed(() => store.getters['groups/currentGroupDefaultPlaylistId'])
const preloadedState = ref(false)
const initialized = ref(false)
const route = useRoute();
const router = useRouter();
const current = ref(onboardingStep.value);
const previous = ref(onboardingStep.value);
const back = ref(false)
const afterRefresh = ref(!!onboardingStep.value)
const open = ref(false);
const modalOpened = ref(false);
const userIsComplete = computed(() => store.getters['auth/userIsComplete'])
const showEmailConfirmedModal = computed(() => store.getters['showEmailConfirmedModal'])
const videoEnded = ref(false)
const gtm = inject('gtm')

const finalizeOnboarding = () => {
  store.dispatch('auth/updateOnboarding', {onboardingFinished: true, onboardingStep: 8})
}

const stepsState = reactive({
  screensMenu: {
    placement: 'bottom',
    ref: null,
    onNext: null,
    onPrev: null,
    onRefresh: null,
    requiredRef: function () { return this.ref},
    title: t('components.onboardingService.steps.screensMenu.title'),
    description: t('components.onboardingService.steps.screensMenu.description'),
    target: function() { return this.ref }
  },
  addDevice: {
    placement: 'left',
    ref: null,
    onNext: null,
    onPrev: null,
    loadable: true,
    onRefresh: async () => {
      return router.push({name: 'DevicesPage'})
    },
    requiredRef: function () { return this.ref},
    title: t('components.onboardingService.steps.addDevice.title'),
    description: t('components.onboardingService.steps.addDevice.description'),
    target: function() { return this.ref }
  },
  attachDevice: {
    placement: 'bottom',
    ref: null,
    onNext: null,
    onPrev: null,
    loadable: true,
    onRefresh: async () => {
      return router.push({name: 'DevicesPage'})
    },
    requiredRef: () => stepsState.addDevice.ref,
    skipToOnNext: 'groupsSidebar',
    title: t('components.onboardingService.steps.attachDevice.title'),
    description: h('div', {}, [
      h('p', {}, t('components.onboardingService.steps.attachDevice.description1')),
      h('p', {}, t('components.onboardingService.steps.attachDevice.description2')),
      h('p', {}, t('components.onboardingService.steps.attachDevice.description3'))
    ]),
    target: function() { return this.ref }
  },
  changeScreenName: {
    placement: 'bottom',
    ref: null,
    onNext: null,
    onPrev: null,
    loadable: false,
    onRefresh: () => {
      current.value = stepsMap['groupsSidebar']
    },
    requiredRef: true,
    skipToOnBack: 'addDevice',
    title: t('components.onboardingService.steps.changeScreenName.title'),
    description: t('components.onboardingService.steps.changeScreenName.description'),
    target: function() { return this.ref }
  },
  groupsSidebar: {
    placement: 'right',
    ref: null,
    onNext: null,
    onPrev: null,
    onRefresh: () => {},
    loadable: false,
    requiredRef: function () { return this.ref},
    skipToOnBack: 'addDevice',
    title: t('components.onboardingService.steps.groupsSidebar.title'),
    description: t('components.onboardingService.steps.groupsSidebar.description'),
    target: function() { return this.ref }
  },
  playlistZone: {
    placement: 'right',
    ref: null,
    onNext: null,
    onPrev: null,
    loadable: false,
    onRefresh: async () => {
      return router.replace({ name: 'PlaylistPage', params: { playlistId: currentGroupDefaultPlaylistId.value } })
    },
    title: t('components.onboardingService.steps.playlistZone.title'),
    description: t('components.onboardingService.steps.playlistZone.description'),
    target: function() { return this.ref }
  },
  worldClock: {
    placement: 'left',
    ref: null,
    onNext: null,
    onPrev: null,
    loadable: false,
    onRefresh: async () => {
      return router.replace({ name: 'PlaylistPage', params: { playlistId: currentGroupDefaultPlaylistId.value } })
    },
    title: t('components.onboardingService.steps.worldClock.title'),
    description: t('components.onboardingService.steps.worldClock.description'),
    target: function() { return this.ref }
  },
  weatherForecast: {
    placement: 'left',
    ref: null,
    onNext: null,
    onPrev: null,
    loadable: false,
    onRefresh: async () => {
      return router.replace({ name: 'PlaylistPage', params: { playlistId: currentGroupDefaultPlaylistId.value } })
    },
    title: t('components.onboardingService.steps.weatherForecast.title'),
    description: t('components.onboardingService.steps.weatherForecast.description'),
    target: function() { return this.ref }
  },
  finalStep: {
    placement: 'bottom',
    ref: null,
    onNext: null,
    onPrev: null,
    loadable: true,
    onRefresh: () => {},
    title: t('components.onboardingService.steps.finalStep.title'),
    description: h('div', {}, [
      h(Text, {}, t('components.onboardingService.steps.finalStep.description')),
      h('ul', { style: 'padding: 12px;' }, [
        h('li', {},
            h('a', { href: 'https://intercom.help/kitcast/en', target: '_blank' },
                t('components.onboardingService.steps.finalStep.howToSchedule')
            )
        ),
        h('li', {},
            h('a', { href: 'https://intercom.help/kitcast/en', target: '_blank' },
                t('components.onboardingService.steps.finalStep.howToInvite')
            )
        )
      ])
    ]),
    target: function() { return this.ref }
  }
})

const steps = computed(() => {
  return Object.keys(stepsState).map(key => {
    return {
      title: stepsState[key].title,
      description: stepsState[key].description,
      target: stepsState[key].target()
    }
  })
})

const placement = computed(() => {
  return stepsState[stepsIndexMap[current.value]]?.placement
})

// Map steps to index
const stepsMap = Object.fromEntries(Object.entries(stepsState).map(([key], index) => {
  return [key, index]
}))

// Map index to steps
const stepsIndexMap = Object.fromEntries(Object.entries(stepsMap).map(([key, value]) => [value, key]))

const next = () => {
  if (!open.value) {
    return;
  }
  current.value += 1;
  saveOnboardingStep(current.value)
}

const goTo = (stepName) => {
  if (!open.value) {
    return;
  }
  current.value = stepsMap[stepName]
}

const handleOpen = () => {
  stepsState[stepsIndexMap[current.value - 1]]?.onNext?.()
  setTimeout(()=>{
    open.value = true;
  }, 500)
}

const handleClose = () => {
  gtm.push({ event: 'Onboarding', action: 'click', label: current.value === 8 ? 'finish' : 'skip'})
  finalizeOnboarding()
  open.value = false
}

const saveOnboardingStep = (step) => {
  store.dispatch('auth/updateOnboarding', {onboardingStep: step, onboardingFinished: false})
}

const handleStepChange = async (currentStep) => {
  back.value = previous.value > currentStep
  if (steps.value && currentStep === steps.value?.length - 1) {
    finalizeOnboarding()
  }
  if (!back.value) {
    const skipToOnNext = stepsState[stepsIndexMap[currentStep - 1]]?.skipToOnNext
    stepsState[stepsIndexMap[currentStep - 1]]?.onNext?.()
    if ( skipToOnNext ) {
      current.value = stepsMap[skipToOnNext]
    }
  }
  else {
    const skipToOnBack = stepsState[stepsIndexMap[currentStep + 1]]?.skipToOnBack
    stepsState[stepsIndexMap[currentStep + 1]]?.onPrev?.()
    if ( skipToOnBack ) {
      current.value = stepsMap[skipToOnBack]
    }
  }
  nextTick(()=>{
    saveOnboardingStep(current.value)
  })
  previous.value = current.value
}

const startOnboarding = () => {
  modalOpened.value = false
  open.value = true
}

const handleVideoEnded = () => {
  videoEnded.value = true
}

provide('onboardingService', {
  setRef: (ref, stepName, onNext, onPrev) => {
    if (!stepsState[stepName]) return
    stepsState[stepName].ref = ref
    stepsState[stepName].onNext = onNext
    stepsState[stepName].onPrev = onPrev
  },
  clearRef: (stepName) => {
    if (!stepsState[stepName]) return
    stepsState[stepName].ref = null
  },
  setLoadable: (stepName, loadable) => {
    if (!stepsState[stepName]) return
    stepsState[stepName].loadable = loadable
  },
  proceedFrom: (stepName) => {
    if (onboardingFinished.value) return
    if (!stepsState[stepName] || stepsIndexMap[current.value] !== stepName) return
    open.value = true
    next()
  },
  pause: () => {
    open.value = false
  },
  proceed: () => {
    open.value = true
  },
  next,

  isCurrent: (index) => {
    return current.value === index;
  },
  goTo
})

watchEffect(async ()=> {
  if (!onboardingFinished.value) {
    const state = stepsState[stepsIndexMap[current.value]]
    if (current.value === 0 && !back.value) {
      if (!state.requiredRef || state.requiredRef()) {
        return modalOpened.value = true
        // return handleOpen()
      }
    }
    if (afterRefresh.value) {
      if (!preloadedState.value && !initialized.value) {
        const loadable = state?.loadable
        const onRefresh = state?.onRefresh
        if (loadable && onRefresh) {
          await onRefresh()
          preloadedState.value = true
          return
        }
        return
      }
      if (preloadedState.value && !initialized.value) {
        if (!state.requiredRef || state.requiredRef()) {
          initialized.value = true
          handleOpen()
        }
      }
    }
  }
})

watchEffect(async ()=> {
  if (!onboardingFinished.value) {
    gtm.push({ event: 'Onboarding', action: 'show', label: `step${current.value}`})
  }
})


</script>

<template>
  <a-tour
    v-if="!showEmailConfirmedModal && userIsComplete && !onboardingFinished"
    v-model:current="current"
    :placement="placement"
    :open="open"
    :steps="steps"
    @close="handleClose"
    @change="handleStepChange"
  >
    <template #indicatorsRender="{current: currentStep, total}">
      <div>
        <a-typography-link
          v-if="currentStep + 1 !== total.value"
          type="secondary"
          style="z-index: 1; position: relative;"
          @click="handleClose"
        >
          <small>
            {{ $t('components.onboardingService.tourSkipText') }}
          </small>
        </a-typography-link>
        <div
          style="z-index: 0;position: absolute; left: 0; right: 0; width: 100%; top: 0; height: 100%; display: flex; justify-content: center; align-items: center"
        >
          <div
            class="ant-tour-indicators"
            style="margin-top: -8px;"
          >
            <span
              v-for="i in total.value"
              :key="i"
              class="ant-tour-indicator"
              :class="{'ant-tour-indicator-active': i - 1 === currentStep}"
            />
          </div>
        </div>
      </div>
    </template>
  </a-tour>
  <a-modal
    :open="!showEmailConfirmedModal && userIsComplete && modalOpened"
    width="768px"
    :closable="false"
  >
    <div style="text-align: center;">
      <div style="aspect-ratio: 16/9; margin-bottom: 16px;">
        <OnboardingVideo
          v-if="modalOpened"
          @ended="handleVideoEnded"
        />
      </div>
      <a-typography-title
        :level="4"
      >
        {{ $t('components.onboardingService.modalTitle') }}
      </a-typography-title>
      <a-typography-paragraph>
        {{ $t('components.onboardingService.modalSubtitle') }}
      </a-typography-paragraph>
    </div>
    <template #footer>
      <a-button
        :type="videoEnded ? 'primary' : 'default'"
        @click="startOnboarding"
      >
        {{ videoEnded ? $t('components.onboardingService.modalNextButtonText') : $t('components.onboardingService.modalSkipButtonText') }}
      </a-button>
    </template>
  </a-modal>
  <slot />
</template>

<style scoped lang="less">

</style>
