<template>
  <div class="facebook-group-editor">
    <MediaPicker
      :selected="assets?.logo"
      @update="onMediaUpdate"
    />
    <TextElement
      v-model:value-model="fieldsState.address"
      :lines="3"
      :title="$t('components.smartTemplateConstructor.facebookGroupEditor.groupInputTitle')"
    />
  </div>
</template>
<script>
import { computed, defineComponent, reactive, ref, watchEffect } from 'vue'
import MediaPicker from '@/components/smartTemplateConstructor/generalElements/MediaPicker'
import TextElement from '@/components/smartTemplateConstructor/segmentElements/TextElement'

export default defineComponent({
  name: 'FacebookGroupEditor',
  components: {
    TextElement,
    MediaPicker
  },
  props: {
    data: Object,
    assets: Object
  },
  emits: ['validChange'],
  setup (props, { emit }) {
    const fieldsState = reactive(props.data)

    const isValid = computed(() => !!(fieldsState.address
        && fieldsState.logoMediaImageReference))

    const assets = ref(props.assets)

    const onMediaUpdate = (media) => {
      fieldsState.logoMediaImageReference = media.mediaReference
      if (assets.value) {
        assets.value.logo = media
      }
    }

    watchEffect(() => {
      emit('validChange', isValid.value)
    })

    return {
      fieldsState,
      onMediaUpdate
    }
  }
})
</script>

<style scoped>

</style>
