<template>
  <a-modal
    :open="showEmailConfirmedModal"
    :closable="false"
    :footer="null"
    width="768px"
    wrap-class-name="auth-modal"
  >
    <a-row :gutter="[48,16]">
      <a-col
        :span="15"
        class="forms"
      >
        <div>
          <a-result
            style="margin-top: 60px;"
            status="success"
            :title="$t('components.emailValidationHandler.emailValidationSuccessTitle')"
            :sub-title="$t('components.emailValidationHandler.emailValidationSuccessSubtitle')"
          />
          <a-button
            type="primary"
            block
            size="large"
            @click="closeModal"
          >
            {{ $t('components.emailValidationHandler.proceed') }}
          </a-button>
        </div>
      </a-col>
      <a-col
        :span="9"
        class="image-container"
      >
        <img
          src="@/assets/images/kc-login-pic.jpg"
          alt=""
        >
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'EmailValidationHandler',
  props: {
    emailConfirmed: Boolean
  },
  setup (props) {
    const store = useStore()
    const showEmailConfirmedModal = computed(() => store.getters['showEmailConfirmedModal'])

    onMounted(()=>{
      if (props.emailConfirmed) {
        openModal()
      }
    })

    const openModal = () => {
      store.dispatch('openEmailConfirmed')
    }

    const closeModal = () => {
      store.dispatch('closeEmailConfirmed')
    }

    return {
      showEmailConfirmedModal,
      closeModal
    }
  }
})
</script>

<style lang="less">
.notification-message {
  display: flex;
  justify-content: flex-end;
  margin: 8px 32px;
}
</style>
