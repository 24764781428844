<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.liveScore.slideStep1.subTitle')"
    :title="$t('components.slides.liveScore.slideStep1.title')"
    no-border
  />
</template>

<script>
import { defineComponent } from 'vue'
import SlideStep from '@/components/SlideStep'
import { useI18n } from 'vue-i18n'
import { LiveScoreSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/DurationSlideStep.vue'

export default defineComponent({
  name: 'LiveScoreSlide',
  components: { DurationSlideStep, SlideStep },
  props: {
    title: String,
    slideObj: LiveScoreSlide,
    groupId: String,
    disabled: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const slide = props.slideObj || new LiveScoreSlide()

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })

    const updateSlide = () => {
      emit('update-slide', slide)
    }

    if (!slide.widgetId) {
      updateSlide()
    }

    return {
      slide,
      updateSlide
    }
  }
})
</script>

<style lang="less">

</style>
