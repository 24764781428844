<template>
  <div class="directory-editor">
    <DirectoryElement
      v-for="(location, $index) in fieldsState.directories"
      :key="'location'+$index"
      :deletable="fieldsState.directories.length > 1"
      :is-only="fieldsState.directories.length === 1"
      :location="location"
      @delete="deleteLocation($index)"
    />
    <a-button
      :disabled="!canAddLocation"
      block
      type="primary"
      @click="addLocation"
    >
      {{ $t('components.smartTemplateConstructor.directoryEditor.addDirectoryButtonText') }}
    </a-button>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, watchEffect } from 'vue'
import DirectoryElement from '@/components/smartTemplateConstructor/segmentElements/DirectoryElement'
import { cloneDeep } from '@apollo/client/utilities'

export default defineComponent({
  name: 'DirectoryEditor',
  components: {
    DirectoryElement
  },
  props: {
    data: Object,
    defaultTemplateData: Object
  },
  emits: ['validChange'],
  setup (props, { emit }) {
    const fieldsState = reactive(props.data)

    const isValid = computed(() => {
      return !!(fieldsState.directories?.every((d) => !!(d.number
          && d.title
          && d.description
      )))
    })

    const canAddLocation = computed(() => props.data?.directories?.length < 4)

    const deleteLocation = (index) => {
      fieldsState.directories = [...fieldsState.directories.slice(0, index), ...fieldsState.directories.slice(index + 1)]
    }

    const addLocation = () => {
      const location = cloneDeep(props.defaultTemplateData?.directories[0])
      fieldsState.directories = [...fieldsState.directories, location]
    }

    watchEffect(() => {
      emit('validChange', isValid.value)
    })

    return {
      fieldsState,
      addLocation,
      deleteLocation,
      canAddLocation
    }
  }
})
</script>

<style lang="less" scoped>
.directory-editor {
}

</style>
