<template>
  <a-modal
    :open="visible"
    :closable="false"
    width="100%"
    destroy-on-close
    wrap-class-name="ai-content-slide-modal"
    :footer="null"
    @cancel="onCancel"
  >
    <a-layout v-if="visible">
      <a-layout-content style="display: flex;flex-direction: column">
        <div
          class="preview"
        >
          <img
            v-if="backgroundType === PHOTO && backgroundPhotoSrc"
            :class="{loading: backgroundImageIsLoading}"
            :src="backgroundPhotoSrc"
          >
          <video
            v-else-if="backgroundType === VIDEO && backgroundVideoSrc"
            :src="backgroundVideoSrc"
            loop
            autoplay="true"
            muted="muted"
          />
          <div
            v-if="generatedText"
            class="text-wrapper"
            :class="textPositionClass"
          >
            <div
              class="text"
              :style="{color: textFontColor}"
            >
              <div
                class="text-background"
              />

              <div class="text-inner">
                <span class="title">{{ generatedTextTitle }}</span>
                <span class="description">{{ generatedTextDescription }}</span>
              </div>
            </div>
          </div>
        </div>
        <a-menu
          mode="horizontal"
          :selected-keys="textPositionSelected"
          class="text-position-select-menu"
        >
          <a-menu-item
            key="LEFT"
            @click="handleTextPositionChange('LEFT')"
          >
            {{ $t('components.aIContentSlideModal.left') }}
          </a-menu-item>
          <a-menu-item
            key="CENTER"
            @click="handleTextPositionChange('CENTER')"
          >
            {{ $t('components.aIContentSlideModal.center') }}
          </a-menu-item>
          <a-menu-item
            key="RIGHT"
            @click="handleTextPositionChange('RIGHT')"
          >
            {{ $t('components.aIContentSlideModal.right') }}
          </a-menu-item>
        </a-menu>
      </a-layout-content>
      <a-layout-sider
        theme="light"
        width="468"
      >
        <a-layout>
          <a-layout-content style="padding: 20px 16px 16px 16px">
            <a-typography-title :level="5">
              {{ $t('components.aIContentSlideModal.title') }}
            </a-typography-title>
            <a-space
              direction="vertical"
              size="large"
              style="width: 100%"
            >
              <a-menu
                mode="horizontal"
                :selected-keys="backgroundTypeSelected"
                class="background-select-menu"
              >
                <a-menu-item
                  key="PHOTO"
                  @click="handleBackgroundTypeChange(PHOTO)"
                >
                  <template #icon>
                    <FileImageOutlined />
                  </template>
                  {{ $t('components.aIContentSlideModal.photo') }}
                </a-menu-item>
                <a-menu-item
                  key="VIDEO"
                  @click="handleBackgroundTypeChange(VIDEO)"
                >
                  <template #icon>
                    <VideoCameraOutlined />
                  </template>
                  {{ $t('components.aIContentSlideModal.video') }}
                </a-menu-item>
              </a-menu>
              <a-form layout="vertical">
                <div style="margin-bottom: 8px;">
                  {{ $t('components.aIContentSlideModal.backgroundLabel') }}
                  <a-tooltip :title="$t('components.aIContentSlideModal.backgroundTooltipText')">
                    <QuestionCircleOutlined />
                  </a-tooltip>
                </div>
                <a-form-item>
                  <a-input-group
                    compact
                    style="display: flex"
                  >
                    <a-input
                      v-model:value="backgroundPrompt"
                      :maxlength="100"
                      :placeholder="$t('components.aIContentSlideModal.backgroundPlaceholder')"
                      :loading="backgroundImageIsLoading"
                      @keypress.enter="handleBackgroundPromptChange"
                    >
                      <template #suffix>
                        <a-tooltip
                          :title="(!prevBackgroundPrompt || prevBackgroundPrompt !== backgroundPrompt) ? 'Generate' : 'Reload'"
                        >
                          <SendOutlined
                            v-if="!prevBackgroundPrompt || prevBackgroundPrompt !== backgroundPrompt"
                            @click="handleBackgroundPromptChange"
                          />
                          <ReloadOutlined
                            v-else
                            :spin="backgroundImageIsLoading"
                            @click="handleBackgroundPromptChange"
                          />
                        </a-tooltip>
                      </template>
                    </a-input>
                  </a-input-group>
                </a-form-item>
                <div style="margin-bottom: 8px;">
                  {{ $t('components.aIContentSlideModal.textLabel') }}
                  <a-tooltip :title="$t('components.aIContentSlideModal.textTooltipText')">
                    <QuestionCircleOutlined />
                  </a-tooltip>
                </div>
                <a-form-item>
                  <a-input-group
                    compact
                    style="display: flex"
                  >
                    <a-input
                      v-model:value="textPrompt"
                      :maxlength="100"
                      :placeholder="$t('components.aIContentSlideModal.textPlaceholder')"
                      :loading="generatedTextIsLoading"
                      @keypress.enter="handleTextPromptChange"
                    >
                      <template #suffix>
                        <a-tooltip :title="(!prevTextPrompt || prevTextPrompt !==textPrompt) ? 'Generate' : 'Reload'">
                          <SendOutlined
                            v-if="!prevTextPrompt || prevTextPrompt !==textPrompt"
                            @click="handleTextPromptChange"
                          />
                          <ReloadOutlined
                            v-else
                            :spin="generatedTextIsLoading"
                            @click="handleTextPromptChange"
                          />
                        </a-tooltip>
                      </template>
                    </a-input>
                  </a-input-group>
                </a-form-item>
                <a-form-item>
                  <a-textarea
                    v-model:value="generatedText"
                    style="resize: none"
                    show-count
                    :disabled="generatedTextIsLoading"
                    :maxlength="300"
                    rows="5"
                    @change="handleGeneratedTextChange"
                  />
                </a-form-item>
              </a-form>
            </a-space>
          </a-layout-content>
          <a-layout-footer style="text-align: right">
            <a-space>
              <a-button
                @click="onCancel"
              >
                {{ $t('components.aIContentSlideModal.cancelText') }}
              </a-button>
              <a-button
                type="primary"
                :disabled="!generatedText || !(backgroundPhotoSrc || backgroundVideoSrc)"
                @click="onSave"
              >
                {{ $t('components.aIContentSlideModal.okText') }}
              </a-button>
            </a-space>
          </a-layout-footer>
        </a-layout>
      </a-layout-sider>
    </a-layout>
  </a-modal>
</template>

<script>
import { computed, defineComponent, ref, toRaw, watch } from 'vue'
import { useStore } from 'vuex'
import {
  FileImageOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  SendOutlined,
  VideoCameraOutlined
} from '@ant-design/icons-vue'
import { cloneDeep } from '@apollo/client/utilities'
import { error } from '@/utils'
import { AIContentSlide } from '@/helpers/Slides'

const PHOTO = 'PHOTO'
const VIDEO = 'VIDEO'

export default defineComponent({
  name: 'AIContentSlideModal',
  components: {
    ReloadOutlined,
    FileImageOutlined,
    VideoCameraOutlined,
    SendOutlined,
    QuestionCircleOutlined
  },
  props: {
    visible: Boolean,
    slideType: String,
    slideObj: AIContentSlide
  },
  emits: ['update', 'update:visible', 'close'],
  setup (props, { emit }) {
    const store = useStore()
    const isNewSlide = ref(!props.slideObj)
    const showMediaSelectModal = ref(isNewSlide.value)
    let slide = props.slideObj || new AIContentSlide()
    const pexelsPhotosObject = ref(null)
    const pexelsVideosObject = ref(null)
    const textInfo = ref(cloneDeep(slide.slideData.text))
    const textPrompt = ref(slide.slideData.openaiPrompt || '')
    const prevTextPrompt = ref(slide.slideData.openaiPrompt || '')
    const backgroundPrompt = ref(slide.slideData.pexelsQuery || '')
    const prevBackgroundPrompt = ref(slide.slideData.pexelsQuery || '')
    const backgroundPhoto = ref(slide.pexelsPhoto?.message ? null : slide.pexelsPhoto || null)
    const backgroundVideo = ref(slide.pexelsVideo?.message ? null : slide.pexelsVideo || null)
    const backgroundType = ref(backgroundPhoto.value ? PHOTO : VIDEO)
    const backgroundImageIsLoading = ref(false)
    const generatedText = ref(textInfo.value?.value)
    const generatedTextIsLoading = ref(false)
    const textVerticalPosition = computed(() => textInfo.value?.position?.vertical || 'CENTER')
    const textHorizontalPosition = computed(() => textInfo.value?.position?.horizontal || 'CENTER')
    const textBackgroundColor = computed(() => textInfo.value?.background?.color)
    const textFontColor = computed(() => textInfo.value?.font?.color)
    const backgroundTypeSelected = computed(() => [backgroundType.value])
    const textPositionSelected = computed(() => [textHorizontalPosition.value])

    const generatedTextTitle = computed(() => {
      return generatedText.value?.split('\n')?.[0]
    })

    const generatedTextDescription = computed(() => {
      return generatedText.value?.split('\n')?.splice(1).filter(Boolean).join('\n')
    })

    const textPositionClass = computed(() => {
      let textClasses = []
      if (textVerticalPosition.value === 'TOP') {
        textClasses.push('top')
      } else if (textVerticalPosition.value === 'BOTTOM') {
        textClasses.push('bottom')
      }
      if (textHorizontalPosition.value === 'LEFT') {
        textClasses.push('left')
      } else if (textHorizontalPosition.value === 'RIGHT') {
        textClasses.push('right')
      }
      return textClasses.join(' ')
    })

    const backgroundPhotoSrc = computed(() => {
      return backgroundPhoto.value?.src?.large
    })

    const backgroundVideoSrc = computed(() => {
      const videoFile = backgroundVideo.value?.video_files?.find(f => f.quality === 'sd')
      return videoFile?.link
    })

    const updateSlide = () => {
      textInfo.value.value = generatedText.value

      const url = backgroundType.value === VIDEO
          ? backgroundVideo.value?.video_files?.find(f => f.quality === 'hd')?.link
          : backgroundType.value === PHOTO
              ? backgroundPhoto.value?.src?.original
              : null
      slide.setSlideData({
        text: toRaw(textInfo.value),
        openaiPrompt: textPrompt.value,
        pexelsQuery: backgroundPrompt.value,
        pexelsPhotoId: backgroundType.value === PHOTO ? backgroundPhoto.value?.id || null : null,
        pexelsVideoId: backgroundType.value === VIDEO ? backgroundVideo.value?.id || null : null,
        mediaReference: {
          sourceType: 'EXTERNAL_URL',
          url
        }
      })
      slide.setPexelsVideo(toRaw(backgroundVideo.value))
      slide.setPexelsPhoto(toRaw(backgroundPhoto.value))
    }

    const handleBackgroundPromptChange = () => {
      if (!backgroundPrompt.value || backgroundImageIsLoading.value) return
      if (backgroundType.value === PHOTO) {
        backgroundImageIsLoading.value = true
        store.dispatch('slides/searchPexelPhotos', backgroundPrompt.value).then(data => {
          prevBackgroundPrompt.value = backgroundPrompt.value
          pexelsPhotosObject.value = data
          setRandomPhotoBackgroundFromPexelObj()
          backgroundVideo.value = null
        }).then(() => {
          backgroundImageIsLoading.value = false
        })
      } else if (backgroundType.value === VIDEO) {
        backgroundImageIsLoading.value = true
        store.dispatch('slides/searchPexelVideos', backgroundPrompt.value).then(data => {
          prevBackgroundPrompt.value = backgroundPrompt.value
          pexelsVideosObject.value = data
          setRandomVideoBackgroundFromPexelObj()
          backgroundPhoto.value = null
        }).then(() => {
          backgroundImageIsLoading.value = false
        })
      }
    }

    const setRandomPhotoBackgroundFromPexelObj = () => {
      backgroundPhoto.value = pexelsPhotosObject.value?.photos[Math.floor(Math.random() * pexelsPhotosObject.value?.photos?.length)]

    }

    const setRandomVideoBackgroundFromPexelObj = () => {
      backgroundVideo.value = pexelsVideosObject.value?.videos[Math.floor(Math.random() * pexelsVideosObject.value?.videos?.length)]
    }

    const handleGeneratedTextChange = () => {
      // updateSlide()
    }

    const handleTextPositionChange = (position) => {
      textInfo.value.position.horizontal = position
    }

    const handleTextPromptChange = () => {
      if (!textPrompt.value || generatedTextIsLoading.value) return
      generatedTextIsLoading.value = true
      store.dispatch('slides/getOpenAICompletion', textPrompt.value).then(data => {
        prevTextPrompt.value = textPrompt.value
        generatedText.value = data.trim()
        generatedTextIsLoading.value = true
      }).catch(() => {
        error('Rate limit exceeded')
      }).then(() => {
        generatedTextIsLoading.value = false
      })
    }

    const mapPropsToState = () => {
      slide = props.slideObj ? props.slideObj : new AIContentSlide()
      pexelsPhotosObject.value = null
      pexelsVideosObject.value = null
      textInfo.value = cloneDeep(slide.slideData.text)
      textPrompt.value = slide.slideData.openaiPrompt || ''
      prevTextPrompt.value = slide.slideData.openaiPrompt || ''
      backgroundPrompt.value = slide.slideData.pexelsQuery || ''
      prevBackgroundPrompt.value = slide.slideData.pexelsQuery || ''
      backgroundPhoto.value = slide.pexelsPhoto?.message ? null : slide.pexelsPhoto || null
      backgroundVideo.value = slide.pexelsVideo?.message ? null : slide.pexelsVideo || null
      backgroundType.value = backgroundPhoto.value ? PHOTO : VIDEO
      backgroundImageIsLoading.value = false
      generatedText.value = textInfo.value?.value
      generatedTextIsLoading.value = false
    }

    const onCancel = () => {
      mapPropsToState()
      emit('update:visible', false)
      emit('close')
    }

    const onSave = () => {
      updateSlide()
      slide.setWaitingForUpdate(true)
      emit('update', slide.getDto())
      emit('update:visible', false)
    }

    const handleBackgroundTypeChange = (type) => {
      if (type === backgroundType.value) return
      backgroundType.value = type
      setTimeout(handleBackgroundPromptChange, 50)
    }

    watch(() => props.visible, (value) => {
      if (value) {
        mapPropsToState()
      }
    })

    return {
      textInfo,
      backgroundImageIsLoading,
      generatedTextIsLoading,
      backgroundPrompt,
      backgroundPhotoSrc,
      backgroundVideoSrc,
      backgroundType,
      pexelsPhotosObject,
      generatedText,
      textPrompt,
      showMediaSelectModal,
      textPositionClass,
      textBackgroundColor,
      textFontColor,
      backgroundTypeSelected,
      textPositionSelected,
      generatedTextTitle,
      generatedTextDescription,
      prevTextPrompt,
      prevBackgroundPrompt,
      PHOTO,
      VIDEO,
      onCancel,
      onSave,
      handleBackgroundPromptChange,
      handleGeneratedTextChange,
      handleBackgroundTypeChange,
      handleTextPositionChange,
      handleTextPromptChange,
    }
  }
})
</script>

<style lang="less">
@import '@/styles/variables.less';

.ai-content-slide-modal {
  .ant-modal {
    max-width: 100%;
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }

  .ant-modal-content {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: calc(100vh);
  }

  .ant-modal-body {
    flex: 1;
    padding: 0;
    display: flex;
    overflow: hidden;

    > .ant-layout {
      flex: 1;

      > .ant-layout-content {
        background-color: #E2E2E2;
        display: flex;
        align-items: center;
        justify-content: center;

        .preview {
          background: @menu-dark-submenu-bg;
          box-shadow: 0px 14px 40px -18px rgba(0, 0, 0, 0.75);
          width: 660px;
          aspect-ratio: 16/9;
          position: relative;
          font-size: 18.9px;
          overflow: hidden;
          border-radius: 8px;

          .text-wrapper {
            top: 0;
            left: 0;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1.2em;
            width: 100%;
            height: 100%;
            transition: all .2s ease;

            &:not(.left):not(.right) {
              .text {
                .text-inner {
                  .title {
                    text-align: center;
                  }
                }
              }
            }

            &.left {
              justify-content: flex-start;
            }

            &.right {
              justify-content: flex-end;
            }

            &.top {
              align-items: flex-start;
            }

            &.bottom {
              align-items: flex-end;
            }

            .text {
              font-family: 'Roboto', sans-serif;
              color: #fff;
              height: 100%;
              padding: 1.2em;
              width: 17em;
              transition: all .2s ease;
              position: relative;
              border-radius: 8px;
              overflow: hidden;
              backdrop-filter: blur(10px);

              .text-inner {
                z-index: 1;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: 100%;

                .title {
                  color: #fff;
                }

                .description {
                  font-size: 75%;
                  color: rgba(255, 255, 255, 0.7);
                }
              }

              .text-background {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.8);
                border-radius: 8px;
                overflow: hidden;
              }
            }
          }

          img {
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
          }

          video {
            display: block;
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            height: 100%;
          }
        }

        .text-position-select-menu {
          margin-top: 16px;
          background-color: transparent;
          border-bottom: 0;
          width: 300px;
          display: flex;
          align-items: center;
          justify-content: center;
          .ant-menu-item {
            color: rgba(42, 48, 55, 0.45);
            border-bottom-color: transparent;

            &:hover, &.ant-menu-item-selected {
              color: #2A3037;

              &::after {
                border-bottom-color: #2A3037;
              }
            }
          }
        }
      }

      > .ant-layout-sider {
        > .ant-layout-sider-children {
          display: flex;
          flex-direction: column;
          .ant-menu-light {
            background-color: transparent;
          }
          .background-select-menu {
            margin-top: 8px;
            border: 0;

            .ant-menu-item {
              padding-left: 0;

              &::after {
                left: 0;
              }
            }
          }

          .ant-layout-footer {
            background-color: transparent;
            padding: 16px;
            border-top: 1px solid rgba(0, 0, 0, .06);
          }
        }
      }
    }
  }
}
</style>
