<template>
  <div class="slide-types">
    <SlideView
      v-for="viewItem in data"
      :key="viewItem.view"
      :type="viewItem.view"
      :text="viewItem.text"
      :active="view === viewItem.view"
      :image="viewItem.image"
      :disabled="viewItem.disabled"
      centered
      @changeType="updateView"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SlideView from '@/components/SlideView'

export default defineComponent({
  name: 'SlideViewSelect',
  components: { SlideView },
  props: {
    view: String,
    data: Array,
    centered: Boolean
  },
  emits: ['update:view'],
  setup (props, { emit }) {
    const updateView = (view) => {
      emit('update:view', view)
    }
    return {
      updateView
    }
  }
})
</script>

<style lang="less">
.slide-types {
  display: flex;
  gap: 8px;
  .slide-type {
    flex: 1;
    cursor: pointer;
    &:hover:not([disabled="true"]) {
      text-decoration: underline;
    }
    &[disabled="true"] {
      cursor: default;
      pointer-events: none;
      > figure {
        opacity: .3;
        .icon-container {
          opacity: 1;
        }
      }
    }
    &.active {
      > figure {
        .icon-container {
          background-color: #000;
        }
      }
    }
    width: 200px;
    >figure {
      .icon-container {
        opacity: .5;
        aspect-ratio: 16/9;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px #f0f0f0;
        border-radius: 3px;
        background-color: #fff;
        margin-bottom: 8px;
        transition: background-color .3s ease;
        svg {
          transition: fill .3s ease;
        }
      }
    }
  }
}
</style>
