import ApolloClient from '@/apollo'
import {
  GET_COMPOSER_JSON, GET_DEFAULT_COMPOSER_SLIDES,
  GET_OPEN_AI_COMPLETION,
  GET_TED_TALKS_SPEAKERS,
  GET_TED_TALKS_TOPICS,
  GET_YOUTUBE_VIDEO_INFO,
  GOOGLE_PLACE_AUTOCOMPLETE,
  LIST_TASTY_TAGS,
  SEARCH_PEXELS_PHOTOS,
  SEARCH_PEXELS_VIDEOS,
  SEARCH_TED_TALKS,
  SEARCH_TICKERS,
  TRIP_ADVISOR_LOCATION_SEARCH
} from '@/graphql/queries'
import { loadJson } from '@/utils'
import { handleError } from '@/helpers/ErrorHandler'

export default {
  namespaced: true,
  state: {
    tedTalksSpeakers: [],
    tedTalksTopics: [],
    tastyTags: [],
    draggingSlideFromZone: null,
    defaultCustomTemplates: [],
    defaultCustomTemplatesLoaded: false
  },
  actions: {
    async getOpenAICompletion ({ commit }, prompt) {
      try {
        const { data: { createOpenAICompletion }} = await ApolloClient.query({ query: GET_OPEN_AI_COMPLETION, variables: { prompt }, fetchPolicy: 'no-cache'  })
        return createOpenAICompletion
      } catch (e) {
        handleError(e)
      }
    },
    async searchPexelPhotos ({ commit }, query) {
      try {
        const { data: { searchPexelsPhotos }} = await ApolloClient.query({ query: SEARCH_PEXELS_PHOTOS, variables: { query } })
        return searchPexelsPhotos
      } catch (e) {
        handleError(e)
      }
    },
    async searchPexelVideos ({ commit }, query) {
      try {
        const { data: { searchPexelsVideos }} = await ApolloClient.query({ query: SEARCH_PEXELS_VIDEOS, variables: { query } })
        return searchPexelsVideos
      } catch (e) {
        handleError(e)
      }
    },
    async getYoutubeVideoInfo ({ commit }, videoId) {
      try {
        const { data: { getYoutubeVideoInfo }} = await ApolloClient.query({ query: GET_YOUTUBE_VIDEO_INFO, variables: { videoId } })
        return getYoutubeVideoInfo
      } catch (e) {
        return null
      }
    },
    async searchTickers ({ commit }, query) {
      try {
        const { data: { searchTickers }} = await ApolloClient.query({ query: SEARCH_TICKERS, variables: query })
        return searchTickers
      } catch (e) {
        handleError(e)
      }
    },
    async getTedTalksTopics ({ commit }) {
      try {
        const { data: { getTedTalksTopics }} = await ApolloClient.query({ query: GET_TED_TALKS_TOPICS })
        commit('SET_TED_TALKS_TOPICS', getTedTalksTopics)
      } catch (e) {
        handleError(e)
      }
    },
    async getTedTalksSpeakers ({ commit }) {
      try {
        const { data: { getTedTalksSpeakers }} = await ApolloClient.query({ query: GET_TED_TALKS_SPEAKERS })
        commit('SET_TED_TALKS_SPEAKERS', getTedTalksSpeakers)
      } catch (e) {
        handleError(e)
      }
    },

    async searchTedTalks ({ commit }, { speaker, topic }) {
      try {
        const { data: { searchTedTalks }} = await ApolloClient.query({ query: SEARCH_TED_TALKS, variables: { speaker, topic } })
        return searchTedTalks
      } catch (e) {
        handleError(e)
      }
    },
    async getTastyTags ({ commit }) {
      try {
        const { data: { listTastyTags }} = await ApolloClient.query({ query: LIST_TASTY_TAGS })
        commit('SET_TASTY_TAGS', listTastyTags)
      } catch (e) {
        handleError(e)
      }
    },
    async fetchComposerJson ({ commit }, widgetId) {
      try {
        const { data: { getWidgetById: { jsonMedia: { url } } }} = await ApolloClient.query({ query: GET_COMPOSER_JSON, variables: { id: widgetId }, fetchPolicy: 'no-cache' })
        return await loadJson(url)
      } catch (e) {
        handleError(e)
      }
    },
    async fetchDefaultComposerSlides ({ commit }) {
      try {
        const { data: { getPreCreatedSlideImageComposers } } = await ApolloClient.query({ query: GET_DEFAULT_COMPOSER_SLIDES })
        commit('SET_DEFAULT_CUSTOM_TEMPLATES', getPreCreatedSlideImageComposers)
      } catch (e) {
        return []
      }
    },
    async getGooglePlaceAutocomplete ({ commit }, { query }) {
      try {
        const { data: { googlePlaceAutocomplete }} = await ApolloClient.query({ query: GOOGLE_PLACE_AUTOCOMPLETE, variables: { query } })
        return googlePlaceAutocomplete
      } catch (e) {
        handleError(e)
      }
    },
    async searchTripAdvisorLocation ({ commit }, { searchQuery }) {
      try {
        const { data: { tripAdvisorLocationSearch }} = await ApolloClient.query({ query: TRIP_ADVISOR_LOCATION_SEARCH, variables: { searchQuery } })
        return tripAdvisorLocationSearch
      } catch (e) {
        handleError(e)
      }
    },
    async setDraggingSlideZone ({ commit }, zoneKey) {
      commit('SET_DRAGGING_SLIDE_FROM_ZONE', zoneKey)
    }
  },
  getters: {
    tedTalksSpeakers: state => state.tedTalksSpeakers,
    tedTalksTopics: state => state.tedTalksTopics,
    tastyTags: state => state.tastyTags,
    draggingSlideFromZone: state => state.draggingSlideFromZone,
    defaultCustomTemplates: state => state.defaultCustomTemplates,
    defaultCustomTemplatesLoaded: state => state.defaultCustomTemplatesLoaded
  },
  mutations: {
    SET_DRAGGING_SLIDE_FROM_ZONE (state, zoneKey) {
      state.draggingSlideFromZone = zoneKey
    },
    SET_TASTY_TAGS (state, tastyTags) {
      state.tastyTags = tastyTags
    },
    SET_DEFAULT_CUSTOM_TEMPLATES (state, templates) {
      state.defaultCustomTemplates = templates
      state.defaultCustomTemplatesLoaded = true
    },
    SET_TED_TALKS_SPEAKERS (state, tedTalksSpeakers) {
      state.tedTalksSpeakers = tedTalksSpeakers
    },
    SET_TED_TALKS_TOPICS (state, tedTalksTopics) {
      state.tedTalksTopics = tedTalksTopics
    }
  }
}
