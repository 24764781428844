<template>
  <div>
    <a-menu
      class="custom-sidebar-menu no-border"
      :selected-keys="selectedKeys"
      theme="dark"
      :inline-collapsed="false"
    >
      <a-menu-item
        v-for="menuItem in customMenuItems"
        :key="menuItem.key"
        @click="openGlobalSettings(menuItem.key)"
      >
        <template
          #icon
        >
          <component :is="menuItem.icon" />
        </template>
        {{ $t(`components.globalSettingsModal.${menuItem.key}`) }}
      </a-menu-item>
      <a-popover
        placement="right"
        :overlay-style="{'width': '260px'}"
        destroy-tooltip-on-hide
      >
        <template #content>
          <a-space direction="vertical">
            <a-checkbox-group
              v-model:value="customMenuItemsState"
              style="width: 100%"
            >
              <div
                v-for="option in menuItemsList"
                :key="option.key"
                style="width: 100%"
              >
                <a-checkbox
                  :value="option.key"
                >
                  {{ $t(`components.globalSettingsModal.${option.key}`) }}
                </a-checkbox>
              </div>
            </a-checkbox-group>
            <div style="text-align: center;">
              <a-typography-link
                type="link"
                :disabled="loading"
                @click.stop.prevent="saveChanges"
              >
                {{ $t('components.rootSidebarCustomMenu.saveLinkText') }}
              </a-typography-link>
            </div>
          </a-space>
        </template>
        <a-menu-item
          key="add"
          title=""
        >
          <template
            #icon
          >
            <PlusCircleOutlined />
          </template>
        </a-menu-item>
      </a-popover>
    </a-menu>
  </div>
</template>

<script>
import { computed, defineComponent, ref, watchEffect } from 'vue'
import { NotificationOutlined, PlusCircleOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { WORKSPACE_MENU_ITEMS } from '@/constants'
import { WORKSPACE_MENU_ICONS } from '@/helpers/Icons'
import { error, success } from '@/utils'

export default defineComponent({
  name: 'RootSidebarCustomMenu',
  components: {
    ...WORKSPACE_MENU_ICONS,
    NotificationOutlined,
    PlusCircleOutlined
  },
  setup () {
    const store = useStore()
    const whitelabelEnabled = computed(() => store.getters['workspace/whitelabelEnabled'])
    const externalApiEnabled = computed(() => store.getters['workspace/externalApiEnabled'])
    const customMenuItemsKeys = computed(() => store.getters['workspace/menuItems'])
    const customMenuItemsKeysFiltered = computed(() => filterFeaturesFromKeys(customMenuItemsKeys.value))
    const selectedKeys = computed(() => [store.getters.globalSettingsActiveTabOpened])
    const loading = ref(false)

    const filterFeaturesFromMenu = (menuItems) => {
      if (!whitelabelEnabled.value) {
        return menuItems.filter(i => i.key !== 'customBranding')
      }
      if (!externalApiEnabled.value) {
        return menuItems.filter(i => i.key !== 'apiKeys')
      }
      return menuItems
    }

    const filterFeaturesFromKeys = (menuKeys) => {
      if (!whitelabelEnabled.value) {
        return menuKeys.filter(key => key !== 'customBranding')
      }
      if (!externalApiEnabled.value) {
        return menuKeys.filter(key => key !== 'apiKeys')
      }
      return menuKeys
    }

    const customMenuItemsState = ref([])
    const menuItemsList = computed(() => filterFeaturesFromMenu(WORKSPACE_MENU_ITEMS))
    const customMenuItems = computed(()=>{
      return customMenuItemsKeys.value?.map((key)=> menuItemsList.value?.find(item => key === item.key))?.filter(Boolean)
    })

    const openGlobalSettings = (tab) => {
      store.dispatch('openGlobalSettings', tab)
    }

    const saveChanges = async () => {
      loading.value = true
      store.dispatch('workspace/updateWorkspace', { appearance: { menuItems: [...customMenuItemsState.value] } }).then(() => {
        success()
        window.document.body.click()
      }).catch((e) => {
        error(e.message)
      }).then(() => {
        loading.value = false
      })
    }


    watchEffect(()=>{
      customMenuItemsState.value = [...customMenuItemsKeysFiltered.value]
    })


    return {
      loading,
      selectedKeys,
      customMenuItemsState,
      menuItemsList,
      customMenuItems,
      saveChanges,
      openGlobalSettings
    }
  }
})
</script>

<style scoped lang="less">
@import "../styles/variables.less";

.custom-sidebar-menu {
  span{
    color: @text-color-secondary-dark;
    &:hover {
      color: @text-color-secondary;
    }
  }
}

</style>
