<template>
  <div v-if="!show2Fa">
    <AuthHeading
      :title="$t('components.loginForm.welcome')"
      :subtitle="$t('components.loginForm.intro')"
    />

    <a-spin :spinning="redirectingToV1">
      <a-form
        ref="formRef"
        layout="vertical"
        :model="formState"
        style="margin-top: 32px;"
        :rules="rules"
        @submit.prevent="login"
      >
        <a-form-item
          :label="$t('components.loginForm.emailLabel')"
          name="email"
        >
          <a-input
            v-model:value="formState.email"
            placeholder="name@company.com"
          />
        </a-form-item>
        <a-form-item
          :label="$t('components.loginForm.passwordLabel')"
          name="password"
        >
          <a-input
            v-model:value="formState.password"
            :placeholder="$t('components.loginForm.passwordPlaceholder')"
            type="password"
          />
        </a-form-item>
        <router-link :to="{name: 'ForgotPassword'}">
          <a-typography-link>
            {{ $t('components.loginForm.forgotPasswordLink') }}
          </a-typography-link>
        </router-link>
        <a-form-item style="margin-bottom: 16px; margin-top: 16px;">
          <a-button
            type="primary"
            block
            html-type="submit"
            size="large"
            :loading="loading"
          >
            {{ $t('components.loginForm.loginButtonText') }}
          </a-button>
        </a-form-item>
      </a-form>
      <div class="form-footer">
        <div><a-divider>{{ $t('components.loginForm.or') }}</a-divider></div>
        <a-row
          :gutter="[8, 8]"
          type="flex"
          justify="center"
        >
          <a-col
            :span="8"
          >
            <a-button
              size="large"
              block
              :disabled="loggingInWithSSO || loading"
              @click="openLoginWithGoogleWindow"
            >
              <template #icon>
                <GoogleOutlined />
              </template>
              Google
            </a-button>
          </a-col>
          <a-col
            :span="8"
          >
            <a-button
              size="large"
              block
              :disabled="loggingInWithSSO || loading"
              @click="openLoginWithMicrosoftWindow"
            >
              <template #icon>
                <WindowsOutlined />
              </template>
              Microsoft
            </a-button>
          </a-col>
          <a-col :span="8">
            <a-button
              size="large"
              block
              :disabled="loading"
              :loading="loggingInWithSSO"
              @click="handleSSOLogin"
            >
              {{ $t('components.loginForm.loginWithSSOButtonText') }}
            </a-button>
          </a-col>
        </a-row>
        <div class="spacer" />
        <p style="text-align: center; margin-top: 32px; margin-bottom: 0;">
          {{ $t('components.loginForm.noAccount') }}
          <router-link :to="{name: 'Signup'}">
            <a-typography-link>
              {{ $t('components.loginForm.signUpLink') }}
            </a-typography-link>
          </router-link>
        </p>
        <p style="text-align: center;">
          <small>
            <i> {{ $t('components.loginForm.noCreditCard') }} </i>
          </small>
        </p>
      </div>
    </a-spin>
  </div>
  <div v-else>
    <AuthHeading
      :title="$t('components.loginForm.2FaTitle')"
      :subtitle="$t('components.loginForm.2FaSubtitle')"
    />
    <a-form
      ref="2FaFormRef"
      layout="vertical"
      style="margin-top: 32px;"
      @submit.prevent="loginWith2Fa"
    >
      <a-form-item
        :label="$t('components.loginForm.codeLabel')"
        name="code"
      >
        <a-input
          v-model:value="code2Fa"
          :placeholder="$t('components.loginForm.codePlaceholder')"
          maxlength="6"
          type="text"
        />
      </a-form-item>
      <a-space>
        <a-button
          type="primary"
          html-type="submit"
          :loading="loading"
        >
          Login
        </a-button>
        <a-button
          :disabled="loading"
          @click="handleCancel2Fa"
        >
          Cancel
        </a-button>
      </a-space>
    </a-form>
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref, toRaw, inject } from 'vue'
import { error } from '@/utils'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { openLoginWithGoogleWindow } from '@/helpers/Google'
import { useI18n } from 'vue-i18n'
import { openSocialLoginWindow } from '@/helpers/SocialAccount'
import AuthHeading from '@/components/AuthHeading.vue'
import { GoogleOutlined, WindowsOutlined } from '@ant-design/icons-vue'
import { openLoginWithMicrosoftWindow } from '@/helpers/Microsoft'

export default defineComponent({
  name: 'LoginForm',
  components: {
    GoogleOutlined,
    WindowsOutlined, AuthHeading },
  setup () {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { t } = useI18n()
    const gtm = inject('gtm')
    const formRef = ref()
    const loading = computed(() => store.getters['auth/loggingIn'])
    const userId = computed(() => store.getters['auth/userId'])
    const redirectingToV1 = computed(() => store.getters['auth/redirectingToV1'])
    const loggingInWithSSO = ref(false)
    const workspaceDomain = computed(()=>store.getters['auth/workspaceDomain'])
    const show2Fa = ref(false)
    const code2Fa = ref('')
    const preAuthToken = ref('')
    const formState = reactive({
      email: route.query?.email || '',
      password: ''
    })
    const rules = computed(()=>{
      return {
        email: [{
          required: true,
          type: 'email',
          trigger: 'blur',
          message: t('components.loginForm.emailInvalidError')
        }],
        password: [{
          required: true,
          trigger: 'blur',
          message: t('components.loginForm.passwordInvalidError')
        }]
      }
    })

    const handleSSOLogin = () => {
      if (workspaceDomain.value) {
        loggingInWithSSO.value = true
        return openSocialLoginWindow(process.env.VUE_APP_SSO_OIDC_LINK + '?whitelabel=' + workspaceDomain.value, 'oidc', 'login')
      }
      else {
        router.push({name: 'SSOLogin'})
      }
    }

    const reset2FaState = () => {
      show2Fa.value = false
      code2Fa.value = ''
      preAuthToken.value = ''
    }

    const login = async () => {
      setTimeout(async () => {
        try {
          await formRef.value.validate()
        } catch (e) {
          return
        }
        const input = toRaw(formState)
        input.email = input.email?.toLowerCase()
        await store.dispatch('auth/login', input)
          .then((extensions) => {
            if (extensions?.requires2FA) {
              preAuthToken.value = extensions?.preAuthToken
              show2Fa.value = true
              return
            }
            gtm.push({ event: 'Login', action: 'success', label: 'success', userId:userId.value })
            router.push({ name: 'Home' })
          }).catch((e) => {
            error(e.message)
          })
      })
    }

    const loginWith2Fa = async () => {
      if (code2Fa.value?.length < 6) return
      await store.dispatch('auth/loginWith2Fa', { preAuthToken: preAuthToken.value, twoFactorCode: code2Fa.value })
        .then(() => {
          gtm.push({ event: 'Login', action: 'success', label: 'success', userId:userId.value })
          router.push({ name: 'Home' })
        }).catch((e) => {
          error('Code is incorrect')
        })
    }

    const handleCancel2Fa = () => {
      reset2FaState()
    }


    return {
      formState,
      loading,
      rules,
      formRef,
      loggingInWithSSO,
      show2Fa,
      code2Fa,
      redirectingToV1,
      login,
      handleSSOLogin,
      handleCancel2Fa,
      loginWith2Fa,
      openLoginWithGoogleWindow,
      openLoginWithMicrosoftWindow
    }
  }
})
</script>

<style lang="less">
.form-footer {
  flex: 1;
  display: flex;
  flex-direction: column;
}
</style>
