<template>
  <DurationSlideStep
    :slide="slide"
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.clock.slideStep1.subTitle')"
    :title="$t('components.slides.clock.slideStep1.title')"
    no-border
  >
    <GeoSuggest
      ref="geoSuggest"
      :debounce="fn => debounce(fn, 500)"
      :search="searchInput"
      :suggestion="selectedSuggestion"
      @geocoded="onDecode"
    />
    <a-select
      ref="geoInput"
      v-model:value="selectedPlace"
      :disabled="inputDisabled"
      :filter-option="false"
      :loading="inputDisabled"
      :max-tag-text-length="10"
      :not-found-content="loading ? undefined : null"
      :options="suggestionOptions"
      :placeholder="$t('components.slides.clock.slideStep1.placeSelectPlaceholder')"
      :show-search="true"
      style="width: 100%; margin-bottom: 16px"
      @search="onSearch"
      @select="onSelect"
    >
      <template
        v-if="loading"
        #notFoundContent
      >
        <a-spin size="small" />
      </template>
    </a-select>
    <a-space style="flex-wrap: wrap;">
      <a-tag
        v-for="({timezone}, index) in selectedPlaces"
        :key="timezone"
        closable
        @close.prevent="removePlace(index)"
      >
        {{ timezone }}
      </a-tag>
    </a-space>
  </SlideStep>
</template>

<script>
import { computed, defineComponent, nextTick, onMounted, ref, toRaw, watch } from 'vue'
import { debounce } from 'lodash-es'
import SlideStep from '@/components/SlideStep'
import GeoSuggest from '@/components/GeoSuggest'
import { error, warn } from '@/utils'
import { getTimezoneByLocation } from '@/helpers/Google'
import { useI18n } from 'vue-i18n'
import { WorldClockSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/DurationSlideStep.vue'

export default defineComponent({
  name: 'ClockSlide',
  components: {
    DurationSlideStep,
    GeoSuggest,
    SlideStep
  },
  props: {
    title: String,
    slideObj: WorldClockSlide,
    groupId: String,
    disabled: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const slide = props.slideObj || new WorldClockSlide()
    const { t } = useI18n()
    const { locations } = slide.slideData
    const searchInput = ref('')
    const geoSuggest = ref(null)
    const geoInput = ref(null)
    const selectedPlaces = ref(locations || [])
    const selectedPlace = ref(null)
    const inputDisabled = ref(false)
    const selectedSuggestion = ref(null)
    const PLACES_LIMIT = 6

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })

    const suggestionOptions = computed(() => {
      const suggestions = geoSuggest?.value?.suggestions ?? []
      return suggestions.map(({
        description,
        placeId
      }) => ({
        label: description,
        value: placeId
      }))
    })

    const loading = computed(() => {
      return geoSuggest?.value?.loading
    })

    onMounted(() => {
      geoInput.value.focus()
    })

    const onSearch = (value) => {
      searchInput.value = value
    }

    const onDecode = async ({
      formattedAddress: address,
      location
    }) => {
      const timezone = await getTimezone(location)
      if (selectedPlaces.value.length < PLACES_LIMIT) {
        const timezoneAlreadyExists = selectedPlaces.value.find(p => p.timezone === timezone)
        const {
          lat,
          lng
        } = location
        if (!timezoneAlreadyExists) {
          selectedPlaces.value.push({
            address,
            timezone,
            latitude: lat,
            longitude: lng
          })
        } else {
          warn(t('components.slides.clock.currentTimeZoneAlreadyAddedError'))
        }
      } else {
        warn(t('components.slides.clock.limitReachedError'))
      }
      inputDisabled.value = false
      selectedSuggestion.value = null
      nextTick(() => {
        geoInput.value.focus()
      })
    }

    const updateSlideData = () => {
      slide.setSlideData({
        locations: toRaw(selectedPlaces.value)
      })
    }

    const updateSlide = () => {
      emit('update-slide', slide)
    }

    const toggleDisabled = (status) => {
      emit('update:disabled', status)
    }

    const onSelect = (v) => {
      const suggestion = geoSuggest?.value?.suggestions.find(s => s.placeId === v)
      inputDisabled.value = true
      selectedSuggestion.value = suggestion
      selectedPlace.value = null
    }

    const removePlace = (p) => {
      selectedPlaces.value.splice(p, 1)
    }

    watch(selectedPlaces.value, () => {
      if (!selectedPlaces.value.length) {
        toggleDisabled(true)
        return
      }
      updateSlideData()
      updateSlide()
      toggleDisabled(false)
    })

    const getTimezone = (location) => {
      const loc = location.lat + ',' + location.lng
      return getTimezoneByLocation(loc).then(json => {
        return json.timeZoneId
      }).catch(e => {
        error(e.message)
      })
    }

    if (!slide.widgetId) {
      toggleDisabled(true)
    }

    return {
      slide,
      geoInput,
      searchInput,
      geoSuggest,
      loading,
      inputDisabled,
      selectedPlaces,
      selectedPlace,
      selectedSuggestion,
      suggestionOptions,
      debounce,
      onSearch,
      onSelect,
      onDecode,
      removePlace,
      updateSlide
    }
  }
})
</script>

<style lang="less">

</style>
