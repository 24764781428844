<template>
  <DesignElement title="Layout">
    <div class="layout-picker">
      <div
        :class="{active: layout === 'left'}"
        class="layout left"
        @click="setLayout('left')"
      >
        <AlignIcon />
      </div>
      <div
        :class="{active: layout === 'center'}"
        class="layout center"
        @click="setLayout('center')"
      >
        <AlignIcon />
      </div>
      <div
        :class="{active: layout === 'right'}"
        class="layout right"
        @click="setLayout('right')"
      >
        <AlignIcon />
      </div>
    </div>
  </DesignElement>
</template>

<script>
import { defineComponent } from 'vue'
import DesignElement from '@/components/smartTemplateConstructor/designElements/DesignElement'
import AlignIcon from '@/components/icons/AlignIcon'

export default defineComponent({
  name: 'LayoutPicker',
  components: {
    AlignIcon,
    DesignElement
  },
  props: {
    layout: String
  },
  emits: ['update'],
  setup (props, { emit }) {

    const setLayout = (layout) => {
      emit('update', layout)
    }
    return {
      setLayout
    }
  }
})

</script>

<style lang="less">
@import "@/styles/variables.less";

.layout-picker {
  gap: 8px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 16px;

  .layout {
    border-radius: 4px;
    aspect-ratio: 16/9;
    background: @bg-dark-grey;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-align: center;

    &.left {
      justify-content: flex-start;
    }

    &.center {
      justify-content: center;
    }

    &.right {
      justify-content: flex-end;
    }

    &.active {
      background-color: var(--ant-primary-color);
      cursor: default;
    }

    .anticon {
      height: 80%;

      svg {
        height: 100%;
      }
    }
  }
}

</style>
