<script setup>
import InfoHeader from '@/InfoHeader.vue'
import { useStore } from 'vuex'
const store = useStore()
const openUpgradeSubscriptionModal = () => {
  store.dispatch('handleUpdateSubscription')
}
</script>

<template>
  <InfoHeader type="danger">
    <a-space>
      <span>{{ $t('components.renewSubscriptionHeader.subscriptionExpired') }}</span>
      <a-button
        type="primary"
        size="small"
        @click="openUpgradeSubscriptionModal"
      >
        {{ $t('components.renewSubscriptionHeader.renewSubscriptionLink') }}
      </a-button>
    </a-space>
  </InfoHeader>
</template>

<style scoped lang="less">

</style>
