
<template>
  <a-modal
    :open="showCreateFolderModal"
    :title="$t('components.mediaService.addFolderModalTitle')"
    width="540px"
    :ok-button-props="{ disabled: !newFolderName, loading: creatingFolder}"
    @ok="createFolder"
    @close="onCloseFolderModal"

    @cancel="onCloseFolderModal"
  >
    <a-form
      layout="vertical"
      @submit.prevent="createFolder"
    >
      <a-form-item
        :label="$t('components.mediaService.folderNameLabel')"
        name="folderName"
      >
        <a-input
          ref="folderNameInputRef"
          v-model:value="newFolderName"
          maxlength="255"
          :placeholder="$t('components.mediaService.folderNamePlaceholder')"
        />
      </a-form-item>
    </a-form>
  </a-modal>
  <a-upload
    class="hidden"
    multiple
    :show-upload-list="false"
    :before-upload="beforeUploadFile"
    :accept="acceptedTypes"
  >
    <a ref="uploadRef" />
  </a-upload>
  <slot />
</template>

<script>
import { computed, createVNode, defineComponent, nextTick, provide, ref } from 'vue'
import { error, formatMimeType, getAffectedPlaylistsCount } from '@/utils'
import { useStore } from 'vuex'
import { ACCEPTED_MIME_TYPES, ALL_ACCEPTED_MIME_TYPES } from '@/constants'
import { Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { useI18n } from 'vue-i18n'

const getVideoDuration = (file) => {
  const video = document.createElement('video')
  video.preload = 'metadata'

  const onLoadedMetaDataPromise = new Promise((resolve) => {
    video.onloadedmetadata = () => {
      window.URL.revokeObjectURL(video.src)
      resolve(video.duration)
      video.remove()
    }
    setTimeout(()=>{
      video && video.remove()
    }, 400)
  })

  video.src = window.URL.createObjectURL(file)
  return onLoadedMetaDataPromise
}

const isVideo = (type) => {
  return ACCEPTED_MIME_TYPES.VIDEO.includes(type)
}

export default defineComponent({
  name: 'MediaService',
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const uploadRef = ref()
    const folderNameInputRef = ref()
    const newFolderName = ref('')
    const acceptedTypes = ref(null)
    const creatingFolder = ref(false)
    const showCreateFolderModal = ref(false)
    const beforeFileUploadAction = ref(null)

    const workspaceDemoStorageSizeLimit = computed(()=>store.getters['workspace/workspaceDemoStorageSizeLimit'])

    const beforeUploadFile = async (file) => {
      acceptedTypes.value = null
      beforeFileUploadAction.value && beforeFileUploadAction.value()
      const mimetype = formatMimeType(file.type)
      let duration
      if (isVideo(file.type)) {
        duration = await getVideoDuration(file)
      }
      const { putObjectSignedUrl: url, id } = await createMedia({
        mimetype,
        name: file.name,
        metadata: {
          ...(duration ? { duration }  : null)
        }
      }).catch(handleCreateMediaError)
      if (!url || !id) return false
      await uploadFile({ file, url, id })
      updateMedia({ id, input: { isUploaded: true } }).then(() => {})
      return false
    }

    const uploadFile = async ({ file, url, id }) => {
      return store.dispatch('media/uploadFile', { file, url, id })
    }

    const createMedia = payload => {
      return store.dispatch('media/createMedia', payload)
    }

    const updateMedia = payload => {
      return store.dispatch('media/updateMedia', payload)
    }

    const deleteMediaBulk = (payload) => {
      return store.dispatch('media/deleteMediaBulk', payload)
    }

    const deleteMedia = (payload) => {
      return store.dispatch('media/deleteMedia', payload)
    }

    const handleCreateMediaError = (e) => {
      const [errorObj] = e.graphQLErrors
      const errorCode = errorObj.extensions?.code
      if (errorCode === 'MEDIA_STORAGE_SIZE_LIMIT_HIT') {
        return Modal.confirm({
          title: t('components.mediaService.mediaErrorModalTitle'),
          icon: createVNode(ExclamationCircleOutlined),
          content: t('components.mediaService.mediaErrorModalContent', {limit:workspaceDemoStorageSizeLimit.value }),
          okText: t('components.mediaService.mediaErrorModalOkText'),
          onOk () {
            store.dispatch('openUpdateSubscription')
          }
        })
      }
      error(e.message)
    }


    const onDeleteMedia = ({payload, success, error}) => {
      deleteMedia(payload).then(() => {
        success && success()
      }).catch((e) => {
        const affectedPlaylistsCount = getAffectedPlaylistsCount(e)
        if (!affectedPlaylistsCount) {
          return error(e.message || 'Error')
        }
        return getConfirmationModal(affectedPlaylistsCount, false,function () {
          payload.force = true
          deleteMedia(payload)
            .then(()=>{
              success && success()
            })
            .catch((e) => {
              error(e.message || 'Error')
            })
        })
      })
    }

    const onDeleteMediaBulk = ({payload, success, error}) => {
      deleteMediaBulk(payload).then(() => {
        success && success()
      }).catch((e) => {
        const affectedPlaylistsCount = getAffectedPlaylistsCount(e)
        if (!affectedPlaylistsCount) {
          return error(e.message || 'Error')
        }
        return getConfirmationModal(affectedPlaylistsCount, true,function () {
          payload.force = true
          deleteMediaBulk(payload)
            .then(()=>{
              success && success()
            })
            .catch((e) => {
              error(e.message || 'Error')
            })
        })
      })
    }

    const getConfirmationModal = (affectedPlaylistsCount, isMultipleFiles, onOk) => {
      return Modal.confirm({
        title: isMultipleFiles ? t('components.mediaService.confirmModalMultipleFilesTitle', {affectedPlaylistsCount}, affectedPlaylistsCount) : t('components.mediaService.confirmModalSingleFileTitle', {affectedPlaylistsCount}, affectedPlaylistsCount),
        icon: createVNode(ExclamationCircleOutlined),
        content: t('components.mediaService.confirmModalContent', affectedPlaylistsCount),
        okText:  t('components.mediaService.confirmModalOkText'),
        cancelText:  t('components.mediaService.confirmModalCancelText'),
        onOk () {
          onOk()
        }
      })
    }

    const createFolder = () => {
      if (creatingFolder.value || !newFolderName.value) return
      creatingFolder.value = true
      return store.dispatch('media/createDirectory', newFolderName.value).then(() => {
        resetCreateFolderForm()
      }).catch((e) => {
        error(e.message)
        creatingFolder.value = false
      })
    }

    const onCloseFolderModal = () => {
      resetCreateFolderForm()
    }

    const resetCreateFolderForm = () => {
      showCreateFolderModal.value = false
      creatingFolder.value = false
      newFolderName.value = ''
    }

    const startFileUpload = (types, action) => {
      acceptedTypes.value = types?.map(type => ACCEPTED_MIME_TYPES[type])?.join(',') || ALL_ACCEPTED_MIME_TYPES
      beforeFileUploadAction.value = action || null

      nextTick(() => {
        uploadRef.value.click()
      })
    }

    const startFolderCreate = () => {
      showCreateFolderModal.value = true
      nextTick(() => {
        folderNameInputRef.value.focus()
      })
    }

    provide('mediaService', {
      startFileUpload,
      startFolderCreate,
      deleteMediaBulk: onDeleteMediaBulk,
      deleteMedia: onDeleteMedia
    })

    return {
      uploadRef,
      acceptedTypes,
      newFolderName,
      creatingFolder,
      folderNameInputRef,
      showCreateFolderModal,
      createFolder,
      beforeUploadFile,
      onCloseFolderModal
    }
  }
})
</script>

<style scoped>

</style>
