<template>
  <DurationSlideStep
    :slide="slide"
    :adjust-to-items-number="selectedMedia?.length"
    :disable-duration-per-item="disableDurationPerItem"
    show-duration-per-item
    @update-slide="updateSlide"
  />
  <SlideStep
    :sub-title="$t('components.slides.image.slideStep1.subTitle')"
    :title="$t('components.slides.image.slideStep1.title')"
  >
    <template #extra>
      <a-button @click="openSelectModal">
        <template #icon>
          <PlusOutlined />
        </template>
        {{ $t('components.slides.image.slideStep1.addImagesButtonText') }}
      </a-button>
    </template>
    <a-checkbox
      v-model:checked="shuffle"
      :disabled="selectedView !== 'SLIDESHOW'"
      style="margin-bottom: 16px;"
    >
      {{ $t('components.slides.image.slideStep1.shuffleImages') }}
    </a-checkbox>
    <a-image-preview-group v-if="selectedMedia?.length">
      <draggable
        :list="selectedMedia"
        class="thumbnails-list"
        ghost-class="ghost"
        item-key="id"
        @change="onOrderChange"
      >
        <template #item="{ element }">
          <div class="thumbnails-list-item">
            <div class="thumbnails-image-wrapper">
              <a-image
                v-if="element.thumbnail"
                :src="element.thumbnail"
                :preview="{src:element.source}"
              />
              <div
                v-else-if="isUploading(element)"
                class="thumbnail-placeholder"
              >
                <SyncOutlined spin />
              </div>
              <div
                v-else
                class="thumbnail-placeholder"
              >
                <PictureOutlined />
              </div>
              <div
                class="remove-item"
                @click="removeMedia(element)"
              >
                <a-tooltip title="Remove">
                  <a-button
                    shape="circle"
                    size="small"
                    type="default"
                  >
                    <template #icon>
                      <DeleteOutlined />
                    </template>
                  </a-button>
                </a-tooltip>
              </div>
            </div>
            <a-tooltip :title="element.fullName">
              <div class="thumbnail-name">
                <span class="thumbnail-name-ellipsis">
                  {{ element.name }}
                </span>
                <span
                  v-if="element.extension"
                  class="thumbnail-name-ext"
                >
                  .{{ element.extension }}
                </span>
              </div>
              <div
                v-if="uploadingQueue[element.id]"
                class="thumbnail-progress"
              >
                {{ uploadingQueue[element.id].progress }}%
              </div>
            </a-tooltip>
          </div>
        </template>
      </draggable>
    </a-image-preview-group>
    <a-empty v-else />
  </SlideStep>
  <SlideStep
    :title="$t('components.slides.image.slideStep2.title')"
    no-border
  >
    <SlideViewSelect
      v-model:view="selectedView"
      :data="viewDataState"
    />
  </SlideStep>
  <MediaSelectModal
    v-model:visible="showMediaSelectModal"
    :exclude-headings="['actions']"
    :selected-media="selectedMedia"
    :types="['IMAGE']"
    selection="multiple"
    @close="onCloseMediaModal"
    @update="onSelectionUpdate"
  />
</template>

<script>
import { computed, defineComponent, reactive, ref, watch, watchEffect } from 'vue'
import { DeleteOutlined, PictureOutlined, PlusOutlined, SyncOutlined } from '@ant-design/icons-vue'
import draggable from 'vuedraggable'
import SlideStep from '@/components/SlideStep'
import MediaSelectModal from '@/components/MediaSelectModal'
import singleTypeImage from '@/assets/slide-type-single-active.svg'
import sliderTypeImage from '@/assets/slide-type-slider-active.svg'
import photowallTypeImage from '@/assets/slide-type-photowall-active.svg'
import SlideViewSelect from '@/components/SlideViewSelect'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { ImageSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/DurationSlideStep.vue'

export default defineComponent({
  name: 'ImageSlide',
  components: {
    DurationSlideStep,
    SlideViewSelect,
    MediaSelectModal,
    SlideStep,
    PlusOutlined,
    DeleteOutlined,
    PictureOutlined,
    SyncOutlined,
    draggable
  },
  props: {
    title: String,
    slideObj: ImageSlide,
    groupId: String,
    disabled: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const isNewSlide = ref(!props.slideObj)
    const store = useStore()
    const { t } = useI18n()
    const slide = props.slideObj || new ImageSlide()
    const selectedView = ref(slide.slideData.view)
    const selectedObjectFit = ref(slide.slideData.objectFit)
    const shuffle = ref(slide.slideData.shuffle || false)
    const disableDurationPerItem = ref(false)
    const selectedMedia = ref(slide.images)
    const showMediaSelectModal = ref(isNewSlide.value)
    const uploadingQueue = computed(() => store.getters['media/uploadingQueue'])
    const isSlideshowDisabled = computed(() => selectedMedia.value?.length <= 1)
    const isPhotowallDisabled = computed(() => selectedMedia.value?.length < 7)
    const isFullscreenDisabled = computed(() => selectedMedia.value?.length !== 1)
    const viewDataState = reactive([
      {
        text: t('components.slides.image.singleImage'),
        view: 'FULLSCREEN',
        image: singleTypeImage,
        disabled: isFullscreenDisabled
      },
      {
        text: t('components.slides.image.slider'),
        view: 'SLIDESHOW',
        image: sliderTypeImage,
        disabled: isSlideshowDisabled
      },
      {
        text: t('components.slides.image.photowall'),
        view: 'PHOTOWALL',
        image: photowallTypeImage,
        disabled: isPhotowallDisabled
      }
    ])

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })
    emit('update:disabled', !selectedMedia.value)

    const openSelectModal = () => {
      showMediaSelectModal.value = true
    }
    const onOrderChange = () => {
      updateSlideData()
      updateSlide()
    }

    const isUploading = (media) => {
      return media.mediaReference?.sourceType === 'WORKSPACE_MEDIA' && !media.thumbnail
    }

    const onSelectionUpdate = (selection) => {
      selectedMedia.value = [...selectedMedia.value, ...selection.filter(m => m.type !== 'DIRECTORY')]
      adjustView()
      updateSlideData()
      updateSlide()
      toggleDisabled(selectedMedia.value.length === 0)
    }

    const adjustView = () => {
      selectedView.value = selectedMedia.value.length === 1 ? 'FULLSCREEN'
          : selectedMedia.value.length < 7 ? 'SLIDESHOW'
              : selectedMedia.value.length >= 7 ? (selectedView.value === 'FULLSCREEN' ? 'SLIDESHOW' : selectedView.value)
                  : 'FULLSCREEN'
    }

    const updateSlide = () => {
      emit('update-slide', slide)
    }

    const toggleDisabled = (status) => {
      emit('update:disabled', status)
    }

    const updateSlideData = () => {
      slide.updateSlideData({
        shuffle: shuffle.value,
        view: selectedView.value,
        objectFit: selectedObjectFit.value,
        mediaImageReferences: selectedMedia.value?.map(m => m.mediaReference)
      })
      slide.setImages(selectedMedia.value)
    }
    const onCloseMediaModal = () => {
      if (isNewSlide.value && !selectedMedia.value?.length) {
        emit('close')
      }
    }

    const removeMedia = (media) => {
      const index = selectedMedia.value.indexOf(media)
      selectedMedia.value.splice(index, 1)
      adjustView()
      updateSlideData()
      updateSlide()
      toggleDisabled(selectedMedia.value.length === 0)
    }

    watch(selectedView, () => {
      updateSlideData()
      updateSlide()
    })

    watch(shuffle, () => {
      updateSlideData()
      updateSlide()
    })

    watchEffect(()=>{
      if (selectedView.value === 'PHOTOWALL') {
        disableDurationPerItem.value = true
      } else {
        disableDurationPerItem.value = false
      }
    })

    adjustView()

    return {
      slide,
      showMediaSelectModal,
      selectedMedia,
      selectedView,
      shuffle,
      isSlideshowDisabled,
      isFullscreenDisabled,
      isPhotowallDisabled,
      viewDataState,
      uploadingQueue,
      disableDurationPerItem,
      isUploading,
      removeMedia,
      openSelectModal,
      onSelectionUpdate,
      onCloseMediaModal,
      onOrderChange,
      updateSlide
    }
  }
})
</script>

<style lang="less" scoped>
.thumbnails-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 8px 8px;

  > div.thumbnails-list-item {
    position: relative;
    cursor: pointer;
    overflow: hidden;

    div.thumbnails-image-wrapper {
      height: 60px;
      border: solid 1px #f0f0f0;
      border-radius: 3px;
      background-color: #fff;

      .thumbnail-placeholder {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }
    }

    .thumbnail-name {
      display: flex;

      .thumbnail-name-ellipsis {
        flex: 0 1 auto;
        flex-shrink: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .thumbnail-name-ext {
        flex: 1 0 auto;
      }
    }

    &[draggable="true"] {
      cursor: grabbing;

      :deep(.ant-image) {
        cursor: grab;
        .ant-image-mask {
          cursor: grabbing;
        }
      }
    }

    &:hover {
      .remove-item {
        display: block;

        &:hover {
        }
      }
    }

    .remove-item {
      cursor: pointer;
      position: absolute;
      right: 4px;
      top: 4px;
      display: none;
    }

    :deep(.ant-image) {
      display: flex;
      justify-content: center;
      border: solid 1px #f0f0f0;
      border-radius: 3px;
      background-color: #fff;

      .ant-image-mask {
        cursor: grab;
      }

      .ant-image-img {
        max-height: 60px;
        object-fit: cover;
      }
    }
  }
}
</style>
