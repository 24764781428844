<template>
  <a-layout-sider
    :collapsed="true"
    :trigger="null"
    collapsible
    collapsed-width="60"
    width="60px"
    class="root-sidebar dark"
  >
    <EmergencyPlaylistModal />
    <a-layout class="full-height">
      <a-layout-header
        style="padding: 0;"
        class="dark"
      >
        <menu-unfold-outlined
          v-if="collapsed"
          class="trigger"
          @click="handleSidebarToggle"
        />
        <menu-fold-outlined
          v-else
          class="trigger"
          @click="handleSidebarToggle"
        />
      </a-layout-header>
      <a-layout-content style="display: flex; flex-direction: column; overflow-x: auto">
        <a-menu
          :selected-keys="selectedKeys"
          theme="dark"
          class="no-border"
        >
          <a-menu-item
            key="devices"
          >
            <template #icon>
              <DesktopOutlined />
            </template>
            <a @click="openDevicesModal">
              <span>{{ $t('components.rootSidebar.screens') }}</span>
            </a>
          </a-menu-item>
          <a-menu-item
            key="media"
          >
            <template #icon>
              <FolderOpenOutlined />
            </template>
            <a @click="openMediaModal">
              <span>{{ $t('components.rootSidebar.media') }}</span>
            </a>
          </a-menu-item>
          <a-menu-item
            v-if="showCollaborationMenuItem"
            key="collaboration"
          >
            <template #icon>
              <TeamOutlined />
            </template>
            <a @click="openCollaborationModal">
              <span>{{ $t('components.rootSidebar.users') }}</span>
            </a>
          </a-menu-item>
          <a-menu-item
            key="authorized-accounts"
          >
            <template #icon>
              <ApiOutlined />
            </template>
            <a @click="openAuthorizedAccountsModal">
              <span>{{ $t('components.rootSidebar.authorizedAccounts') }}</span>
            </a>
          </a-menu-item>
          <a-menu-item
            v-if="externalApiEnabled"
            key="emergency-playlist"
          >
            <template #icon>
              <WarningOutlined />
            </template>
            <a @click="openEmergencyPlaylistModal">
              <span>{{ $t('components.rootSidebar.emergencyPlaylist') }}</span>
            </a>
          </a-menu-item>
        </a-menu>
        <div class="spacer" />
        <RootSidebarCustomMenu v-if="showCustomMenu" />

        <a-menu
          theme="dark"
          class="no-border notification-menu"
          :selected-keys="selectedKeys"
        >
          <RootSidebarInfoMenuItem />
          <a-menu-item
            key="notifications-badge"
            @click="$emit('notifications')"
          >
            <template
              #icon
            >
              <a-badge
                :dot="!!unseenNotificationsCount"
              >
                <BellOutlined />
              </a-badge>
            </template>
            {{ $t('components.rootSidebar.notifications') }}
          </a-menu-item>
        </a-menu>
      </a-layout-content>
      <footer
        style="padding: 16px 8px; text-align: center"
      >
        <MainMenu />
      </footer>
    </a-layout>
  </a-layout-sider>
</template>

<script>
import { computed, defineComponent, onMounted, onUnmounted, ref, toRef } from 'vue'
import {
  ApiOutlined,
  BellOutlined,
  DesktopOutlined,
  FolderOpenOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  TeamOutlined,
  WarningOutlined
} from '@ant-design/icons-vue'
import MainMenu from '@/components/MainMenu'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import RootSidebarCustomMenu from '@/components/RootSidebarCustomMenu.vue'
import { theme } from 'ant-design-vue'
import RootSidebarInfoMenuItem from '@/components/RootSidebarInfoMenuItem.vue'
import EmergencyPlaylistModal from '@/components/EmergencyPlaylistModal.vue'

export default defineComponent( {
  name: 'RootSidebar',
  components: {
    EmergencyPlaylistModal,
    RootSidebarInfoMenuItem,
    RootSidebarCustomMenu,
    MainMenu,
    MenuUnfoldOutlined,
    FolderOpenOutlined,
    MenuFoldOutlined,
    TeamOutlined,
    DesktopOutlined,
    BellOutlined,
    ApiOutlined,
    WarningOutlined
  },
  props: {
    notificationsVisible: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const store = useStore()
    const router = useRouter()
    const notificationsVisible = toRef(props, 'notificationsVisible')
    const showMedia = computed(() => store.getters.showMedia)
    const showDevices = computed(() => store.getters.showDevices)
    const showCollaboration = computed(() => store.getters.showCollaboration)
    const showAuthorizedAccounts = computed(() => store.getters.showAuthorizedAccounts)
    const showEmergencyPlaylistModal = computed(() => store.getters.showEmergencyPlaylistModal)
    const unseenNotificationsCount = computed(() => store.getters['notifications/unseenNotificationsCount'])
    const globalSettingsActiveTabOpened = computed(() => store.getters['globalSettingsActiveTabOpened'])
    const externalApiEnabled = computed(() => store.getters['workspace/externalApiEnabled'])
    const isOwner = computed(() => store.getters['workspace/isOwner'])
    const isAdmin = computed(() => store.getters['workspace/isAdmin'])
    const prevRoute = computed(() => store.getters['prevRoute'])
    const collapsed = computed(() => store.getters['groupSidebarCollapsed'])
    const workspaceEmergencyFeedShowOnMainPanel = ref(true)

    const showCollaborationMenuItem = computed(() => {
      return isAdmin.value || isOwner.value
    })

    const showCustomMenu = computed(() => {
      return isAdmin.value || isOwner.value
    })

    const selectedKeys = computed(() => {
      return [
        notificationsVisible.value && 'notifications-badge',
        showMedia.value && 'media',
        showDevices.value && 'devices',
        showCollaboration.value && 'collaboration',
        showAuthorizedAccounts.value && 'authorized-accounts',
        showEmergencyPlaylistModal.value && 'emergency-modal',
        globalSettingsActiveTabOpened.value
      ].filter(Boolean)
    })

    onMounted(()=>{
      store.dispatch('notifications/getUnreadNotificationsCount')
      store.dispatch('notifications/subscribeToUnreadNotifications')
    })

    onUnmounted(()=>{
      store.commit('subscription/CLEAR_SUBSCRIPTION_DATA')
    })

    const handleGoBack = () => {
      if (prevRoute.value) {
        router.push(prevRoute.value)
      }
      else {
        router.push({name: 'Home'})
      }
    }

    const openEmergencyAlertSettings = () => {
      store.dispatch('openGlobalSettings', 'emergency')
    }
    const handleSidebarToggle = () => {
      store.dispatch('toggleGroupSidebar')
    }
    const openCollaborationModal = () => {
      store.dispatch('openCollaboration')
    }
    const openAuthorizedAccountsModal = () => {
      store.dispatch('openAuthorizedAccounts')
    }
    const openEmergencyPlaylistModal = () => {
      store.dispatch('openEmergencyPlaylistModal')
    }
    const openMediaModal = () => {
      store.dispatch('openMedia')
    }
    const openDevicesModal = () => {
      store.dispatch('openDevices')
    }

    return {
      theme,
      collapsed,
      selectedKeys,
      unseenNotificationsCount,
      showCollaborationMenuItem,
      showCustomMenu,
      workspaceEmergencyFeedShowOnMainPanel,
      externalApiEnabled,
      openEmergencyAlertSettings,
      handleGoBack,
      handleSidebarToggle,
      openAuthorizedAccountsModal,
      openEmergencyPlaylistModal,
      openCollaborationModal,
      openMediaModal,
      openDevicesModal
    }
  }
}

)
</script>

<style lang="less">
@import "../styles/variables.less";

.root-sidebar {
  .anticon.trigger {
    font-size: 18px;
    line-height: 64px;
    width: 100%;
    color: @text-color-secondary-dark;
    cursor: pointer;
    transition: color .3s;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    :hover {
      color: @text-color-secondary;
    }
  }
  .notification-menu span{
    color: @text-color-secondary-dark;
    &:hover {
      color: @text-color-secondary;
    }
  }
  .ant-layout-sider-children {
    .ant-layout {
      width: 60px;
    }
  }
}

</style>
