<template>
  <MoveToOtherGroupModal
    v-model:visible="showDeviceMoveModal"
    :title="$t('components.devicesTable.moveToGroupModalTitle')"
    :ignore-smart-groups="true"
    :exclude-group-id="deviceGroupIdToMove"
    do-not-exclude-branch
    @select="onDeviceMoveSelect"
  />
  <a-row
    v-if="!hideSearch"
    type="flex"
    justify="space-between"
    style="position: sticky; z-index: 10; top: -24px; padding: 24px 0 16px; margin-top: -24px; background-color: #fff"
    :style="padded ? 'padding: 24px 0 0 16px;' : ''"
  >
    <a-col span="8">
      <a-input-search
        v-model:value="nameFilter"
        :placeholder="$t('components.devicesTable.filterPlaceholder')"
        style="width: 200px"
      />
    </a-col>
  </a-row>
  <a-table
    :data-source="devicesList"
    :loading="devicesLoading"
    :columns="columns"
    :scroll="{ x: 600 }"
    size="small"
    :row-selection="rowSelection"
    :class="{'table-padded': padded}"
    @resizeColumn="handleResizeColumn"
  >
    <template #headerCell="{ column }">
      {{ $t(`components.devicesTable.${column.key}`) }}
    </template>
    <template #bodyCell="{ column, text, record }">
      <template v-if="column.key === 'name'">
        <div class="editable-cell">
          <a-tooltip :title="$t(`components.devicesTable.preview`)">
            <a-button
              :disabled="!record.isOnline"
              style="flex-shrink: 0;"
              @click="showDevicePreview({deviceId: record.id, deviceName: record.name})"
            >
              <template #icon>
                <EyeOutlined />
              </template>
            </a-button>
          </a-tooltip>
          <div
            v-if="typeof editableData[record.id] === 'string'"
            class="editable-cell-input-wrapper"
          >
            <a-input
              v-model:value="editableData[record.id]"
              style="margin-left: 4px;"
              @press-enter="onNameChange({id: record.id, groupId: record.groupId})"
            />
            <CheckOutlined
              class="editable-cell-icon-check"
              @click="onNameChange({id: record.id, groupId: record.groupId})"
            />
          </div>
          <div
            v-else
            class="editable-cell-text-wrapper"
          >
            <span class="editable-cell-text-overflow">{{ text || ' ' }}</span>
            <a-tooltip :title="$t(`components.devicesTable.edit`)">
              <EditOutlined
                class="editable-cell-icon"
                @click="startNameEdit(record.id)"
              />
            </a-tooltip>
          </div>
        </div>
      </template>
      <template v-if="column.key === 'tags'">
        <a-select
          mode="tags"
          size="small"
          :placeholder="$t(`components.devicesTable.tagsInputPlaceholder`)"
          :loading="tagsLoading"
          option-label-prop="label"
          style="width: 160px"
          max-tag-count="responsive"
          :value="record.customTags"
          :options="devicesTagsOptions"
          @change="tags => onTagsChange({ id: record.id, groupId: record.groupId , tags, systemTags: record.systemTags, customTags: record.customTags})"
        />
      </template>
      <template v-if="column.key === 'orientation'">
        <a-tooltip :title="$t(`components.devicesTable.${orientationOptionsMap[record.orientation]}`)">
          <a-select
            ref="select"
            style="width: 100%;"
            :value="record.orientation+''"
            :dropdown-match-select-width="200"
            size="small"
            @change="orientation => onOrientationChange({ id: record.id, groupId: record.groupId ,orientation})"
          >
            <a-select-option
              v-for="option in orientationOptions"
              :key="option.value"
              :value="option.value"
            >
              {{ $t(`components.devicesTable.${option.label}`) }}
            </a-select-option>
          </a-select>
        </a-tooltip>
      </template>
      <template v-if="column.key === 'hardwareInfo'">
        {{ text?.tvos }}
      </template>
      <template v-if="column.key === 'groupName'">
        <a-tooltip
          v-if="screensMovable"
          placement="bottom"
        >
          <template #title>
            {{ $t('components.devicesTable.moveTooltipText') }}
          </template>
          <a-typography-link @click="startDeviceMove({id: record.id, groupId: record.groupId})">
            {{ text }}
          </a-typography-link>
        </a-tooltip>
        <span v-else>{{ text }}</span>
      </template>
      <template v-if="column.key === 'contentGatheringWindow'">
        <a-select
          size="small"
          style="width: 100%;"
          :value="record.contentGatheringWindowValue"
          dropdown-match-select-width="200px"
          @change="contentGatheringWindowValue => onContentGatheringWindowChange({id: record.id, groupId: record.groupId , contentGatheringWindowValue})"
        >
          <a-select-option value="0">
            {{ $t('components.devicesTable.allDay') }}
          </a-select-option>
          <a-select-option value="1">
            {{ $t('components.devicesTable.morning') }}
          </a-select-option>
          <a-select-option value="2">
            {{ $t('components.devicesTable.afternoon') }}
          </a-select-option>
          <a-select-option value="3">
            {{ $t('components.devicesTable.evening') }}
          </a-select-option>
          <a-select-option value="4">
            {{ $t('components.devicesTable.night') }}
          </a-select-option>
        </a-select>
      </template>
      <template v-if="column.key === 'isOnline'">
        <a-tooltip
          :title="$t(`components.devicesTable.${text ? 'online' : 'offline'}`)"
        >
          <a-badge
            :status="text ? 'success' : 'error'"
          />
        </a-tooltip>
      </template>
      <template v-else-if="column.dataIndex === 'actions'">
        <div style="display: flex; gap: 4px; justify-content: center;">
          <template v-if="!showGroups">
            <a-tooltip placement="bottom">
              <template #title>
                {{ $t('components.devicesTable.moveTooltipText') }}
              </template>
              <a-button @click="startDeviceMove({id: record.id, groupId: record.groupId})">
                <template #icon>
                  <ExportOutlined style="font-size: 12px;" />
                </template>
              </a-button>
            </a-tooltip>
          </template>
          <a-popconfirm
            :title="$t('components.devicesTable.deletePopConfirmTitle')"
            :ok-text="$t('components.devicesTable.deletePopConfirmOkText')"
            :cancel-text="$t('components.devicesTable.deletePopConfirmCancelText')"
            @confirm="onDeviceDelete({id: record.id, groupId: record.groupId})"
          >
            <a-tooltip placement="bottom">
              <template #title>
                {{ $t('components.devicesTable.deleteTooltipTitle') }}
              </template>
              <a-button>
                <template #icon>
                  <DeleteOutlined style="font-size: 12px;" />
                </template>
              </a-button>
            </a-tooltip>
          </a-popconfirm>
        </div>
      </template>
    </template>
  </a-table>
</template>

<script>
import { computed, defineComponent, onMounted, reactive, ref, watch } from 'vue'
import { cloneDeep } from 'lodash'
import { useStore } from 'vuex'
import { error, sortByValueAsc, success } from '@/utils'
import { INNER_SIDER_WIDTH } from '@/constants'
import MoveToOtherGroupModal from '@/components/MoveToOtherGroupModal'
import { CheckOutlined, DeleteOutlined, EditOutlined, ExportOutlined, EyeOutlined } from '@ant-design/icons-vue'

const COLUMNS = [{
  title: 'Name',
  dataIndex: 'name',
  key: 'name',
  resizable: true,
  ellipsis: true,
  fixed: 'left',
  width: 180,
  maxWidth: 600,
  minWidth: 180
}, {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    width: 180,
    ellipsis: true
}, {
  title: 'tvOS',
  key: 'hardwareInfo',
  dataIndex: 'hardwareInfo',
  width: 70,
  ellipsis: true
}, {
  title: 'App Version',
  dataIndex: 'appVersion',
  key: 'appVersion',
  width: 100,
  ellipsis: true
}, {
  title: 'Orientation',
  dataIndex: 'orientation',
  key: 'orientation',
  width: 120,
  minWidth: 100,
  maxWidth: 120
}, {
  title: 'Content Synс',
  dataIndex: 'contentGatheringWindow',
  key: 'contentGatheringWindow',
  width: 140,
  minWidth: 100,
  maxWidth: 140
}, {
  title: 'Status',
  dataIndex: 'isOnline',
  key: 'isOnline',
  align: 'center',
  width: 80,
  minWidth: 80,
  maxWidth: 80
}, {
  title: 'Actions',
  dataIndex: 'actions',
  key: 'actions',
  align: 'center',
  width: 120,
  minWidth: 120,
  maxWidth: 120
}]

const GROUP_COLUMN = {
  title: 'Group',
  dataIndex: 'groupName',
  key: 'groupName',
  width: 140,
  minWidth: 140,
  maxWidth: 200
}

const ORIENTATION_OPTIONS_MAP = {
  0: 'horizontal',
  90: 'vertical',
  180: 'horizontalReverse',
  270: 'verticalReverse'
}

const GROUP_COLUMN_INDEX = 2

const MILLISECONDS_IN_HOUR = 60 * 60 * 1000

const contentGatheringWindowMap = [
  null,
  {
    startDailyWindowTimeMs: 8 * MILLISECONDS_IN_HOUR,
    endDailyWindowTimeMs: 12 * MILLISECONDS_IN_HOUR
  },
  {
    startDailyWindowTimeMs: 12 * MILLISECONDS_IN_HOUR,
    endDailyWindowTimeMs: 18 * MILLISECONDS_IN_HOUR
  },
  {
    startDailyWindowTimeMs: 18 * MILLISECONDS_IN_HOUR,
    endDailyWindowTimeMs: 24 * MILLISECONDS_IN_HOUR - 1
  },
  {
    startDailyWindowTimeMs: 0,
    endDailyWindowTimeMs: 6 * MILLISECONDS_IN_HOUR
  }
]


export default defineComponent({
  name: 'DevicesTable',
  components: {
    MoveToOtherGroupModal,
    EditOutlined,
    CheckOutlined,
    ExportOutlined,
    DeleteOutlined,
    EyeOutlined,
  },
  props: {
    searchTerm: String,
    hideSearch: Boolean,
    selection: {
      type: String,
      validator (value) {
        return ['multiple', 'none'].indexOf(value) !== -1
      },
      default: 'none'
    },

    devices: Array,
    padded: Boolean,
    showGroups: Boolean,
  },
  setup (props) {
    const store = useStore()
    const availableStandardGroups = computed(()=>store.getters['groups/availableStandardGroups'])
    const nameFilter = ref('')
    const devicesTags = computed(()=> store.getters['tags/devicesTags'])
    const customDevicesTags = computed(()=> devicesTags.value?.data?.filter(tag => !tag.isSystemGenerated))
    const devicesTagsOptions = computed(()=> customDevicesTags.value?.map(tag=>({label: tag.title, value: tag.key}))?.sort(sortByValueAsc))
    const selectedDevicesIds = computed(() => store.getters['devices/selectedDevicesIds'])

    const tagsLoading = ref(false)

    const columns = ref(!props.showGroups ? cloneDeep(COLUMNS) : cloneDeep([...COLUMNS.slice(0,GROUP_COLUMN_INDEX), GROUP_COLUMN, ...COLUMNS.slice(GROUP_COLUMN_INDEX)]))
    const devicesLoading = ref(false)
    const showDeviceMoveModal = ref(false)
    const deviceIdToMove = ref(null)
    const deviceGroupIdToMove = ref(null)

    const selectedKeys = ref(selectedDevicesIds.value || [])

    const editableData = reactive({})
    const devicesList = computed(() => props.devices?.filter(({name}) => nameFilter.value ? name.toLowerCase()?.includes(nameFilter.value?.toLowerCase()) : true)
        .map(d => {
      return {
        contentGatheringWindowValue: contentGatheringWindowToValue(d.contentGatheringWindow),
        ...d,
        key: d.id,
        groupName: props.showGroups ? availableStandardGroups.value?.find(({id}) => id === d.groupId)?.name : '',
        customTags: d.tags?.filter(tag => !tag.isSystemGenerated)?.map(tag=>({label: tag.title, value: tag.key, tag}))
      }
    }))

    const screensMovable = computed(() => {
      return availableStandardGroups.value?.length > 1
    })

    onMounted(()=>{
      fetchDevicesTags()
    })

    const fetchDevicesTags = async () => {
      await store.dispatch('tags/getDevicesTags')
    }

    const onDeviceDelete = async ({id, groupId}) => {
      store.dispatch('devices/deleteDevice', { id, groupId }).then(() => {
        success()
      }).catch(e => {
        error(e.message || 'Error')
      })
    }

    const onOrientationChange = ({id, groupId, orientation}) => {
      store.dispatch('devices/updateDevice', { id, groupId, input: { orientation: +orientation } }).then(() => {
        success()
        delete editableData[id]
      }).catch(e => {
        error(e)
      })
    }

    const onTagsChange = ({id, groupId, tags}) => {
      tagsLoading.value = true
      tags = tags.map(tag => (tag.startsWith('#') || tag.startsWith('@')) ? tag.slice(1) : tag).filter(Boolean)
      store.dispatch('devices/setDeviceCustomTagsById', { id, groupId, input: { tagKeys: tags } }).then(async () => {
        await fetchDevicesTags()
        success()
      }).catch(e => {
        error(e.message)
      }).then(()=>{
        tagsLoading.value = false
      })
    }

    const onContentGatheringWindowChange = ({id, groupId, contentGatheringWindowValue}) => {
      const contentGatheringWindow = contentGatheringWindowMap[contentGatheringWindowValue]
      store.dispatch('devices/updateDevice', { id, groupId, input: { contentGatheringWindow } }).then(() => {
        success()
        delete editableData[id]
      }).catch(e => {
        error(e)
      })
    }

    const contentGatheringWindowToValue = (contentGatheringWindow) => {
      if (contentGatheringWindow === null) {
        return '0'
      }
      const contentGatheringWindowIndex = contentGatheringWindowMap
          .findIndex(w => w && w.startDailyWindowTimeMs === contentGatheringWindow.startDailyWindowTimeMs &&
              w.endDailyWindowTimeMs === contentGatheringWindow.endDailyWindowTimeMs)
      return (contentGatheringWindowIndex > 0 ? contentGatheringWindowIndex : 0) + ''
    }

    const startNameEdit = id => {
      editableData[id] = devicesList.value.find(item => id === item.id)?.name
    }

    const startDeviceMove = ({ id, groupId }) => {
      deviceIdToMove.value = id
      deviceGroupIdToMove.value = groupId
      showDeviceMoveModal.value = true
    }

    const onNameChange = ({ id, groupId }) => {
      if (!editableData[id]) return
      store.dispatch('devices/updateDevice', { id, groupId, input: { name: editableData[id] } }).then(() => {
        success()
        delete editableData[id]
      }).catch(e => {
        error(e)
      })
    }

    const onDeviceMoveSelect = (groupId) => {
      if (!groupId) return
      store.dispatch('devices/moveDevice', { id: deviceIdToMove.value, groupId: deviceGroupIdToMove.value, input: { groupId } }).then(() => {
        success()
      }).catch(e => {
        error(e)
      })
    }

    const onSelectChange = (selectedRowKeys, selectedRows) => {
      selectedKeys.value = cloneDeep(selectedRowKeys)
      selectScreens(selectedRows)
    }

    const rowSelection = computed(() => {
      if (props.selection === 'multiple') {
        return {
          onChange: onSelectChange,
          selectedRowKeys: selectedKeys.value
        }
      }
      return null
    })

    const selectScreens = (selection = []) => {
      store.commit('devices/SET_SELECTED_DEVICES', selection)
    }

    const handleResizeColumn = (w, col) => {
      col.width = w
    }

    const showDevicePreview = ({deviceId, deviceName}) => {
      store.commit('devices/SET_DEVICE_PREVIEW_OBJECT', {deviceId, deviceName})
    }



    watch(selectedDevicesIds, (devicesIds) => {
      selectedKeys.value = devicesIds
    })

    watch(()=> props.searchTerm, (newValue) => {
      nameFilter.value = newValue
    })

    return {
      devicesTagsOptions,
      columns,
      devicesList,
      tagsLoading,
      devicesLoading,
      editableData,
      nameFilter,
      showDeviceMoveModal,
      deviceGroupIdToMove,
      screensMovable,
      rowSelection,
      INNER_SIDER_WIDTH,
      startNameEdit,
      startDeviceMove,
      onNameChange,
      onDeviceDelete,
      onDeviceMoveSelect,
      onOrientationChange,
      onTagsChange,
      onContentGatheringWindowChange,
      contentGatheringWindowToValue,
      handleResizeColumn,
      showDevicePreview,
      orientationOptions: Object.keys(ORIENTATION_OPTIONS_MAP).map((k)=> ({ value: k, label: ORIENTATION_OPTIONS_MAP[k] })),
      orientationOptionsMap: ORIENTATION_OPTIONS_MAP
    }
  }
})
</script>

<style scoped lang="less">
  .tag-select-option {
    display: flex;
    justify-content: space-between;
  }

</style>
