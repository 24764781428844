<script>
import { defineComponent } from 'vue'
import { loadExternalScript } from '@/utils'

export default defineComponent({
  name: 'PixieComponent',
  emits: ['update', 'close', 'load'],
  setup (props, {emit}) {
    let pixie

    const initPixie = (callback) => {
      if (pixie) {
        callback && callback()
        return
      }
      loadExternalScript('/pixie/pixie-integrate.js', '/pixie').then(()=>{
        pixie = window.Pixie && window.Pixie.setOptions({
          appendTo: 'body',
          enableCORS: true,
          onSaveButtonClick: () => {
            pixie.save()
          },
          onSave: (data) => {
            emit('update', data)
          },
          onClose: () => {
            emit('close', 'data')
          },
          onLoad: () => {
            emit('load')
          }
        })
        pixie && pixie.setAssetsPath(process.env.VUE_APP_STATIC_ASSETS + '/composer')
        callback && callback()
      })
    }

    const initAndOpenPixie = ({assets, images, json, aspectRatio, templates, getTemplateJson} = {}) => {
      initPixie(() => openModal({assets, images, json, aspectRatio, templates, getTemplateJson}))
    }

    const openModal = ({assets = [], images = [], json = {}, aspectRatio, templates = [], getTemplateJson} = {}) => {
      pixie && pixie.open({ images, assets, templates, getTemplateJson }, json, aspectRatio)
    }

    const closeModal = () => {
      pixie && pixie.close()
    }

    return {
      openModal: initAndOpenPixie,
      closeModal
    }
  }
})
</script>

<template>
  <template />
</template>
