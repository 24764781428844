<template>
  <div class="template-editor">
    <header>
      <a-radio-group v-model:value="activeTab">
        <a-radio-button
          style="width: 30px;"
          value="back"
          @click="goBack"
        >
          <LeftOutlined />
        </a-radio-button>
        <a-radio-button value="content">
          {{ $t('components.smartTemplateConstructor.templateEditor.contentLabel') }}
        </a-radio-button>
        <a-radio-button value="design">
          {{ $t('components.smartTemplateConstructor.templateEditor.designLabel') }}
        </a-radio-button>
      </a-radio-group>
    </header>
    <section style="overflow-x: auto">
      <template v-if="activeTab==='content'">
        <component
          :is="editor"
          :assets="assets"
          :data="data"
          :default-template-data="defaultTemplateData"
          @update="onDataUpdate"
          @valid-change="onValidChange"
        />
      </template>
      <template v-else>
        <LayoutPicker
          v-if="layout"
          :layout="layout"
          @update="onLayoutUpdate"
        />
        <BackgroundPicker
          :animations="animations"
          :asset="assetsObj?.background"
          :background="background"
          :foreground="foreground"
          :no-border="!showForegroundPicker"
          @update-background="onBackgroundUpdate"
          @update-assets="onAssetsUpdate"
        />
        <ForegroundPicker
          v-if="showForegroundPicker"
          :foreground="foreground"
          @update-foreground="onForegroundUpdate"
        />
      </template>
    </section>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { LeftOutlined } from '@ant-design/icons-vue'
import LayoutPicker from '@/components/smartTemplateConstructor/designElements/LayoutPicker'
import BackgroundPicker from '@/components/smartTemplateConstructor/designElements/BackgroundPicker'
import TextElement from '@/components/smartTemplateConstructor/segmentElements/TextElement'
import DirectoryEditor from '@/components/smartTemplateConstructor/segmentEditors/DirectoryEditor'
import FloorMapEditor from '@/components/smartTemplateConstructor/segmentEditors/FloorMapEditor'
import WorkingHoursEditor from '@/components/smartTemplateConstructor/segmentEditors/WorkingHoursEditor'
import FacebookGroupEditor from '@/components/smartTemplateConstructor/segmentEditors/FacebookGroupEditor'
import SimpleSignEditor from '@/components/smartTemplateConstructor/segmentEditors/SimpleSignEditor'
import SaleEditor from '@/components/smartTemplateConstructor/segmentEditors/SaleEditor'
import MenuEditor from '@/components/smartTemplateConstructor/segmentEditors/MenuEditor'
import ForegroundPicker from '@/components/smartTemplateConstructor/designElements/ForegroundPicker'

export default defineComponent({
  name: 'TemplateEditor',
  components: {
    ForegroundPicker,
    MenuEditor,
    LeftOutlined,
    SaleEditor,
    SimpleSignEditor,
    FacebookGroupEditor,
    WorkingHoursEditor,
    FloorMapEditor,
    DirectoryEditor,
    TextElement,
    BackgroundPicker,
    LayoutPicker
  },
  props: {
    data: Object,
    isValid: Boolean,
    defaultTemplateData: Object,
    animations: Array,
    editor: String,
    assets: Object
  },
  emits: ['back', 'update:data', 'validChange'],
  setup (props, { emit }) {
    const activeTab = ref('content')
    const assetsObj = ref(props.assets)

    const background = computed(() => props.data?.background)
    const foreground = computed(() => props.data?.foreground)
    const layout = computed(() => props.data?.layout)
    const showForegroundPicker = computed(() => !background.value?.animation)

    const goBack = () => {
      emit('back')
    }

    const onBackgroundUpdate = (background) => {
      const settings = { background }
      if (background?.animation) {
        settings.foreground = props.defaultTemplateData?.foreground
      }
      const data = { ...props.data, ...settings }
      emit('update:data', data)
    }
    const onForegroundUpdate = (foreground) => {
      const data = { ...props.data, ...{ foreground } }
      emit('update:data', data)
    }
    const onLayoutUpdate = (layout) => {
      const data = { ...props.data, ...{ layout } }
      emit('update:data', data)
    }

    const onDataUpdate = (updateData) => {
      const data = {
        ...props.data,
        data: updateData
      }
      emit('update:data', data)
    }

    const onAssetsUpdate = ({
      assets: newAssets,
      key
    }) => {
      assetsObj.value[key] = newAssets?.[0]
    }

    const onValidChange = (valid) => {
      emit('validChange', valid)
    }

    return {
      assetsObj,
      activeTab,
      background,
      foreground,
      layout,
      showForegroundPicker,
      onValidChange,
      goBack,
      onBackgroundUpdate,
      onForegroundUpdate,
      onLayoutUpdate,
      onDataUpdate,
      onAssetsUpdate
    }
  }
})
</script>

<style lang="less" scoped>
@import "@/styles/variables.less";

.template-editor {
  display: flex;
  flex-direction: column;
  overflow: hidden;

  header {
    flex: 1 0 56px;
    border-bottom: solid 1px @border-color-light;
    display: flex;
    margin-bottom: 16px;
    align-items: center;

    .ant-radio-group {
      width: 100%;
      display: flex;

      .ant-radio-button-wrapper {
        &:first-child {
          flex: 0 0 50px;
        }

        flex: 1;
        text-align: center;
      }
    }

    border-bottom: solid 1px @border-color-light;
  }
}
</style>
