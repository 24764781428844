<template>
  <div class="groups-sidebar">
    <a-modal v-model:open="showAddScreenModal">
      <template #title>
        {{ $t('components.standardGroupDevicesSider.addScreensModalTitle') }}
      </template>
      {{ $t('components.standardGroupDevicesSider.addScreensModalDescription') }}
      <template #footer>
        <a-button
          type="primary"
          @click="handleAddScreenModalClose"
        >
          {{ $t('components.standardGroupDevicesSider.addScreensModalButtonText') }}
        </a-button>
      </template>
    </a-modal>
    <div class="total-devices">
      <a-typography-text
        type="secondary"
        style="line-height: 32px;"
      >
        {{ $t('components.standardGroupDevicesSider.screensInUse',{currentDevicesNumber, totalDevices}) }}
      </a-typography-text>
      <a-progress
        :stroke-color="colorPrimary"
        :percent="!currentDevicesNumber ? 0 : currentDevicesNumber / (totalDevices || 1) * 100"
        :show-info="false"
      />
    </div>
    <a-button
      v-if="showAddScreenButton"
      block
      @click="openUpgradeSubscriptionModal"
    >
      {{ $t('components.standardGroupDevicesSider.moreScreens') }}
    </a-button>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { error } from '@/utils'
import { theme, Modal } from 'ant-design-vue'

export default defineComponent({
  name: 'StandardGroupDevicesSider',
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const { useToken } = theme;
    const { token } = useToken();
    const currentDevicesNumber = computed(() => store.getters['devices/allDevicesNumber'])
    const totalDevices = computed(() => store.getters['workspace/billingDevicesQuantity'] + store.getters['workspace/freeDevicesQuantity'])
    const isEnterprize = computed(() => store.getters['workspace/isEnterprize'])
    const isUnpaid = computed(() => store.getters['workspace/isUnpaid'])
    const isPaused = computed(() => store.getters['workspace/isPaused'])
    const isPastDue = computed(() => store.getters['workspace/isPastDue'])
    const isTrialTier = computed(() => store.getters['workspace/isTrialTier'])
    const emailVerified = computed(() => store.getters['auth/emailVerified'])
    const colorPrimary = computed(() => token.value?.colorPrimary)
    const isOwnerOrAdmin = computed(() => store.getters['workspace/isOwner'] || store.getters['workspace/isAdmin'])
    const isManuallyFrozen = computed(() => store.getters['workspace/isManuallyFrozen'])
    const showAddScreenButton = computed(() =>
        emailVerified.value &&
        !isPaused.value &&
        !isEnterprize.value &&
        !isManuallyFrozen.value &&
        (isUnpaid.value || isPastDue.value || isTrialTier.value))

    const showAddScreenModal = ref(false)

    const openUpgradeSubscriptionModal = () => {
      if (isOwnerOrAdmin.value) {
        return store.dispatch('handleUpdateSubscription')
      }
      else {
        showAddScreenModal.value = true
      }
    }

    const handleAddScreenModalClose = () => {
      showAddScreenModal.value = false
    }

    return {
      colorPrimary,
      totalDevices,
      currentDevicesNumber,
      showAddScreenModal,
      showAddScreenButton,
      openUpgradeSubscriptionModal,
      handleAddScreenModalClose
    }
  }
})
</script>

<style scoped lang="less">
.groups-sidebar {
  margin: 16px 0 8px 0;
  padding: 0 8px 0 24px;
  .total-devices {
    font-size: 90%;
    align-items: center;
  }
}

</style>
