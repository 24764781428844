<template>
  <template v-if="mediaExists">
    <DurationSlideStep
      :key="duration"
      :slide="slide"
      :default-duration="defaultDuration"
      @update-slide="updateSlide"
    />
    <SlideStep
      :sub-title="videoName"
      :title="$t('components.slides.video.slideStep1.title')"
      no-border
    >
      <template #extra>
        <a-button @click="replaceMedia">
          <template #icon>
            <SyncOutlined />
          </template>
          {{ $t('components.slides.video.slideStep1.replaceButtonsText') }}
        </a-button>
      </template>
      <div class="video-card">
        <div class="video-thumbnail">
          <img
            v-if="thumbnail"
            :src="thumbnail"
          >
          <SyncOutlined
            v-else
            spin
          />
        </div>
        <div class="video-desc">
          <a-typography-text>
            {{ $t('components.slides.video.slideStep1.extension', { ext: videoExtension?.toUpperCase() }) }}
          </a-typography-text>
          <br>
          <a-typography-text>
            {{
              videoDuration ? $t('components.slides.video.slideStep1.duration', { count: videoDuration }) : $t('components.slides.video.slideStep1.pagesUnknown')
            }}
          </a-typography-text>
        </div>
      </div>
    </SlideStep>
  </template>
  <MediaSelectModal
    v-model:visible="showMediaSelectModal"
    :exclude-headings="['actions']"
    :selected-media="selectedMedia"
    :types="['VIDEO']"
    selection="single"
    @close="onCloseMediaModal"
    @select="onSelectChange"
  />
</template>
<script>
import { computed, defineComponent, ref } from 'vue'
import { SyncOutlined } from '@ant-design/icons-vue'
import SlideStep from '@/components/SlideStep'
import MediaSelectModal from '@/components/MediaSelectModal'
import { useI18n } from 'vue-i18n'
import { VideoSlide } from '@/helpers/Slides'
import DurationSlideStep from '@/components/DurationSlideStep.vue'

export default defineComponent({
  name: 'VideoSlide',
  components: {
    DurationSlideStep,
    MediaSelectModal,
    SlideStep,
    SyncOutlined
  },
  props: {
    title: String,
    slideObj: VideoSlide,
    groupId: String,
    zoning: Boolean,
    disabled: Boolean
  },
  emits: ['save', 'update:disabled', 'update-slide', 'init-name', 'close'],
  setup (props, { emit }) {
    const { t } = useI18n()
    const isNewSlide = ref(!props.slideObj)
    const showMediaSelectModal = ref(isNewSlide.value)
    const slide = props.slideObj || new VideoSlide()
    const selectedObjectFit = ref(slide.slideData.objectFit)
    const asset = ref(slide.video)
    const { muted } = ref(slide.slideData)
    const duration = ref(slide.duration)
    const mediaExists = ref(!!asset.value)
    const isMuted = props.zoning || muted
    const thumbnail = computed(() => asset.value?.thumbnail)
    const videoExtension = asset.value?.extension?.toUpperCase()
    const videoName = asset.value?.name
    const videoDuration = computed(() => asset.value?.metadata?.duration || 'Unknown')
    const selectedMedia = ref(mediaExists.value ? [asset] : [])
    const selectedMediaId = computed(() => selectedMedia.value?.[0]?.id)
    const defaultDuration = (computed(()=>slide?.customDuration ? parseInt(slide?.customDuration) : null))

    emit('init-name', {
      name: slide.name,
      defaultName: slide.getDefaultName(t)
    })

    const updateSlide = () => {
      duration.value = slide.duration
      emit('update-slide', slide)
    }

    const toggleDisabled = (status) => {
      emit('update:disabled', status)
    }

    const updateSlideData = () => {
      slide.updateSlideData({
        muted: isMuted,
        objectFit: selectedObjectFit.value,
        mediaVideoReference: selectedMedia.value?.map(m => m.mediaReference)?.[0]
      })
      slide.setMute(isMuted)
      !isNewSlide.value && slide.setVideo(selectedMedia.value?.[0])
    }

    const onCloseMediaModal = () => {
      if (isNewSlide.value) {
        emit('close')
      }
    }

    const onSelectChange = (selection) => {
      selectedMedia.value = selection
      const duration = selection[0]?.metadata?.duration
      duration && updateSlideDuration(Math.floor(+duration))
      updateSlideData()
      updateSlide()
      toggleDisabled(!selectedMediaId.value)
      emit('save')
    }

    const updateSlideDuration = (duration) => {
      slide.setDuration(duration)
    }

    const replaceMedia = () => {
      showMediaSelectModal.value = true
    }

    return {
      slide,
      duration,
      mediaExists,
      selectedMedia,
      thumbnail,
      videoExtension,
      videoDuration,
      videoName,
      showMediaSelectModal,
      defaultDuration,
      onCloseMediaModal,
      replaceMedia,
      onSelectChange,
      updateSlide
    }
  }
})
</script>

<style lang="less" scoped>
.video-card {
  margin-bottom: 16px;
  overflow: auto;
  display: flex;

  .video-thumbnail {
    width: 384px;
    height: 216px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 384px;
      height: 216px;
      float: left;
      margin-right: 32px;
      border-radius: 4px;
    }
  }
}

</style>
