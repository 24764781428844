import { createRouter, createWebHistory } from 'vue-router'
import OuterAuthPage from '@/views/OuterAuthPage'
import InnerView from '@/views/InnerView'
import DevicesPage from '@/views/DevicesPage'
import CalendarPage from '@/views/CalendarPage'
import PlaylistPage from '@/views/PlaylistPage'
import WeekLoopPage from '@/views/WeekLoopPage'
import LoginAsPage from '@/views/LoginAsPage'
import ResetPasswordPage from '@/views/ResetPasswordPage'
import ChangeEmailPage from '@/views/ChangeEmailPage'
import RevertEmailPage from '@/views/RevertEmailPage'
import GroupsView from '@/views/GroupsView'
import AuthPage from '@/views/AuthPage'
import ScheduleSider from '@/components/ScheduleSider'
import PlaylistsSider from '@/components/PlaylistsSider'
import DevicesSider from '@/components/DevicesSider'
import VerifyEmail from '@/views/VerifyEmail'
import LogoutPage from '@/views/LogoutPage.vue'
import GroupsWrapperView from '@/views/GroupsWrapperView.vue'
import store from '@/store'

const routes = [
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/CalendarPage.vue')
  // },
  {
    path: '/authenticate',
    name: 'OuterAuth',
    component: OuterAuthPage
  },
  {
    path: '/logout',
    name: 'LogOut',
    component: LogoutPage
  },
  {
    path: '/login',
    name: 'Login',
    component: AuthPage
  },
  {
    path: '/verify-email/:token',
    name: 'VerifyEmail',
    component: VerifyEmail,
    props: true
  },
  {
    path: '/signup',
    name: 'Signup',
    component: AuthPage
  },
  {
    path: '/sso-login',
    name: 'SSOLogin',
    component: AuthPage
  },
  {
    path: '/invitation/:token',
    name: 'SimpleSignup',
    component: AuthPage,
    props: true
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: AuthPage
  },
  {
    path: '/email-confirmed',
    name: 'EmailConfirmed',
    component: AuthPage
  },
  {
    path: '/reset-password/:token',
    name: 'ResetPassword',
    component: ResetPasswordPage,
    props: true
  },
  {
    path: '/login-as/:token',
    name: 'LoginAs',
    component: LoginAsPage,
    props: true
  },
  {
    path: '/change-email/:token',
    name: 'ChangeEmail',
    component: ChangeEmailPage,
    props: true
  },
  {
    path: '/revert-email/:token',
    name: 'RevertEmail',
    component: RevertEmailPage,
    props: true
  },
  {
    path: '/',
    name: 'Home',
    component: InnerView,
    props: true,
    redirect: { name: 'GroupsWrapper' },
    children: [
      {
        path: '/',
        name: 'GroupsWrapper',
        component: GroupsWrapperView,
        props: true,
        children: [{
          path: '/:groupId',
          name: 'Groups',
          props: true,
          component: GroupsView,
          children: [
            {
              path: 'calendar',
              name: 'Calendar',
              props: true,
              meta: {
                key: 'schedule'
              },
              components: {
                default: CalendarPage,
                InnerSider: ScheduleSider
              }
            }, {
              path: 'week-loop',
              name: 'WeekLoop',
              props: true,
              meta: {
                key: 'schedule'
              },
              components: {
                default: WeekLoopPage,
                InnerSider: ScheduleSider
              }
            },
            {
              path: 'playlists/:playlistId/',
              name: 'PlaylistPage',
              props: true,
              meta: {
                key: 'playlists'
              },
              components: {
                default: PlaylistPage,
                InnerSider: PlaylistsSider
              }
            },
            {
              path: 'devices',
              name: 'DevicesPage',
              props: true,
              components: {
                default: DevicesPage,
                InnerSider: DevicesSider
              },
              meta: {
                key: 'devices'
              }
            }
          ]
        }]
      },
    ]
  },
  {
    path: '/:catchAll(.*)',
    redirect: {
      name: 'Login'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// router.beforeEach((to, from) => {
//   console.log(to, from)
// })

router.afterEach(async (to, from) => {
  const isSmartTemplatesView = !!from.matched?.some(r=> r.name === 'SmartPlaylists')
  from.name && !isSmartTemplatesView && await store.dispatch('setPrevRoute', from.path)
})

export default router
