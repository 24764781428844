<template>
  <a-collapse
    :bordered="false"
    class="directory-element"
    ghost
  >
    <a-collapse-panel
      key="panel"
      :header="$t('components.smartTemplateConstructor.directoryElement.title')"
    >
      <template
        v-if="deletable"
        #extra
      >
        <a-tooltip
          :title="$t('components.smartTemplateConstructor.directoryElement.deleteTooltipTitle')"
          placement="left"
        >
          <a-button
            danger
            size="small"
            type="text"
            @click.stop="onDeleteDirectory"
          >
            <DeleteOutlined />
          </a-button>
        </a-tooltip>
      </template>
      <TextElement
        v-model:value-model="locationState.number"
        :max-length="3"
        :title="$t('components.smartTemplateConstructor.directoryElement.floorInputTitle')"
      />
      <TextElement
        v-model:value-model="locationState.title"
        :lines="isOnly ? 3 : 1"
        :title="$t('components.smartTemplateConstructor.directoryElement.titleInputTitle')"
      />
      <TextElement
        v-model:value-model="locationState.description"
        :lines="3"
        :title="$t('components.smartTemplateConstructor.directoryElement.descriptionInputTitle')"
      />
      <DirectionSelect v-model:value-model="locationState.direction" />
    </a-collapse-panel>
  </a-collapse>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import { DeleteOutlined } from '@ant-design/icons-vue'
import DirectionSelect from '@/components/smartTemplateConstructor/segmentElements/DirectionSelect'
import TextElement from '@/components/smartTemplateConstructor/segmentElements/TextElement'

export default defineComponent({
  name: 'DirectoryElement',
  components: {
    TextElement,
    DirectionSelect,
    DeleteOutlined
  },
  props: {
    deletable: Boolean,
    isOnly: Boolean,
    location: Object
  },
  emits: ['delete'],
  setup (props, { emit }) {
    const locationState = reactive(props.location)

    const onDeleteDirectory = () => {
      emit('delete')
    }

    return {
      locationState,
      onDeleteDirectory
    }
  }
})
</script>

<style lang="less" scoped>
.directory-element {
  :deep(  > .ant-collapse-item > .ant-collapse-content > .ant-collapse-content-box) {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  :deep( > .ant-collapse-item > .ant-collapse-header) {
    padding-left: 0;
    padding-right: 0;
  }

  :deep(.ant-form-item) {
    display: block;
    margin-bottom: 4px;
  }
}

</style>
