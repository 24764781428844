<template>
  <a-modal
    :open="showCollaboration"
    width="960px"
    style="top: 80px"
    wrap-class-name="full-height-modal collaboration-modal"
    @ok="closeModal"
    @cancel="closeModal"
  >
    <template #title>
      <ModalHeading
        :title="$t('components.collaborationModal.title')"
        :subtitle="$t('components.collaborationModal.subtitle')"
      />
    </template>
    <a-layout style="overflow: hidden">
      <a-drawer
        :title="$t('components.collaborationModal.accessDrawerTitle')"
        placement="right"
        :closable="true"
        :open="showDrawer"
        :get-container="false"
        :style="{ position: 'absolute' }"
        @close="closeDrawer"
      >
        <a-typography-paragraph>{{ drawerState.treeCheckedKeys?.length ? $t('components.collaborationModal.selectedCount', {count: filterGroupsLabels(drawerState.treeCheckedKeys).length}) : $t('components.collaborationModal.selectedAll') }}</a-typography-paragraph>
        <a-tree
          v-if="showDrawer"
          v-model:checkedKeys="drawerState.treeCheckedKeys"
          multiple
          checkable
          :selectable="false"
          default-expand-all
          :placeholder="$t('components.collaborationModal.selectPlaylist')"
          allow-clear
          tree-default-expand-all
          :tree-data="drawerState.treeData"
        />
        <template #footer>
          <a-space>
            <a-button
              style="margin-right: 8px"
              @click="closeDrawer"
            >
              {{ $t('components.collaborationModal.drawerCancelButtonText') }}
            </a-button>
            <a-button
              type="primary"
              @click="saveAccess"
            >
              {{ $t('components.collaborationModal.drawerSaveButtonText') }}
            </a-button>
          </a-space>
        </template>
      </a-drawer>
      <a-layout-content style="background-color: #fff; overflow-x: auto">
        <a-row
          justify="space-between"
          style="position: sticky; z-index: 1; top: -24px; padding-bottom: 16px; padding-top: 24px; margin-top: -24px;background-color: #fff"
        >
          <a-col flex="1">
            <a-input-group
              v-if="isOwner || isAdmin"
              compact
            >
              <a-input
                v-model:value="newMemberEmail"
                style="width: 200px"
                :placeholder="$t('components.collaborationModal.newMemberEmailPlaceholder')"
                @pressEnter="inviteMember"
              />
              <a-dropdown :overlay-class-name="'role-dropdown'">
                <template #overlay>
                  <a-menu @click="handleNewMemberRoleChange">
                    <a-menu-item key="ADMIN">
                      {{ $t('components.collaborationModal.adminRole') }}
                      <div>
                        <small>
                          {{ $t('components.collaborationModal.adminRoleDescription') }}
                        </small>
                      </div>
                    </a-menu-item>
                    <a-menu-item key="MANAGER">
                      {{ $t('components.collaborationModal.managerRole') }}
                      <div>
                        <small>
                          {{ $t('components.collaborationModal.managerRoleDescription') }}
                        </small>
                      </div>
                    </a-menu-item>
                    <a-menu-item
                      v-if="authorRoleEnabled"
                      key="AUTHOR"
                    >
                      {{ $t('components.collaborationModal.authorRole') }}
                      <div>
                        <small>
                          {{ $t('components.collaborationModal.authorRoleDescription') }}
                        </small>
                      </div>
                    </a-menu-item>
                  </a-menu>
                </template>
                <a-button id="role-dropdown-button">
                  {{ $t(`components.collaborationModal.${newMemberRole?.toLowerCase()}Role`) }}
                  <DownOutlined />
                </a-button>
              </a-dropdown>
              <a-button
                :disabled="FULL_ACCESS_ROLES.includes(newMemberRole)"
                @click="startNewMemberAccessEdit"
              >
                {{ newMemberAccess?.length ? $t('components.collaborationModal.accessToButtonText', {count: newMemberAccess?.length}) : $t('components.collaborationModal.accessAllButtonText') }}
              </a-button>
              <a-button
                type="primary"
                :disabled="!isNewEmailValid"
                :loading="sendingInvite"
                @click="inviteMember"
              >
                {{ $t('components.collaborationModal.inviteButtonText') }}
              </a-button>
            </a-input-group>
          </a-col>
          <a-col flex="0 0 220px">
            <a-input-search
              v-model:value="userFilter"
              :placeholder="$t('components.collaborationModal.filterPlaceholder')"
              style="width: 220px"
            />
          </a-col>
        </a-row>
        <a-tabs v-model:activeKey="activeTab">
          <a-tab-pane
            key="members"
            :tab="$t('components.collaborationModal.membersTabTitle', {count: membersCountString})"
          >
            <a-table
              :columns="memberColumns"
              :data-source="filteredMembers"
            >
              <template #headerCell="{ column }">
                <template v-if="column.key">
                  {{ $t(`components.collaborationModal.${column.key}`) }}
                </template>
              </template>
              <template #bodyCell="{ text, column, record }">
                <template v-if="column.dataIndex === 'avatar'">
                  <a-avatar
                    v-if="text"
                    :src="text"
                  />
                  <a-avatar v-else>
                    <template #icon>
                      <UserOutlined />
                    </template>
                  </a-avatar>
                </template>
                <template v-else-if="column.dataIndex === 'name'">
                  {{ text }}
                </template>
                <template v-else-if="column.dataIndex === 'role'">
                  <span style="text-transform: capitalize">{{ $t(`components.collaborationModal.${text?.toLowerCase()}Role`) }}</span>
                </template>
                <template v-else-if="column.dataIndex === 'access'">
                  <template v-if="record.role === 'MANAGER'">
                    <a-tooltip v-if="record.restrictions?.length">
                      <template #title>
                        <div
                          v-for="restrictionId in record.restrictions"
                          :key="restrictionId"
                        >
                          {{ availableGroupsIdMap[restrictionId] }}
                        </div>
                      </template>
                      {{ `${record.restrictions.length} ${RESTRICTION_NAMES_MAP[record.role]}` }}
                    </a-tooltip>
                    <template v-else>
                      {{ $t('components.collaborationModal.all') }}
                    </template>
                  </template>
                  <template v-else-if="record.role === 'AUTHOR'">
                    <a-tooltip v-if="record.restrictions?.length">
                      <template #title>
                        <div
                          v-for="restrictionId in record.restrictions"
                          :key="restrictionId"
                        >
                          {{ availableGroupsPlaylistsIdMap[restrictionId] }}
                        </div>
                      </template>
                      {{ `${record.restrictions.length} ${RESTRICTION_NAMES_MAP[record.role]}` }}
                    </a-tooltip>
                    <template v-else>
                      {{ $t('components.collaborationModal.all') }}
                    </template>
                  </template>
                  <template v-else>
                    {{ $t('components.collaborationModal.all') }}
                  </template>
                </template>
                <template v-else-if="column.dataIndex === 'actions'">
                  <div style="display: flex; gap: 8px; align-items: center; justify-content: center;">
                    <a-popconfirm
                      v-if="record.role !== 'OWNER' && record.userId !== userId"
                      placement="bottom"
                      :title="$t('components.collaborationModal.popConfirmTitle')"
                      :ok-text="$t('components.collaborationModal.popConfirmOkText')"
                      :cancel-text="$t('components.collaborationModal.popConfirmCancelText')"
                      @confirm="handleRemoveUser(record.userId)"
                    >
                      <a-button>
                        <template #icon>
                          <DeleteOutlined style="font-size: 12px" />
                        </template>
                      </a-button>
                    </a-popconfirm>
                  </div>
                </template>
              </template>
            </a-table>
          </a-tab-pane>
          <a-tab-pane
            key="invites"
            :tab="$t('components.collaborationModal.invitesTabTitle', {count: invitesCountString})"
            force-render
            :disabled="!invitesCount"
          >
            <a-table
              :columns="inviteColumns"
              :data-source="filteredInvites"
              :loading="resendingInvite"
            >
              <template #headerCell="{ column }">
                <template v-if="column.key">
                  {{ $t(`components.collaborationModal.${column.key}`) }}
                </template>
              </template>
              <template #bodyCell="{ text, column, record }">
                <template v-if="column.dataIndex === 'actions'">
                  <a-typography-link @click="resendInvite(record.id)">
                    {{ $t('components.collaborationModal.resendLink') }}
                  </a-typography-link>
                  <template v-if="isAdmin || isOwner">
                    <a-divider type="vertical" />
                    <a-tooltip>
                      <template #title>
                        {{ $t('components.collaborationModal.copyLinkTooltipTitle') }}
                      </template>
                      <a-typography-link
                        @click="copyInviteLink(record.id)"
                      >
                        {{ $t('components.collaborationModal.copyLink') }}
                      </a-typography-link>
                    </a-tooltip>
                  </template>
                  <a-divider type="vertical" />
                  <a-typography-link
                    type="danger"
                    @click="cancelInvite(record.email)"
                  >
                    {{ $t('components.collaborationModal.cancelLink') }}
                  </a-typography-link>
                </template>
                <template v-else-if="column.dataIndex === 'role'">
                  <span style="text-transform: capitalize">{{ $t(`components.collaborationModal.${text?.toLowerCase()}Role`) }}</span>
                </template>
                <template v-else-if="column.dataIndex === 'access'">
                  <template v-if="record.role === 'MANAGER'">
                    <a-tooltip v-if="record.restrictions?.length">
                      <template #title>
                        <div
                          v-for="restrictionId in record.restrictions"
                          :key="restrictionId"
                        >
                          {{ availableGroupsIdMap[restrictionId] }}
                        </div>
                      </template>
                      {{ `${record.restrictions.length} ${RESTRICTION_NAMES_MAP[record.role]}` }}
                    </a-tooltip>
                    <template v-else>
                      {{ $t('components.collaborationModal.all') }}
                    </template>
                  </template>
                  <template v-else-if="record.role === 'AUTHOR'">
                    <a-tooltip v-if="record.restrictions?.length">
                      <template #title>
                        <div
                          v-for="restrictionId in record.restrictions"
                          :key="restrictionId"
                        >
                          {{ availableGroupsPlaylistsIdMap[restrictionId] }}
                        </div>
                      </template>
                      {{ `${record.restrictions.length} ${RESTRICTION_NAMES_MAP[record.role]}` }}
                    </a-tooltip>
                    <template v-else>
                      {{ $t('components.collaborationModal.all') }}
                    </template>
                  </template>
                  <template v-else>
                    {{ $t('components.collaborationModal.all') }}
                  </template>
                </template>
              </template>
            </a-table>
          </a-tab-pane>
        </a-tabs>
      </a-layout-content>
    </a-layout>
    <template #footer>
      <a-button
        type="primary"
        @click="closeModal"
      >
        {{ $t('components.collaborationModal.closeButtonText') }}
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { computed, defineComponent, reactive, ref, watch } from 'vue'
import { DeleteOutlined, DownOutlined, UserOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { isEmail } from 'validator'
import { error, formatDate, getUserAvatarPlaceholder, success } from '@/utils'
import { copyText } from 'vue3-clipboard'
import { useI18n } from 'vue-i18n'
import ModalHeading from '@/components/ModalHeading.vue'

const RESTRICTION_PARAMS_MAP = {
  MANAGER: 'groupIds',
  AUTHOR: 'playlistIds'
}

const FULL_ACCESS_ROLES = ['ADMIN', 'OWNER']

const RESTRICTION_NAMES_MAP = {
  MANAGER: 'Group(s)',
  AUTHOR: 'Playlist(s)'
}

const MEMBER_COLUMNS = [
  {
    title: '',
    dataIndex: 'avatar',
    width: 64
  },
  {
    title: 'name',
    dataIndex: 'name',
    key: 'name',
    width: 300
  },
  {
    title: 'email',
    dataIndex: 'email',
    key: 'email',
    width: 300
  },
  {
    title: 'role',
    dataIndex: 'role',
    key: 'role',
    width: 100
  },
  {
    title: 'access',
    dataIndex: 'access',
    key: 'access',
    width: 150
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'actions'
  }]

const INVITE_COLUMNS = [
  {
    title: 'email',
    key: 'email',
    dataIndex: 'email'
  },
  {
    title: 'role',
    key: 'role',
    dataIndex: 'role',
    width: 100
  },
  {
    title: 'access',
    key: 'access',
    dataIndex: 'access',
    width: 150
  },
  {
    title: 'sent',
    key: 'sent',
    dataIndex: 'sent',
    width: 180
  },
  {
    title: 'actions',
    key: 'actions',
    dataIndex: 'actions',
    width: 200
  }]

export default defineComponent({
  name: 'CollaborationModal',
  components: {
    DeleteOutlined,
    ModalHeading,
    DownOutlined,
    UserOutlined
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const userFilter = ref('')
    const newMemberEmail = ref('')
    const newMemberRole = ref('MANAGER')
    const newMemberAccess = ref([])
    const sendingInvite = ref(false)
    const resendingInvite = ref(false)
    const showDrawer = ref(false)
    const activeTab = ref('members')
    const selectedGroups = ref([])
    const selectedPlaylists = ref([])

    const smartGroupsEnabled = computed(()=> store.getters['workspace/smartGroupsEnabled'])
    const authorRoleEnabled = computed(()=> store.getters['workspace/authorRoleEnabled'])

    const standardGroupsSelectableTree = computed(() => store.getters['groups/availableStandardGroupsTree']({ checkable: true }))
    const smartGroupsSelectableTree = computed(() => store.getters['groups/availableSmartGroupsTree']({ checkable: true }))

    const standardGroupsPlaylistsSelectableTree = computed(() => store.getters['groups/availableStandardGroupsTree']({ selectablePlaylists: true }))
    const smartGroupsPlaylistsSelectableTree = computed(() => store.getters['groups/availableSmartGroupsTree']({ selectablePlaylists: true }))

    const groupsSelectableTree = computed(() => {
      if (smartGroupsEnabled.value && smartGroupsSelectableTree.value.length) {
        if (standardGroupsSelectableTree.value?.length) {
          return [{
            title: 'Groups',
            key: 'groups',
            value: 'groups',
            selectable: false,
            children : standardGroupsSelectableTree.value
          },
            {
              title: 'Smart Groups',
              key: 'smart-groups',
              value: 'smart-groups',
              selectable: false,
              children : smartGroupsSelectableTree.value
            }]
        }
        else {
          return smartGroupsSelectableTree.value
        }
      }
      else {
        return standardGroupsSelectableTree.value
      }
    })

    const playlistsSelectableTree = computed(() => {
      if (smartGroupsEnabled.value && smartGroupsPlaylistsSelectableTree.value.length) {
        if (standardGroupsPlaylistsSelectableTree.value?.length) {
          return [{
            title: 'Groups',
            key: 'groups',
            value: 'groups',
            checkable: false,
            selectable: false,
            children : standardGroupsPlaylistsSelectableTree.value
          },
            {
              title: 'Smart Groups',
              key: 'smart-groups',
              value: 'smart-groups',
              checkable: false,
              selectable: false,
              children : smartGroupsPlaylistsSelectableTree.value
            }]
        }
        else {
          return smartGroupsPlaylistsSelectableTree.value
        }
      }
      else {
        return standardGroupsPlaylistsSelectableTree.value
      }
    })

    const availableStandardGroupsIdMap = computed(() => store.getters['groups/availableStandardGroupsIdMap'])
    const availableSmartGroupsIdMap = computed(() => store.getters['groups/availableSmartGroupsIdMap'])

    const availableGroupsIdMap = computed(() => ({
      ...availableStandardGroupsIdMap.value || null,
      ...availableSmartGroupsIdMap.value || null
    }))

    const availableStandardGroupsPlaylistsIdMap = computed(() => store.getters['groups/availableStandardGroupsPlaylistsIdMap'])
    const availableSmartGroupsPlaylistsIdMap = computed(() => store.getters['groups/availableSmartGroupsPlaylistsIdMap'])

    const availableGroupsPlaylistsIdMap = computed(() => ({
      ...availableStandardGroupsPlaylistsIdMap.value || null,
      ...availableSmartGroupsPlaylistsIdMap.value || null
    }))

    const userId = computed(() => store.getters['auth/userId'])
    const isOwner = computed(() => store.getters['workspace/isOwner'])
    const isAdmin = computed(() => store.getters['workspace/isAdmin'])

    const showCollaboration = computed(() => store.getters.showCollaboration)
    const members = computed(() => store.getters['workspace/workspaceMembers'].map(member => {
      return {
        userId: member.user?.id,
        name: `${member.user?.firstName} ${member.user?.lastName}`,
        email: member.user?.email,
        avatar: member.user?.avatarMedia?.generatedMedia?.[0]?.url || getUserAvatarPlaceholder(member.user.id),
        role: member.role,
        restrictions: member.restrictions ? member.restrictions[RESTRICTION_PARAMS_MAP[member.role]] : []
      }
    }))
    const invites = computed(() => store.getters['workspace/workspacePendingInvites'].map(invite => {
      return {
        id: invite.id,
        email: invite.email,
        role: invite.role,
        sent: formatDate(invite.createdAt),
        restrictions: invite.restrictions ? invite.restrictions[RESTRICTION_PARAMS_MAP[invite.role]] : []
      }
    }))

    const drawerState = reactive({
      type: '',
      memberId: '',
      memberName: '',
      treeData: [],
      treeCheckedKeys: []
    })

    const membersCount = computed(() => {
      return members.value?.length || null
    })

    const membersCountString = computed(() => {
      return membersCount.value ? ` (${membersCount.value})` : ''
    })

    const invitesCount = computed(() => {
      return invites.value?.length || null
    })
    const invitesCountString = computed(() => {
      return invitesCount.value ? ` (${invitesCount.value})` : ''
    })

    const isNewEmailValid = computed(() => {
      return isEmail(newMemberEmail.value)
    })

    const filteredMembers = computed(() => {
      return members.value.filter(member => member.name.toLowerCase().includes(userFilter.value.toLowerCase()) ||
          member.email?.toLowerCase().includes(userFilter.value.toLowerCase())
      )
    })
    const filteredInvites = computed(() => {
      return invites.value.filter(invite => invite.email.includes(userFilter.value.toLowerCase()))
    })

    const inviteMember = () => {
      if (!isNewEmailValid.value) return
      sendingInvite.value = true

      store.dispatch('workspace/sendInvite', {
        role: newMemberRole.value,
        email: newMemberEmail.value,
        ...(RESTRICTION_PARAMS_MAP[newMemberRole.value] ? {
          restrictions: {
            [RESTRICTION_PARAMS_MAP[newMemberRole.value]]: newMemberAccess.value?.length ? newMemberAccess.value : null
          }
        } : {})

      }).then(() => {
        success()
        resetInviteForm()
      }).catch(e => {
        switch (e.message) {
          case 'USER_IS_ALREADY_MEMBER':
            error(t('components.collaborationModal.userAlreadyHasAccess'))
            break
          case 'USER_IS_ALREADY_INVITED':
            error(t('components.collaborationModal.userAlreadyHasPendingInvite'))
            break
          default:
            error(e.message)
        }
      }).then(() => {
        sendingInvite.value = false
      })
    }

    const cancelInvite = (email) => {
      store.dispatch('workspace/cancelInvite', email).then(() => {
        success()
      }).catch(e => {
        error(e.message)
      })
    }

    const filterGroupsLabels = (groups = []) => {
      return groups.filter((id)=>!['smart-groups', 'groups'].includes(id))
    }

    const copyInviteLink = (inviteId) => {
      getInviteLink(inviteId)
    }

    const resendInvite = (inviteId) => {
      resendingInvite.value = true
      store.dispatch('workspace/resendInvite', inviteId).then(() => {
        success()
      }).catch(e => {
        error(e.message)
      }).finally(() => {
        resendingInvite.value = false
      })
    }

    const getInviteLink = (inviteId) => {
      store.dispatch('workspace/getInviteLink', inviteId).then((text) => {
        copyText(text, undefined, (e, event) => {
          if (e) {
            error(e.message)
          } else {
            success(t('components.collaborationModal.inviteLinkCopied'))
          }
        })

      }).catch(e => {
        error(e.message)
      })
    }

    const resetInviteForm = () => {
      newMemberEmail.value = ''
      newMemberRole.value = 'MANAGER'
      newMemberAccess.value = []
    }

    const closeModal = () => {
      store.dispatch('closeCollaboration')
    }

    const closeDrawer = () => {
      showDrawer.value = false
    }

    const startNewMemberAccessEdit = () => {
      drawerState.memberId = ''
      drawerState.memberName = ''
      drawerState.treeCheckedKeys = newMemberAccess.value
      switch (newMemberRole.value) {
        case 'MANAGER':
          drawerState.type = 'groupIds'
          drawerState.treeData = groupsSelectableTree.value
          break
        case 'AUTHOR':
          drawerState.type = 'playlistIds'
          drawerState.treeData = playlistsSelectableTree.value
          break
      }
      openDrawer()
    }

    const saveAccess = () => {
      if (!drawerState.memberId) {
        newMemberAccess.value = filterGroupsLabels(drawerState.treeCheckedKeys)
      }
      showDrawer.value = false
    }

    const openDrawer = () => {
      showDrawer.value = true
    }

    const handleNewMemberRoleChange = (e) => {
      newMemberRole.value = e.key
    }

    const handleRemoveUser = (userId) => {
      store.dispatch('workspace/removeWorkspaceMember', userId).then(()=>{
        success()
      })
    }

    watch(invitesCount, (count) => {
      if (!count) {
        activeTab.value = 'members'
      }
    })

    watch(newMemberRole, () => {
      newMemberAccess.value = []
    })

    return {
      userId,
      isAdmin,
      isOwner,
      showDrawer,
      availableGroupsIdMap,
      availableGroupsPlaylistsIdMap,
      showCollaboration,
      selectedGroups,
      selectedPlaylists,
      groupsSelectableTree,
      playlistsSelectableTree,
      filteredMembers,
      membersCountString,
      filteredInvites,
      invitesCount,
      invitesCountString,
      newMemberEmail,
      newMemberRole,
      newMemberAccess,
      authorRoleEnabled,
      isNewEmailValid,
      sendingInvite,
      activeTab,
      drawerState,
      resendingInvite,
      memberColumns: MEMBER_COLUMNS,
      inviteColumns: INVITE_COLUMNS,
      RESTRICTION_NAMES_MAP,
      FULL_ACCESS_ROLES,
      userFilter,
      copyInviteLink,
      resendInvite,
      inviteMember,
      cancelInvite,
      handleRemoveUser,
      closeModal,
      closeDrawer,
      openDrawer,
      saveAccess,
      filterGroupsLabels,
      startNewMemberAccessEdit,
      handleNewMemberRoleChange
    }
  }
})
</script>

<style lang="less">
.collaboration-modal {
  .ant-modal-content {
    overflow: hidden;
  }
  .ant-modal-body {
    padding: 0;
    .ant-layout {
      height: 100%;
    }
  }
  #role-dropdown-button {
    text-transform: capitalize;
    width: 140px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center
  }
}
.role-dropdown {
  .ant-dropdown-menu {
    max-width: 300px;
    .ant-dropdown-menu-item {
      small {
        opacity: 0.5;
      }
      &:hover {
        background-color: var(--ant-primary-1);
        small {
          opacity: 1;
        }
      }
    }
  }
}
</style>
