import moment from 'moment'
import { DAY_IN_MILLISECONDS, DEFAULT_PLAYLIST_COLOR } from '@/constants'
import { colorShade } from '@/utils'

export const isAnOverlapEvent = (eventStartDay, eventEndDay, events) => {
  for (let i = 0; i < events.length; i++) {
    const eventA = events[i]
    if (eventA.display === 'background') {
      return false
    }
    if (moment(eventStartDay).isAfter(eventA.start) && moment(eventStartDay).isBefore(eventA.end)) {
      return true
    }
    if (moment(eventEndDay).isAfter(eventA.start) && moment(eventEndDay).isBefore(eventA.end)) {
      return true
    }
    if (moment(eventStartDay).isSameOrBefore(eventA.start) && moment(eventEndDay).isSameOrAfter(eventA.end)) {
      return true
    }
  }
  return false
}

export const eventCalendarToDto = ({ playlistId, startDate, endDate }) => {
  startDate = startDate.length === 10 ? new Date(startDate + 'T00:00:00') : startDate
  endDate = endDate.length === 10 ? new Date(endDate + 'T00:00:00') : endDate
  return {
    ...playlistId ? { playlistId } : {},
    startDate: startDate,
    endDate: endDate
  }
}
export const eventWeekLoopToDto = ({ playlistId, startDate, endDate }) => {
  const weekDay = moment(startDate)
  const startOfWeek = weekDay.startOf('week')
  const startWeeklyWindowTimeMs = moment.duration(moment(startDate).diff(moment(startOfWeek))).asMilliseconds()
  const endWeeklyWindowTimeMs = endDate ? moment.duration(moment(endDate).diff(moment(startOfWeek))).asMilliseconds() : startWeeklyWindowTimeMs + DAY_IN_MILLISECONDS

  return {
    ...playlistId ? { playlistId } : {},
    startWeeklyWindowTimeMs,
    endWeeklyWindowTimeMs
  }
}

export const WEEKLY_LOOP_TYPE = 'REPEATABLE_WEEKLY_WINDOW'
export const ONETIME_WINDOW_TYPE = 'ONETIME_WINDOW'
export const MAIN_TYPE = 'MAIN'

export const mapElementsToEvents = (elements, isCalendarView) => {
  return [...elements.map((element, index) => {
    const {
      startWeeklyWindowTimeMs,
      endWeeklyWindowTimeMs,
      startDate,
      endDate,
      background,
      type,
      index: reverseOrder,
      playlist: { name, color, paused }
    } = element

    const event = {
      id: index,
      title: name,
      borderColor: paused ? 'rgba(0, 0, 0, 0.2)' : (color || DEFAULT_PLAYLIST_COLOR),
      order: 20 - reverseOrder,
      extendedProps: {
        element,
        paused,
        color
      }
    }
    const startDay = Math.floor(startWeeklyWindowTimeMs / DAY_IN_MILLISECONDS)
    const endDay = Math.floor(endWeeklyWindowTimeMs / DAY_IN_MILLISECONDS)
    switch (type) {
      case WEEKLY_LOOP_TYPE:
        event.startTime = startWeeklyWindowTimeMs % DAY_IN_MILLISECONDS
        event.backgroundColor = paused
          ? 'rgba(255, 255, 255, 0.8)'
          : background
            ? colorShade(color || DEFAULT_PLAYLIST_COLOR, 150)
            : isCalendarView ? colorShade(color || DEFAULT_PLAYLIST_COLOR, 150) : (color || DEFAULT_PLAYLIST_COLOR)
        event.endTime = endWeeklyWindowTimeMs % DAY_IN_MILLISECONDS + ((endDay - startDay) * DAY_IN_MILLISECONDS)
        event.daysOfWeek = [startDay]
        event.constraints = null
        event.display = background ? 'background' : !isCalendarView ? 'block' : 'background'
        break
      case ONETIME_WINDOW_TYPE:
        if (isCalendarView) {
          event.start = startDate
          event.order++
          event.backgroundColor = paused
            ? 'rgba(255, 255, 255, 0.8)'
            : background ? colorShade(color || DEFAULT_PLAYLIST_COLOR, 150) : (color || DEFAULT_PLAYLIST_COLOR)
          event.end = endDate
          event.constraints = null
          event.display = background ? 'background' : 'block'
        }
        else {
          event.display = 'none'
        }
        break
      case MAIN_TYPE:
        event.display = 'none'
        break
    }
    return event
  })]
}



